import styled from "styled-components";

export const CompetencesChart = styled.div`
    width: 100%;
    margin-top: 30px;
`;

export const Chart = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and(max-width: $media) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 760px) {
    }
`;

export const GraphicContainer = styled.div`
    flex: 2;
    margin-bottom: 25px;
`;

export const ListContainer = styled.div`
    flex: 3;
`;
