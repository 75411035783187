import styled from "styled-components";

export const GraphicTalents = styled.div`
    .highcharts-credits {
        display: none;
    }
    .highcharts-title {
        display: none;
    }
    .highcharts-legend-item {
        display: none;
    }
    .highcharts-legend {
        display: none;
    }
    .highcharts-subtitle {
        display: none;
    }
    .highcharts-yaxis-labels {
        display: none;
    }
    .highcharts-container {
    }
`;

export const BoxResult = styled.div``;

export const StyledChart = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;
