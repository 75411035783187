import React, { useState } from "react";
import * as Styled from "./style";

const EmptyMessage = ({
    history,
    timer,
    messagemTitle = "Ainda não há dados a serem exibidos",
    messageBox = "",
    button,
}) => {
    const [seconds, setSeconds] = useState(8);
    function setTimer() {
        if (seconds > 0) {
            setTimeout(function() {
                let newSeconds = seconds;
                newSeconds -= 1;
                setSeconds(newSeconds);
            }, 1000);
        } else {
            history.push("/");
        }
        return `${seconds}s`;
    }

    return (
        <Styled.EmptyMessageBox>
            <Styled.EmptyMessageContainer>
                <Styled.EmptyMessageTitle>
                    {messagemTitle}
                </Styled.EmptyMessageTitle>
                <Styled.EmptyMessageText>{messageBox}</Styled.EmptyMessageText>
                <Styled.Center>{button}</Styled.Center>
                <Styled.EmptyTimerText>
                    {timer && setTimer()}
                </Styled.EmptyTimerText>
            </Styled.EmptyMessageContainer>
        </Styled.EmptyMessageBox>
    );
};

export default EmptyMessage;
