import React from "react";
import { ReactComponent as Up } from "../../../../../assets/svg/upCard.svg";
import { ReactComponent as Down } from "../../../../../assets/svg/downCard.svg";
import { ReactComponent as Equals } from "../../../../../assets/svg/equalsCard.svg";

import * as Styled from "./styles";

const Positive = ({ type, data, plus }) => {
    const newData = data.split("%");

    const renderChart = () => {
        switch (type) {
            case "executor":
                return (
                    <Styled.Positive>
                        <Styled.Content>
                            <Styled.OneDivision atencao={newData[0] > 35}>
                                <Styled.Result
                                    size={`${newData[0]}%`}
                                    type="executor"
                                />
                            </Styled.OneDivision>
                            <Styled.SeccondDivision atencao={newData[0] > 35}>
                                {newData[0] > 35 && (
                                    <Styled.AtencaoDestaqueText>
                                        DESTAQUE
                                    </Styled.AtencaoDestaqueText>
                                )}
                                <Styled.SeccondDivisionItem>
                                    {plus !== undefined && (
                                        <Styled.SeccondDivisionItemPlus>
                                            <Styled.AtencaoDestaqueText>
                                                (
                                            </Styled.AtencaoDestaqueText>
                                            {plus.positive.executor > 0 ? (
                                                <Up />
                                            ) : plus.positive.executor < 0 ? (
                                                <Down />
                                            ) : (
                                                <Equals />
                                            )}
                                            <Styled.AtencaoDestaqueText>
                                                {`${
                                                    plus.positive.executor < 0
                                                        ? plus.positive.executor
                                                              .toString()
                                                              .substr(
                                                                  1,
                                                                  plus.positive.executor.toString()
                                                                      .length
                                                              )
                                                        : plus.positive.executor
                                                }%)`}
                                            </Styled.AtencaoDestaqueText>
                                        </Styled.SeccondDivisionItemPlus>
                                    )}
                                    {`${newData[0]}%`}
                                </Styled.SeccondDivisionItem>
                            </Styled.SeccondDivision>
                        </Styled.Content>
                    </Styled.Positive>
                );
            case "planner":
                return (
                    <Styled.Positive>
                        <Styled.Content>
                            <Styled.OneDivision atencao={newData[0] > 35}>
                                <Styled.Result
                                    size={`${newData[0]}%`}
                                    type="planejador"
                                />
                            </Styled.OneDivision>
                            <Styled.SeccondDivision atencao={newData[0] > 35}>
                                {newData[0] > 35 && (
                                    <Styled.AtencaoDestaqueText>
                                        DESTAQUE
                                    </Styled.AtencaoDestaqueText>
                                )}
                                <Styled.SeccondDivisionItem>
                                    {plus !== undefined && (
                                        <Styled.SeccondDivisionItemPlus>
                                            <Styled.AtencaoDestaqueText>
                                                (
                                            </Styled.AtencaoDestaqueText>
                                            {plus.positive.executor > 0 ? (
                                                <Up />
                                            ) : plus.positive.executor < 0 ? (
                                                <Down />
                                            ) : (
                                                <Equals />
                                            )}
                                            <Styled.AtencaoDestaqueText>
                                                {`${
                                                    plus.positive.executor < 0
                                                        ? plus.positive.executor
                                                              .toString()
                                                              .substr(
                                                                  1,
                                                                  plus.positive.executor.toString()
                                                                      .length
                                                              )
                                                        : plus.positive.executor
                                                }%)`}
                                            </Styled.AtencaoDestaqueText>
                                        </Styled.SeccondDivisionItemPlus>
                                    )}
                                    {`${newData[0]}%`}
                                </Styled.SeccondDivisionItem>
                            </Styled.SeccondDivision>
                        </Styled.Content>
                    </Styled.Positive>
                );
            case "communicator":
                return (
                    <Styled.Positive>
                        <Styled.Content>
                            <Styled.OneDivision atencao={newData[0] > 35}>
                                <Styled.Result
                                    size={`${newData[0]}%`}
                                    type="comunicador"
                                />
                            </Styled.OneDivision>
                            <Styled.SeccondDivision atencao={newData[0] > 35}>
                                {newData[0] > 35 && (
                                    <Styled.AtencaoDestaqueText>
                                        DESTAQUE
                                    </Styled.AtencaoDestaqueText>
                                )}
                                <Styled.SeccondDivisionItem>
                                    {plus !== undefined && (
                                        <Styled.SeccondDivisionItemPlus>
                                            <Styled.AtencaoDestaqueText>
                                                (
                                            </Styled.AtencaoDestaqueText>
                                            {plus.positive.executor > 0 ? (
                                                <Up />
                                            ) : plus.positive.executor < 0 ? (
                                                <Down />
                                            ) : (
                                                <Equals />
                                            )}
                                            <Styled.AtencaoDestaqueText>
                                                {`${
                                                    plus.positive.executor < 0
                                                        ? plus.positive.executor
                                                              .toString()
                                                              .substr(
                                                                  1,
                                                                  plus.positive.executor.toString()
                                                                      .length
                                                              )
                                                        : plus.positive.executor
                                                }%)`}
                                            </Styled.AtencaoDestaqueText>
                                        </Styled.SeccondDivisionItemPlus>
                                    )}
                                    {`${newData[0]}%`}
                                </Styled.SeccondDivisionItem>
                            </Styled.SeccondDivision>
                        </Styled.Content>
                    </Styled.Positive>
                );
            case "analyst":
                return (
                    <Styled.Positive>
                        <Styled.Content>
                            <Styled.OneDivision atencao={newData[0] > 35}>
                                <Styled.Result
                                    size={`${newData[0]}%`}
                                    type="analista"
                                />
                            </Styled.OneDivision>
                            <Styled.SeccondDivision atencao={newData[0] > 35}>
                                {newData[0] > 35 && (
                                    <Styled.AtencaoDestaqueText>
                                        DESTAQUE
                                    </Styled.AtencaoDestaqueText>
                                )}
                                <Styled.SeccondDivisionItem>
                                    {plus !== undefined && (
                                        <Styled.SeccondDivisionItemPlus>
                                            <Styled.AtencaoDestaqueText>
                                                (
                                            </Styled.AtencaoDestaqueText>
                                            {plus.positive.executor > 0 ? (
                                                <Up />
                                            ) : plus.positive.executor < 0 ? (
                                                <Down />
                                            ) : (
                                                <Equals />
                                            )}
                                            <Styled.AtencaoDestaqueText>
                                                {`${
                                                    plus.positive.executor < 0
                                                        ? plus.positive.executor
                                                              .toString()
                                                              .substr(
                                                                  1,
                                                                  plus.positive.executor.toString()
                                                                      .length
                                                              )
                                                        : plus.positive.executor
                                                }%)`}
                                            </Styled.AtencaoDestaqueText>
                                        </Styled.SeccondDivisionItemPlus>
                                    )}
                                    {`${newData[0]}%`}
                                </Styled.SeccondDivisionItem>
                            </Styled.SeccondDivision>
                        </Styled.Content>
                    </Styled.Positive>
                );
            default:
                break;
        }
    };

    return renderChart();
};

export default Positive;
