import styled from "styled-components";

export const PositiveNegative = styled.div`
  position: relative;
  width: 100%;
`;

export const FirstContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Line = styled.div`
  position: absolute;
  width: 91%;
  height: 1px;
  left: 0;
  background: #f1f1f1;
  margin-left: 36px;

  @media (max-width: 550px) {
    background: transparent;
  }
`;

export const SecondContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const TextImagePerson = styled.span`
  font-size: 16px;
  line-height: 10px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
`;

export const Image = styled.div`
  width: 40px;
  height: 40px;
  ${({ data }) => data && `background-image: url(${data});`};
  ${({ data }) =>
    !data && `align-items: center; display: flex; justify-content: center;`};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 5px;
`;

export const ChartContent = styled.div`
  flex: 6;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: 550px) {
    grid-template-columns: 2fr;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  height: 60px;
  align-items: center;
  position: relative;
  left: 23px;

  @media (max-width: 550px) {
    left: 0px;
  }
`;

export const Info = styled.div`
  width: 1px;
  height: 50px;
  background: #7a869a;
  opacity: 0.15;
  position: absolute;

  @media (max-width: 550px) {
    height: 100%;
  }
`;

export const Text = styled.div`
  position: absolute;
`;

export const InfoNegative = styled.span`
  position: absolute;
  top: -36px;
  left: 15px;
  font-size: 13px;
  font-family: "Roboto Condensed";
  ${({ theme, executor, planejador, comunicador }) =>
    executor
      ? `color: ${theme.colors.executorLight};`
      : comunicador
      ? `color: ${theme.colors.communicatorLight};`
      : planejador
      ? `color: ${theme.colors.plannerLight};`
      : `color: ${theme.colors.analystLight};`};
  font-weight: bold;
  font-weight: bold;
`;

export const InfoPositive = styled.span`
  position: absolute;
  top: -36px;
  right: 15px;
  font-size: 13px;
  font-family: "Roboto Condensed";
  ${({ theme, executor, planejador, comunicador }) =>
    executor
      ? `color: ${theme.colors.executor};`
      : comunicador
      ? `color: ${theme.colors.communicator};`
      : planejador
      ? `color: ${theme.colors.planner};`
      : `color: ${theme.colors.analyst};`};
  font-weight: bold;
`;

export const PositiveContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const NegativeContainer = styled.div`
  flex: 1;
`;

export const Positive = styled.div`
  ${({ percentage }) => percentage && `width: ${percentage}px;`}
  height: 36px;
  ${({ theme, executor, planejador, comunicador }) =>
    executor
      ? `background: ${theme.colors.executor};;`
      : comunicador
      ? `background: ${theme.colors.communicator};`
      : planejador
      ? `background: ${theme.colors.planner};`
      : `background: ${theme.colors.analyst};`};
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
`;

export const Negative = styled.div`
  ${({ percentage }) => percentage && `width: ${percentage}px;`}
  height: 36px;

  ${({ theme, executor, planejador, comunicador }) =>
    executor
      ? `background: ${theme.colors.executorLight};`
      : comunicador
      ? `background:  ${theme.colors.communicatorLight}  ;`
      : planejador
      ? `background: ${theme.colors.plannerLight};`
      : `background:  ${theme.colors.analystLight}`};
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
`;
