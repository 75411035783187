import React from "react";
import { Provider } from "react-redux";
import { store } from "./store";
import history from "./services/history";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Global from "./styles/global";

import Profiler from "./pages/profiler";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";

const Routes = ({ history }) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Profiler} />
      </Switch>
    </BrowserRouter>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Routes history={history} />
        <Global />
      </Provider>
    </ThemeProvider>
  );
};

export default App;
