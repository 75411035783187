import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 235px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  ${({ first }) => (first ? `margin-top: 20px;` : `margin-top: 0px;`)}
  justify-content: space-between;
`;

export const InfoTextContainer = styled.div`
  flex: 2;
  padding-right: 20px;
  align-self: center;
  text-align: right;
`;

export const InfoText = styled.span`
  color: #601e82;

  @media screen and (max-width: 540px) {
    font-size: 0.75rem;
  }
`;

export const ContentChart = styled.div`
  flex: 9;
  ${(props) =>
    `grid-template-columns: ${props.executor} ${props.comunicador} ${props.planejador} ${props.analista}`};
  align-self: center;
`;

export const Line = styled.div`
  background: #f4f6f8;
  height: 1px;
  display: inline-block;
  width: 100%;
  margin: 10px 0;
`;
export const Chart = styled.div`
  ${(props) =>
    `grid-template-columns: ${props.executor} ${props.comunicador} ${props.planejador} ${props.analista}`}
  display: grid;
  width: 100%;
  flex-direction: row;
  max-width: 700px;
  z-index: 1;
`;

export const Item = styled.div`
  ${({ theme, executor, planejador, comunicador }) =>
    executor
      ? `background:${theme.colors.executor}`
      : planejador
      ? `background: ${theme.colors.planner}`
      : comunicador
      ? `background: ${theme.colors.communicator}`
      : `background: ${theme.colors.analyst}`}
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 1;

  ${({ first }) =>
    first
      ? `border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;`
      : `border-left-top-radius: 0; border-top-bottom-radius: 0;`}
  ${({ last }) =>
    last === true
      ? `border-top-right-radius: 25px;border-bottom-right-radius: 25px; `
      : `border-top-right-radius: 0;border-bottom-right-radius: 0px;`}
`;

export const BallItem = styled.div`
  position: absolute;
  left: 50%;
  background: #fff;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  transform: translate(-50%);
`;

export const ItemText = styled.span`
  color: #fff;
  font-size: 1.375rem;
  padding-right: 10px;
`;

export const ContentLegend = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const ContentBlank = styled.div`
  flex: 2;
  padding-right: 20px;
  align-self: center;
  text-align: right;
`;

export const ContentLegendInfo = styled.div`
  flex: 9;
`;

export const ContentLegendInfoMain = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  width: 100%;
  height: 20px;
  position: relative;
  max-width: 700px;
  z-index: 2px;
  bottom: 190px;
  align-items: center;
`;

export const Info = styled.div`
  height: 210px;
  width: 100%;
  ${({ last }) =>
    last ? `border-right: 0;` : `border-right: 1px solid #efedf377;`}
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

export const ItemInfo = styled.span`
  ${({ theme, executor, planejador, comunicador }) =>
    executor
      ? `color:  ${theme.colors.executor}`
      : planejador
      ? `color: ${theme.colors.planner}`
      : comunicador
      ? `color: ${theme.colors.communicator}`
      : `color: ${theme.colors.analyst}`}
  font-size: 1rem;
  font-weight: 800;

  @media screen and (max-width: 720px) {
    font-size: 0.875rem;
  }

  @media screen and (max-width: 540px) {
    font-size: 0.75rem;
  }
`;
