import styled from "styled-components";

export const Item = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  margin-top: 30px;
  height: 24px;
  align-items: center;
`;

export const Text = styled.div`
  margin: 9px 0 0 40px;
  border-left: 1px solid #efedf3;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 125%;
  text-align: justify;
  color: #8872b2;
  padding: 10px 18px;
  display: flex;
  align-items: center;
`;
export const ItemIndex = styled.div`
  flex: 0.6;
  display: flex;
  align-items: center;
  padding-left: 20px;
  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 10px;
    flex: 1;
  }
`;
export const ValueBars = styled.div`
  background: #efedf3;
  width: 100%;
  border-radius: 24px;
  height: 8px;
  flex: 1;
  position: relative;
`;
export const StatusBar = styled.div`
  background: #5f4a87;
  height: 100%;
  border-radius: 24px;
  ${({ widthValues }) =>
    widthValues ? `width:${(100 * widthValues) / 8}%` : "width:100%"}
`;

export const ItemIndexText = styled.span`
  color: #601e82;
  font-weight: bold;
  font-size: 14px;
  padding-right: 5px;
  min-width: 28px;
  font-family: Roboto Condensed;
  @media screen and (max-width: 540px) {
    font-size: 13px;
  }
`;

export const ItemType = styled.div`
  flex: 4;
  display: flex;
  align-items: center;
  padding-left: 5px;
  text-align: left;
`;

export const ItemTypeText = styled.span`
  color: #8872b2;
  font-size: 14px;
  flex: 1;
  text-align: left;
  font-weight: bold;
  @media screen and (max-width: 540px) {
    font-size: 13px;
    flex: 0.8;
  }
`;

export const ItemPlus = styled.div`
  flex: 0.6;
  display: flex;
  align-items: center;
  svg {
    color: #601e82;
    width: 11px;
  }
`;

export const ItemStatus = styled.div`
  flex: 2.2;
  display: flex;
  align-items: center;
  padding-right: 10px;
  justify-content: center;
`;

export const ItemStatusText = styled.span`
  color: #5f4a87;
  font-family: "Roboto Condensed";
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  position: absolute;
  top: -19px;
  left: 0;
`;
