import React from "react";
import * as Styled from "./style";

import {
  ProfilesBar,
  MediumRequirement,
  SearchResults,
  SituationalIndicator,
  CompareProfilers,
  Competences,
  TalentsArea,
  MatchProfiler,
  CirclePercentage,
  MatchNegativePositive,
  PositiveNegative,
  Matriz,
  MatchTalentsArea,
  Chartline,
  ChartInteraction,
  NineBox,
} from "./components";

const Charts = ({
  quadrantChartData,
  fallbackClickQuadrant,
  isTooltip = false,
  chartline,
  animation,
  withList,
  withOutGraphic,
  type,
  name,
  hideTexts,
  size = "medium",
  objectSliders,
  matchTalentsArea,
  primaryColor,
  primaryName,
  sencodaryName,
  secondaryColor,
  dominante,
  profilesBar = {
    executor: "25%",
    planejador: "25%",
    comunicador: "25%",
    analista: "25%",
    tooltipExecutor: 25.0,
    tooltipComunicador: 25.0,
    tooltipPlanejador: 25.0,
    tooltipAnalista: 25.0,
    plus: {},
    predominant_energy_level: {
      executor: "",
      planejador: "",
      comunicador: "",
      analista: "",
    },
  },

  mediumRequirement = {
    voce: {
      executor: "25%",
      planejador: "25%",
      comunicador: "25%",
      analista: "25%",
    },
    meio: {
      executor: "25%",
      planejador: "25%",
      comunicador: "25%",
      analista: "25%",
    },
    flexibilidade: {
      executor: "25%",
      planejador: "25%",
      comunicador: "25%",
      analista: "25%",
    },
  },
  searchResults = {
    voce: {
      dominante: "25%",
      informal: "25%",
      condescendente: "25%",
      formal: "25%",
    },
    meio: {
      dominante: "25%",
      informal: "25%",
      condescendente: "25%",
      formal: "25%",
    },
    flexibilidade: {
      dominante: "25%",
      informal: "25%",
      condescendente: "25%",
      formal: "25%",
    },
    tooltipContent: {
      dominante: {
        text: "",
      },
      informal: {
        text: "",
      },
      condescendente: {
        text: "",
      },
      formal: {
        text: " ",
      },
    },
  },
  pdfLeader,
  pdfTemplate,
  compareProfilers = {
    negative: {
      executor: "5%",
      planejador: "20%",
      comunicador: "30%",
      analista: "100%",
    },
    positive: {
      executor: "5%",
      planejador: "20%",
      comunicador: "30%",
      analista: "100%",
    },
  },
  situationalIndicator = {
    title: "Muito bom",
    type: 1,
    animation: false,
  },
  competences = [
    [
      {
        type: "tolerancia",
        value: 20,
        status: 1,
      },
      {
        type: "tolerancia",
        value: 20,
        status: 1,
      },
      {
        type: "planejamento",
        value: 30,
        status: 2,
      },
      {
        type: "empatia",
        value: 33,
        status: 3,
      },
      {
        type: "capac_ouvir",
        value: 16,
        status: 4,
      },
      {
        type: "concentracao",
        value: 31,
        status: 5,
      },
      {
        type: "condescendencia",
        value: 43,
        status: 6,
      },
      {
        type: "perfil_tecnico",
        value: 73,
        status: 7,
      },
      {
        type: "organizacao",
        value: 63,
        status: 8,
      },
      {
        type: "detalhismo",
        value: 73,
        status: 1,
      },
      {
        type: "rigorosidade",
        value: 83,
        status: 1,
      },
      {
        type: "orientadorsultado",
        value: 33,
        status: 1,
      },
      {
        type: "multitarefas",
        value: 43,
        status: 1,
      },
      {
        type: "automotivacao",
        value: 53,
        status: 1,
      },
      {
        type: "proatividade",
        value: 33,
        status: 1,
      },
      {
        type: "dinamismo",
        value: 43,
        status: 1,
      },
      {
        type: "dominancia",
        value: 53,
        status: 1,
      },
      {
        type: "extroversao",
        value: 63,
        status: 1,
      },
      {
        type: "relac_interpes",
        value: 33,
        status: 1,
      },
      {
        type: "sociabilidade",
        value: 32,
        status: 1,
      },
      {
        type: "orientadorelacionamento",
        value: 33,
        status: 1,
      },
    ],
    [
      { type: "tolerancia", value: 10, status: 1 },
      { type: "planejamento", value: 60, status: 2 },
      { type: "empatia", value: 53, status: 3 },
      { type: "capac_ouvir", value: 36, status: 4 },
      { type: "concentracao", value: 71, status: 5 },
      { type: "condescendencia", value: 13, status: 6 },
      { type: "perfil_tecnico", value: 93, status: 7 },
      { type: "organizacao", value: 63, status: 8 },
      { type: "detalhismo", value: 23, status: 1 },
      { type: "rigorosidade", value: 30, status: 1 },
      { type: "orientadorsultado", value: 33, status: 1 },
      { type: "multitarefas", value: 45, status: 1 },
      { type: "automotivacao", value: 53, status: 1 },
      { type: "proatividade", value: 73, status: 1 },
      { type: "dinamismo", value: 43, status: 1 },
      { type: "dominancia", value: 53, status: 1 },
      { type: "extroversao", value: 13, status: 1 },
      { type: "relac_interpes", value: 33, status: 1 },
      { type: "sociabilidade", value: 32, status: 1 },
      { type: "orientadorelacionamento", value: 33, status: 1 },
    ],
  ],
  talentsArea = {
    dx1: 30,
    dy1: 10,
    dx2: 22,
    dy2: -10,
    showTable: true,
    name: "",
    image: "",
  },
  matchProfiler = {
    primary: {
      img: "https://avatars.slack-edge.com/2019-10-23/808278080631_36f0e314dd957949aa50_72.jpg",
      profiler: "PA",
      executor: "20%",
      comunicador: "10%",
      planejador: "40%",
      analista: "30%",
    },
    secondary: {
      img: "https://avatars.slack-edge.com/2019-10-23/808278080631_36f0e314dd957949aa50_72.jpg",
      profiler: "AC",
      executor: "15%",
      comunicador: "30%",
      planejador: "15%",
      analista: "40%",
    },
  },
  circlePercentage = {
    percentage: 25,
    progressColor: "#601e82",
    bgColor: "whitesmoke",
    textColor: "#601e82",
    lineWidth: 44,
    roundedStroke: true,
    showPercentage: true,
    size: "small",
  },
  matriz = [
    {
      primaryProfiler: "A",
      nivelenergia: 7,
      nivelestress: 5,
      ia_nivel: 2,
      moral_nivel: 3,
      nivelip: 3,
      amplitude_nivel: 4,
      niveltempo: 1,
      flex_nivel: 4,
      nivel_incitab: 5,
      nivel_energperfil: 2,
      picture: "https://ca.slack-edge.com/TPQBE51MK-UPCS5A6CA-c0e3d6b92cf6-512",
    },
    {
      secondaryProfiler: "PC",
      nivelenergia: 7,
      nivelestress: 5,
      ia_nivel: 2,
      moral_nivel: 3,
      nivelip: 7,
      amplitude_nivel: 4,
      niveltempo: 1,
      flex_nivel: 6,
      nivel_incitab: 3,
      nivel_energperfil: 7,
      picture: "https://ca.slack-edge.com/TPQBE51MK-UPBHK9AEN-e78367acccd8-72",
    },
  ],
  matchNegativePositive = {
    primary: {
      name: "Name primary",
      profiler: "PA",
      img: "https://avatars.slack-edge.com/2019-10-23/808278080631_36f0e314dd957949aa50_72.jpg",
      positive: {
        executor: "30%",
        planejador: "30%",
        comunicador: "20%",
        analista: "20%",
      },
      negative: {
        executor: "30%",
        planejador: "30%",
        comunicador: "20%",
        analista: "20%",
      },
    },
    secondary: {
      profiler: "A",
      name: "Name secondary",
      img: "https://avatars.slack-edge.com/2019-10-23/808278080631_36f0e314dd957949aa50_72.jpg",
      positive: {
        executor: "30%",
        planejador: "30%",
        comunicador: "20%",
        analista: "20%",
      },
      negative: {
        executor: "30%",
        planejador: "30%",
        comunicador: "20%",
        analista: "20%",
      },
    },
  },
  positiveNegative = {
    data: {
      name: "Name primary",
      positive: {
        executor: "30%",
        planejador: "30%",
        comunicador: "20%",
        analista: "20%",
      },
      negative: {
        executor: "30%",
        planejador: "30%",
        comunicador: "20%",
        analista: "20%",
      },
    },
  },
}) => {
  const renderToltipDescription = (title, text, value) => {
    return (
      <>
        <Styled.TitleToolip>{title}</Styled.TitleToolip>
        <Styled.ContainerTooltip>
          <Styled.Text>{text}</Styled.Text>
          <Styled.Text>{value}</Styled.Text>
        </Styled.ContainerTooltip>
      </>
    );
  };

  const estiloLideranca = {
    dominante: {
      text: `A dominância é a autoridade de um indivíduo.
                Sua capacidade de delegar e organizar colaboradores.`,
    },
    informal: {
      text: "A descontração de um indíviduo. Seu desprendimento de regras e padrões.    ",
    },
    condescendente: {
      text: "Indica a abertura do indivíduo, sua capacidade de ser flexível e envolver outras pessoas em decisões. ",
    },

    formal: {
      text: "Indica quanto o indivíduo é ligado a regras e padrões. Também define a capacidade de foco do mesmo.",
    },
  };

  const renderChart = () => {
    const flexbilidade = {
      title: "Flexibilidade",
      text: "O índice de flexibilidade define sua capacidade de se adaptar. Sua facilidade em mudar de comportamento ou trabalhar de formas diferentes do comum.",
    };
    const energiaPerfil = {
      title: "Automotivação",
      text: "A energia do perfil está diretamente ligada ao perfil resultado do seu Profiler. É a energia de longo prazo, energia de manutenção para continuar as tarefas diárias.",
    };
    const exigencia = {
      title: "Exigência do meio",
      text: "A exigência do meio é o quanto você se sente cobrado por fatores externos. Pode ser o ambiente de trabalho, a rotina ou qualquer outra influência externa",
    };

    const aproveitamento = {
      title: "Aproveitamento",
      text: `Esse índice determina o quanto você se sente aproveitado em seu ambiente de trabalho.
                Se suas entregas são valorizadas, suas capacidades e competências estão sendo alocadas
                da melhor forma.`,
    };

    const moral = {
      title: "Moral",
      text: "O moral indica sua autoaprovação profissional. O quanto você está satisfeito com a qualidade de suas entregas, sua função de trabalho, sua rotina.",
    };
    const positividade = {
      title: "Positividade",
      text: "Este índice define o quanto você se sente satisfeito consigo mesmo, pessoalmente. Sua autoaprovação pessoal, que também impacta seu trabalho e sua produtividade.",
    };
    const amplitude = {
      title: "Amplitude",
      text: "A influência define o quanto você afeta o ambiente à sua volta e como o mesmo também afeta sua produtividade, positiva ou negativamente.",
    };
    const tempoResp = {
      title: "Tempo de Resposta",
      text: "Esse índice mede sua velocidade de resposta durante o preenchimento do teste.",
    };
    const energia = {
      title: "Energia ",
      text: "Seu nível de energia pessoal é o seu pique para o trabalho. Sua força, disposição, motivação para entrega de resultados.",
    };
    const incitabilidade = {
      title: "Incitabilidade",
      text: `O índice de incitabilidade é sua velocidade de resposta ao se
                deparar com um novo projeto, desafio, tarefa ou problema no trabalho.
                Mede sua rapidez de resposta a qualquer tipo de estímulo`,
    };

    const renderIndicadores = () => {
      let data;
      switch (situationalIndicator.title) {
        case "Flexibilidade":
          const { title, text } = flexbilidade;
          data = {
            content: renderToltipDescription(title, text),
          };
          return data;

        case "Exigência do meio":
          data = {
            content: renderToltipDescription(exigencia.title, exigencia.text),
          };
          return data;

        case "Energia do perfil":
        case "Automotivação":
          data = {
            content: renderToltipDescription(
              energiaPerfil.title,
              energiaPerfil.text
            ),
          };
          return data;

        case "Aproveitamento":
          data = {
            content: renderToltipDescription(
              aproveitamento.title,
              aproveitamento.text
            ),
          };
          return data;

        case "Moral":
          data = {
            content: renderToltipDescription(moral.title, moral.text),
          };
          return data;

        case "Positividade":
          data = {
            content: renderToltipDescription(
              positividade.title,
              positividade.text
            ),
          };
          return data;
        case "Amplitude":
          data = {
            content: renderToltipDescription(amplitude.title, amplitude.text),
          };
          return data;
        case "Tempo de resposta":
          data = {
            content: renderToltipDescription(tempoResp.title, tempoResp.text),
          };
          return data;
        case "Energia":
          data = {
            content: renderToltipDescription(energia.title, energia.text),
          };
          return data;

        case "Incitabilidade":
          data = {
            content: renderToltipDescription(
              incitabilidade.title,
              incitabilidade.text
            ),
          };
          return data;
        default:
          return data;
      }
    };

    const renderEstiloLider = () => {
      const { dominante, informal, condescendente, formal } = estiloLideranca;

      const data = {
        dominante: renderToltipDescription(dominante.title, dominante.text),
        informal: renderToltipDescription(informal.title, informal.text),
        condescendente: renderToltipDescription(
          condescendente.title,
          condescendente.text
        ),
        formal: renderToltipDescription(formal.title, formal.text),
      };
      return data;
    };

    const competencesTooltip = [
      {
        type: "tolerancia",
        tooltipContent: {
          title: "",
          text: "Capacidade de tolerar diferentes maneiras de pensar, agir e sentir, mesmo sendo opostas às adotadas por si mesmo. ",
        },
      },
      {
        type: "planejamento",
        tooltipContent: {
          title: "",
          text: "Capacidade de planejar as ações, processos e atividades. ",
        },
      },
      {
        type: "empatia",
        tooltipContent: {
          title: "",
          text: "Capacidade de compreender o sentimento ou reação da outra pessoa imaginando-se nas mesmas circunstâncias. ",
        },
      },
      {
        type: "capac_ouvir",

        tooltipContent: {
          title: "",
          text: "Capacidade de escuta ativa, habilidade de ouvir com atenção. ",
        },
      },
      {
        type: "concentracao",

        tooltipContent: {
          title: "",
          text: `Nível de capacidade/necessidade de concentração para execução de um
                        trabalho que exige atenção e constância. Essa competência mensura não
                        só a capacidade de concentração da pessoa como também sua necessidade
                        de estar em um ambiente adequado para tal atividade. `,
        },
      },
      {
        type: "condescendencia",

        tooltipContent: {
          title: "",
          text: "Indica o quanto a pessoa considera e pondera as intenções, desejos e opinião de outrem, agindo com complacência para buscar a melhor ação possível.",
        },
      },
      {
        type: "perfil_tecnico",

        tooltipContent: {
          text: `Aptidão para habilidades técnicas. Indica a capacidade de a pessoa se
                        tecnificar, dar ou proporcionar recursos técnicos a uma atividade para
                        otimizá-la.`,
        },
      },
      {
        type: "organizacao",

        tooltipContent: {
          title: "",
          text: `É a capacidade de um indivíduo de organizar suas ideias de maneira
                        clara e bem definida. Essa competência resvala no ambiente físico também
                        como a capacidade de manter o ambiente limpo e arrumado.`,
        },
      },
      {
        type: "detalhismo",

        tooltipContent: {
          title: "",
          text: "Capacidade de exposição minuciosa de fatos, planos ou projetos, com atenção a detalhes. ",
        },
      },
      {
        type: "rigorosidade",

        tooltipContent: {
          title: "",
          text: "Competência de uma pessoa exata, precisa, exigente e de raciocínio rigoroso. ",
        },
      },
      {
        type: "orientadorsultado",

        tooltipContent: {
          title: "",
          text: "É a competência que identifica o quanto a pessoa se desenvolve pelo trabalho e pela ação, investindo mais tempo na execução das tarefas.",
        },
      },
      {
        type: "multitarefas",

        tooltipContent: {
          title: "",
          text: "Capacidade de executar várias tarefas ao mesmo tempo. ",
        },
      },
      {
        type: "automotivacao",

        tooltipContent: {
          title: "",
          text: "Indica o nível da capacidade de a pessoa se auto-motivar, a capacidade de motivar-se ao entusiasmo.",
        },
      },
      {
        type: "proatividade",

        tooltipContent: {
          title: "",
          text: "Indica a capacidade de agir antecipadamente, resolvendo situações e problemas futuros sem que seja obrigatoriamente requerido.",
        },
      },
      {
        type: "dinamismo",

        tooltipContent: {
          title: "",
          text: "Particularidade e característica de quem demonstra energia, movimento e vitalidade, lida bem com mudanças e ambientes dinâmicos.",
        },
      },
      {
        type: "dominancia",

        tooltipContent: {
          title: "",
          text: "Competência de quem exerce uma postura dominante, firme. Normalmente toma decisões rápidas por ser menos avesso a risco e assume uma postura de comando.",
        },
      },
      {
        type: "extroversao",

        tooltipContent: {
          title: "",
          text: "Característica de quem é extrovertido, expansivo, comunicativo e sociável. Uma pessoa extrovertida tem facilidade em se sociabilizar. ",
        },
      },
      {
        type: "relac_interpes",

        tooltipContent: {
          title: "",
          text: "Capacidade de estabelecer conexões ou vínculos com outras pessoas dentro de um determinado contexto.",
        },
      },
      {
        type: "sociabilidade",

        tooltipContent: {
          title: "",
          text: " Indica a necessidade e a tendência à busca por relacionamento social com as outras pessoas, de forma expansiva e extrovertida. ",
        },
      },
      {
        type: "orientadorelacionamento",

        tooltipContent: {
          title: "",
          text: " O nível de foco da pessoa em relacionamentos. Esta competência indica o quanto a pessoa se desenvolve por relacionamentos e os prioriza em suas tarefas.",
        },
      },
    ];

    const renderCompetences = () => {
      const data = competencesTooltip.map((item) => {
        return {
          type: item.type,
          tooltipContent: item.tooltipContent ? item.tooltipContent : {},
        };
      });

      const response = data.map((res) => {
        return {
          type: res.type,
          render: renderToltipDescription(
            res.tooltipContent.title,
            res.tooltipContent.text
          ),
        };
      });

      return response;
    };

    switch (type) {
      case "nineBox":
        return (
          <NineBox
            quadrantChartData={quadrantChartData}
            fallbackClickQuadrant={fallbackClickQuadrant}
          />
        );
      case "profilesBar":
        return (
          <ProfilesBar
            executor={profilesBar.executor}
            comunicador={profilesBar.comunicador}
            planejador={profilesBar.planejador}
            analista={profilesBar.analista}
            tooltipsValue={{
              tooltipExecutor: profilesBar.tooltipExecutor,
              tooltipComunicador: profilesBar.tooltipComunicador,
              tooltipAnalista: profilesBar.tooltipAnalista,
              tooltipPlanejador: profilesBar.tooltipPlanejador,
            }}
            plus={profilesBar.plus}
            hideTexts={hideTexts}
            isTooltip={isTooltip}
            //tooltipContent={_renderStyleTootip()}
            size={size}
            dominante={dominante}
            energy_level_executor={
              !!profilesBar.predominant_energy_level &&
              profilesBar.predominant_energy_level.executor
            }
            energy_level_comunicador={
              !!profilesBar.predominant_energy_level &&
              profilesBar.predominant_energy_level.comunicador
            }
            energy_level_planejador={
              !!profilesBar.predominant_energy_level &&
              profilesBar.predominant_energy_level.planejador
            }
            energy_level_analista={
              !!profilesBar.predominant_energy_level &&
              profilesBar.predominant_energy_level.analista
            }
          />
        );

      case "mediumRequirement":
        return (
          <MediumRequirement
            voce={mediumRequirement.voce}
            meio={mediumRequirement.meio}
            name={name}
            flexibilidade={mediumRequirement.flexibilidade}
            isTooltip={isTooltip}
          />
        );

      case "searchResults":
        return (
          <SearchResults
            voce={searchResults.voce}
            meio={searchResults.meio}
            flexibilidade={searchResults.flexibilidade}
            isTooltip={isTooltip}
            name={name}
            tooltipContent={renderEstiloLider()}
          />
        );

      case "compareProfilers":
        return (
          <CompareProfilers
            negative={compareProfilers.negative}
            positive={compareProfilers.positive}
            plus={compareProfilers.plus}
          />
        );

      case "positiveNegative":
        return <PositiveNegative data={positiveNegative.data} />;

      case "situationalIndicator":
        return (
          <SituationalIndicator
            title={situationalIndicator.title}
            type={situationalIndicator.type}
            plus={situationalIndicator.plus}
            textIndicador={situationalIndicator.textIndicador}
            animation={situationalIndicator.animation}
            isTooltip={isTooltip}
            tooltipContent={renderIndicadores()}
          />
        );
      case "competences":
        return (
          <Competences
            data={competences}
            animation={animation}
            withOutGraphic={withOutGraphic}
            withList={withList}
            objectSliders={objectSliders}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            primaryName={primaryName}
            sencodaryName={sencodaryName}
            isTooltip={isTooltip}
            tooltipColumnRight={renderCompetences()}
          />
        );
      case "talentsArea":
        return (
          <TalentsArea
            dx1={talentsArea.dx1}
            dy1={talentsArea.dy1}
            dx2={talentsArea.dx2}
            dy2={talentsArea.dy2}
            showTable={talentsArea.showTable}
            showList={talentsArea.showList}
            image={talentsArea.image}
            name={talentsArea.name}
            textIndicador={talentsArea.textIndicador}
            showDescription={talentsArea.showDescription}
          />
        );
      case "matchProfiler":
        return (
          <MatchProfiler
            primary={matchProfiler.primary}
            secondary={matchProfiler.secondary}
          />
        );

      case "matrizSituationalIndicator":
        return (
          <>
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].nivelenergia}
              locationTwo={matriz[1].nivelenergia}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Energia"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].nivelestress}
              locationTwo={matriz[1].nivelestress}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Exigência do meio"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].ia_nivel}
              locationTwo={matriz[1].ia_nivel}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Energia do perfil"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].moral_nivel}
              locationTwo={matriz[1].moral_nivel}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Aproveitamento"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].nivelip}
              locationTwo={matriz[1].nivelip}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Moral"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].amplitude_nivel}
              locationTwo={matriz[1].amplitude_nivel}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Otimismo"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].niveltempo}
              locationTwo={matriz[1].niveltempo}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Incitabilidade"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].flex_nivel}
              locationTwo={matriz[1].flex_nivel}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Flexibilidade"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].nivel_incitab}
              locationTwo={matriz[1].nivel_incitab}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Amplitude"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].nivel_energperfil}
              locationTwo={matriz[1].nivel_energperfil}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Tempo de resposta"
            />
          </>
        );

      case "matrizCompetences":
        return (
          <>
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].tolerancia}
              locationTwo={matriz[1].tolerancia}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Tolerância"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].planejamento}
              locationTwo={matriz[1].planejamento}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Planejamento"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].empatia}
              locationTwo={matriz[1].empatia}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Empatia"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].capac_ouvir}
              locationTwo={matriz[1].capac_ouvir}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Capacidade de ouvir"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].concentracao}
              locationTwo={matriz[1].concentracao}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Concentração"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].condescendencia}
              locationTwo={matriz[1].condescendencia}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Condescendência"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].perfil_tecnico}
              locationTwo={matriz[1].perfil_tecnico}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Perfil técnico"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].organizacao}
              locationTwo={matriz[1].organizacao}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Organização"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].detalhismo}
              locationTwo={matriz[1].detalhismo}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Detalhismo"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].rigorosidade}
              locationTwo={matriz[1].rigorosidade}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Rigorosidade"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].orientadorsultado}
              locationTwo={matriz[1].orientadorsultado}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Orientado por resultado"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].multitarefas}
              locationTwo={matriz[1].multitarefas}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Multitarefas"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].automotivacao}
              locationTwo={matriz[1].automotivacao}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Automotivação"
            />

            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].proatividade}
              locationTwo={matriz[1].proatividade}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Proatividade"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].dinamismo}
              locationTwo={matriz[1].dinamismo}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Dinamismo"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].dominancia}
              locationTwo={matriz[1].dominancia}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Dominância"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].extroversao}
              locationTwo={matriz[1].extroversao}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Extroversão"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].relac_interpes}
              locationTwo={matriz[1].relac_interpes}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Relacionamento interpessoal"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].sociabilidade}
              locationTwo={matriz[1].sociabilidade}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Sociabilidade"
            />
            <Matriz
              primaryProfiler={matriz[0].primaryProfiler}
              secondaryProfiler={matriz[1].secondaryProfiler}
              nameUserPrimary={matriz[0].nameUserPrimary}
              nameUserSecondary={matriz[1].nameUserSecondary}
              locationOne={matriz[0].orientadorelacionamento}
              locationTwo={matriz[1].orientadorelacionamento}
              pictureOne={matriz[0].picture}
              pictureTwo={matriz[1].picture}
              name="Orientado por relacionamento"
            />
          </>
        );

      case "circlePercentage":
        return <CirclePercentage data={circlePercentage} />;

      case "matchNegativePositive":
        return (
          <MatchNegativePositive
            primary={matchNegativePositive.primary}
            secondary={matchNegativePositive.secondary}
          />
        );

      case "matchTalentsArea":
        return <MatchTalentsArea {...matchTalentsArea} />;
      case "chartline":
        return <Chartline chartline={chartline} />;
      case "chartinteraction":
        return (
          <ChartInteraction
            mediumRequirement={mediumRequirement}
            searchResults={searchResults}
            pdfTemplate={pdfTemplate}
            pdfLeader={pdfLeader}
          />
        );

      default:
        break;
    }
  };

  return renderChart();
};

export default Charts;
