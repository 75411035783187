import React from "react";
import { ReactComponent as Up } from "../../../../assets/svg/upCard.svg";
import { ReactComponent as Down } from "../../../../assets/svg/downCard.svg";
import { ReactComponent as Equals } from "../../../../assets/svg/equalsCard.svg";
import * as Styled from "./styled";
import { Tooltip } from "antd";
import getTextLevel from "../../../../utils/functions/getTextLevel";
import getStaticTextsProfiler from "../../../../utils/functions/getStaticTextsProfiler";

const Profiler = ({
  dominante = "",
  executor = "30%",
  planejador = "20%",
  comunicador = "20%",
  analista = "30%",
  energy_level_executor,
  energy_level_comunicador,
  energy_level_planejador,
  energy_level_analista,
  plus,
  tooltipsValue,
  // plus = {
  //     // executor: 10
  //     // planejador: -1,
  //     // comunicador: 0,
  //     // analista: 8
  // },
  isTooltip = false,
  tooltipContent,
  hideTexts,
  size = "medium",
}) => {
  const verifyDominante = (type) => {
    switch (type) {
      case "executor":
        for (let i = 0; i < dominante.length; i++) {
          if (dominante.charAt(i) === "E") {
            return true;
          }
        }
        return false;

      case "comunicador":
        for (let i = 0; i < dominante.length; i++) {
          if (dominante.charAt(i) === "C") {
            return true;
          }
        }
        return false;

      case "analista":
        for (let i = 0; i < dominante.length; i++) {
          if (dominante.charAt(i) === "A") {
            return true;
          }
        }
        return false;

      case "planejador":
        for (let i = 0; i < dominante.length; i++) {
          if (dominante.charAt(i) === "P") {
            return true;
          }
        }
        return false;
      default:
        return false;
    }
  };

  const _renderChartExecutor = () => {
    return (
      <Styled.ItemExecutor size={size}>
        <Styled.ItemText>
          {verifyDominante("executor") && <Styled.BallWhite />}
        </Styled.ItemText>
      </Styled.ItemExecutor>
    );
  };

  const _renderChartComunicador = () => {
    return (
      <Styled.ItemComunicador size={size}>
        <Styled.ItemText>
          {verifyDominante("comunicador") && <Styled.BallWhite />}
        </Styled.ItemText>
      </Styled.ItemComunicador>
    );
  };

  const _renderChartPlanejador = () => {
    return (
      <Styled.ItemPlanejador size={size}>
        <Styled.ItemText>
          {verifyDominante("planejador") && <Styled.BallWhite />}
        </Styled.ItemText>
      </Styled.ItemPlanejador>
    );
  };

  const _renderChartAnalista = () => {
    return (
      <Styled.ItemAnalista size={size}>
        <Styled.ItemText>
          {verifyDominante("analista") && <Styled.BallWhite />}
        </Styled.ItemText>
      </Styled.ItemAnalista>
    );
  };

  const renderCharts = () => {
    if (isTooltip === true) {
      return (
        <>
          <Tooltip
            overlayClassName="ant-tooltip-custumer"
            placement="top"
            title={
              <>
                <Styled.TitleToolip>Palavras-chave:</Styled.TitleToolip>
                <Styled.Label>Resultados, diretos e decisivos.</Styled.Label>
                {tooltipsValue.tooltipExecutor.toFixed(2)}%
              </>
            }
          >
            {_renderChartExecutor()}
          </Tooltip>
          <Tooltip
            overlayClassName="ant-tooltip-custumer"
            placement="top"
            title={
              <>
                <Styled.TitleToolip>Palavras-chave:</Styled.TitleToolip>
                <Styled.Label>Entusiasmo, diversão e imagem.</Styled.Label>
                {tooltipsValue.tooltipComunicador.toFixed(2)}%
              </>
            }
          >
            {_renderChartComunicador()}
          </Tooltip>
          <Tooltip
            overlayClassName="ant-tooltip-custumer"
            placement="top"
            title={
              <>
                <Styled.TitleToolip>Palavras-chave:</Styled.TitleToolip>
                <Styled.Label>
                  Estabilidade, paciência e processos.
                </Styled.Label>
                {tooltipsValue.tooltipPlanejador.toFixed(2)}%
              </>
            }
          >
            {_renderChartPlanejador()}
          </Tooltip>
          <Tooltip
            overlayClassName="ant-tooltip-custumer"
            placement="top"
            title={
              <>
                <Styled.TitleToolip>Palavras-chave:</Styled.TitleToolip>
                <Styled.Label>Exatidão, corretos e cuidadosos.</Styled.Label>
                {(
                  (tooltipsValue.tooltipExecutor +
                    tooltipsValue.tooltipComunicador +
                    tooltipsValue.tooltipPlanejador -
                    100) *
                  -1
                ).toFixed(2)}
                %
              </>
            }
          >
            {_renderChartAnalista()}
          </Tooltip>
        </>
      );
    }
    return (
      <>
        {_renderChartExecutor()}
        {_renderChartComunicador()}
        {_renderChartPlanejador()}
        {_renderChartAnalista()}
      </>
    );
  };

  return (
    <Styled.ProfilerBar hideTexts={hideTexts}>
      <Styled.Chart
        executor={executor}
        planejador={planejador}
        comunicador={comunicador}
        analista={analista}
      >
        {renderCharts()}
      </Styled.Chart>
      {hideTexts ? null : (
        <>
          <Styled.Info>
            <Styled.InfoGrid>
              <InfoGridItem
                percentage={executor}
                type="executor"
                label=""
                energy={energy_level_executor}
                plus={!!plus && plus.executor}
              />
              <InfoGridItem
                percentage={comunicador}
                type="comunicador"
                label=""
                energy={energy_level_comunicador}
                plus={!!plus && plus.comunicador}
              />
              <InfoGridItem
                percentage={planejador}
                type="planejador"
                label=""
                energy={energy_level_planejador}
                plus={!!plus && plus.planejador}
              />
              <InfoGridItem
                percentage={analista}
                type="analista"
                label=""
                energy={energy_level_analista}
                plus={!!plus && plus.analista}
              />
            </Styled.InfoGrid>
          </Styled.Info>
        </>
      )}
    </Styled.ProfilerBar>
  );
};

const InfoGridItem = ({ percentage, type, energy = "", label = "", plus }) => {
  const _handleRenderLevel = () => {
    const nivel = getTextLevel(energy);

    if (nivel.length >= 15) {
      return (
        <>
          <span>{nivel.split(" ")[0]}</span>
          <span>{nivel.split(" ")[1]}</span>
        </>
      );
    } else {
      return (
        <>
          <span>{nivel}</span>
        </>
      );
    }
  };

  const _handleRenderType = () => {
    const staticTextsProfiler = getStaticTextsProfiler();
    const typesMap = {
      "executor": staticTextsProfiler.tipos.masculino.E,
      "comunicador": staticTextsProfiler.tipos.masculino.C,
      "planejador": staticTextsProfiler.tipos.masculino.P,
      "analista": staticTextsProfiler.tipos.masculino.A
    };

    return typesMap[type] || null;
  };

  return (
    <Styled.InfoGridItem>
      <Styled.InfoGridItemPercentage>{percentage}</Styled.InfoGridItemPercentage>
      <Styled.InfoGridItemType type={type}>{_handleRenderType()}</Styled.InfoGridItemType>
      <Styled.ProfilerEnergic>{_handleRenderLevel()}</Styled.ProfilerEnergic>
      <Styled.InfoGridItemLabel>{label !== "" && label}</Styled.InfoGridItemLabel>
      {plus && (
        <Styled.InfoGridItemPlus>
          {plus === "0" ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Equals style={{ width: 10, marginRight: 5 }} />
              {`${plus}%`}
            </div>
          ) : plus.split("-").length > 1 ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Down style={{ width: 10, marginRight: 5 }} />
              {`-${plus.split("-")[1]}%`}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Up style={{ width: 10, marginRight: 5 }} />
              {`${plus}%`}
            </div>
          )}
        </Styled.InfoGridItemPlus>
      )}
    </Styled.InfoGridItem>
  );
};

export default Profiler;
