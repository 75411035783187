import React from "react";
import * as Styled from "./styles";
// import "./index.scss";

import Negative from "./negative";
import Positive from "./positive";

function CompareProfilers({
    negative,
    positive,
    plus,
    // positive: { executor: 0, comunicador: 10, analista: 0, planejador: 0 },
    // negative: { executor: 0, comunicador: 10, analista: 0, planejador: 0 }
}) {
    return (
        <Styled.Container>
            <Styled.Info>
                <Styled.NegativeInfo>
                    <Styled.TextNegative>NEGATIVOS</Styled.TextNegative>
                </Styled.NegativeInfo>
                <Styled.PositiveInfo>
                    <Styled.TextPositive>POSITIVOS</Styled.TextPositive>
                </Styled.PositiveInfo>
            </Styled.Info>
            <Styled.Chart>
                <Negative type="executor" data={positive.executor} />
                <Positive type="executor" data={negative.executor} />
            </Styled.Chart>
            <Styled.Chart>
                <Negative type="communicator" data={positive.comunicador} />
                <Positive type="communicator" data={negative.comunicador} />
            </Styled.Chart>
            <Styled.Chart>
                <Negative type="planner" data={positive.planejador} />
                <Positive type="planner" data={negative.planejador} />
            </Styled.Chart>
            <Styled.Chart>
                <Negative type="analyst" data={positive.analista} />
                <Positive type="analyst" data={negative.analista} />
            </Styled.Chart>
            <Styled.InfoBottom>
                <Styled.Points>
                    <Styled.PointsText>PONTOS DE ATENÇÃO</Styled.PointsText>
                </Styled.Points>
                <Styled.Highlights>
                    <Styled.HighlightsText>DESTAQUES</Styled.HighlightsText>
                </Styled.Highlights>
            </Styled.InfoBottom>
        </Styled.Container>
    );
}

export default CompareProfilers;
