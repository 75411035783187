import styled from "styled-components";

export const MatchProfilerContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 100%;
`;

export const MatchFirst = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-top: 10px;
    box-sizing: border-box;
`;

export const TextImagePerson = styled.span`
    font-size: 16px;
    line-height: 10px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
`;

export const MatchImage = styled.div`
    width: 40px;
    height: 40px;
    ${({ data }) => data && `background-image: url('${data}');`};
    ${({ data }) =>
        !data &&
        `align-items: center; display: flex; justify-content: center;`};
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-right: 5px;
`;

export const ContentPhoto = styled.div`
    flex: 1;
    flex-direction: row;
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: center;
`;

export const ContentChart = styled.div`
    flex: 5;
    position: relative;
`;

export const ChartContainer = styled.div`
    width: 100%;
    height: 45px;
`;

export const Chart = styled.div`
    display: grid;
    ${props =>
        `grid-template-columns: ${props.executor}% ${props.comunicador}% ${props.planejador}% ${props.analista}%`}
    width: 100%;
    position: absolute;
    top: 20%;
`;

export const ItemExecutor = styled.div`
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #d74149;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
`;

export const ItemPlanejador = styled.div`
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #57dd6d;
`;

export const ItemComunicador = styled.div`
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #ffba00;
`;

export const ItemAnalista = styled.div`
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #00c9db;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
`;

export const ItemText = styled.span`
    color: #fff;
    font-size: 20px;
    padding-right: 10px;
`;

export const ItemChart = styled.div`
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
export const ProfilerBarInfo = styled.div`
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    width: 100%;
    height: 20px;
    position: absolute;
    z-index: 2px;
    top: 0;
`;

export const Info = styled.div`
    ${props => props.last === true && "border-right: 0px !important;"}
    height: 80px;
    width: 100%;
    border-right: 1px solid #efedf3;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
`;

export const Dominante = styled.span`
    font-size: 11px;
    color: #5f4a87;
    font-weight: 800;
`;

export const Executor = styled.span`
    color: #d74149;
    font-size: 14px;
    font-weight: 800;
    display: flex;
    align-items: center;
    margin-right: 11px;
    ${({ dominante }) =>
        dominante ? `margin-top: 72px;` : `margin-top: 85px;`}
    svg {
        rect {
            fill: #d74149;
        }
        path {
            fill: #d74149;
        }
        margin-left: 3px;
        margin-right: 3px;
        width: 15px;
        height: 15px;
    }
`;

export const Planejador = styled.span`
    color: #57dd6d;
    font-size: 14px;
    font-weight: 800;
    display: flex;
    align-items: center;
    margin-left: 11px;
    ${({ dominante }) =>
        dominante ? `margin-top: 72px;` : `margin-top: 85px;`}
    svg {
        rect {
            fill: #57dd6d;
        }
        path {
            fill: #57dd6d;
        }
        margin-left: 3px;
        margin-right: 3px;
        width: 15px;
        height: 15px;
    }
`;

export const Comunicador = styled.span`
    color: #ffba00;
    font-size: 14px;
    font-weight: 800;
    display: flex;
    align-items: center;
    ${({ dominante }) =>
        dominante ? `margin-top: 72px;` : `margin-top: 85px;`}
    svg {
        rect {
            fill: #ffba00;
        }
        path {
            fill: #ffba00;
        }
        margin-left: 3px;
        margin-right: 3px;
        width: 15px;
        height: 15px;
    }
`;

export const Analista = styled.span`
    color: #00c9db;
    font-size: 14px;
    font-weight: 800;
    display: flex;
    align-items: center;
    margin-left: 11px;
    ${({ dominante }) =>
        dominante ? `margin-top: 72px;` : `margin-top: 85px;`}
    svg {
        rect {
            fill: #00c9db;
        }
        path {
            fill: #00c9db;
        }
        margin-left: 3px;
        margin-right: 3px;
        width: 15px;
        height: 15px;
    }
`;
