import React from "react";
import * as Styled from "./styles";
import { Tooltip } from "antd";
import getTextLevel from "../../../../utils/functions/getTextLevel";

// import "./index.scss";

const SituationalIndicator = ({
    title,
    type,
    plus,
    animation = false,
    isTooltip = false,
    tooltipContent = [],
    textIndicador,
}) => {
    const renderTitle = () => {
        const nivel = getTextLevel(type);

        if (nivel.length >= 15) {
            return (
                <>
                    <span>{nivel.split(" ")[0]} </span>
                    <span>{nivel.split(" ")[1]}</span>
                </>
            );
        } else {
            return (
                <>
                    <span>{nivel}</span>
                </>
            );
        }
    };
    const _renderNewSitualIndicator = () => {
        return (
            <Styled.ContainerSingle>
                <Styled.textIndicador className="pdfTitleCustomStyle">
                    {title}
                </Styled.textIndicador>
            </Styled.ContainerSingle>
        );
    };
    const _renderGraphicIndicador = () => {
        return (
            <Styled.GraphicIndicadorContainer>
                <Styled.ItemStatusText>{renderTitle()}</Styled.ItemStatusText>
                <Styled.GrahicpColor className="graphColorPdf" width={type} />
            </Styled.GraphicIndicadorContainer>
        );
    };
    const renderCharts = () => {
        if (isTooltip) {
            return (
                <>
                    <Tooltip
                        overlayClassName="ant-tooltip-custumer"
                        placement="top"
                        title={tooltipContent.content}
                    >
                        {_renderNewSitualIndicator()}
                    </Tooltip>

                    {_renderGraphicIndicador()}
                </>
            );
        }

        return (
            <>
                {_renderNewSitualIndicator()}
                {_renderGraphicIndicador()}
            </>
        );
    };

    return (
        <>
            <Styled.Containermain>{renderCharts()}</Styled.Containermain>
            {textIndicador && (
                <Styled.Text className="pdfContentCustomStyle">
                    {textIndicador}
                </Styled.Text>
            )}
        </>
    );
};

export default SituationalIndicator;
