import { all, takeLatest, put, call } from "redux-saga/effects";
import moment from "moment";

import { getPassportByToken } from "./repository";

function* getPassaportByTokenSaga({ payload }) {
  try {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: true },
    });

    const url = window.location.href.split("/");
    const token = url[3];

    const { data: profilerData } = yield call(getPassportByToken, token);

    const {
      name,
      gender,
      cellPhone,
      phone,
      email,
      picture,
      birthDate,
      result,
      profiler,
    } = profilerData;

    yield put({
      type: "@profiler/SET_PROFILER_SUCCESS",
      payload: {
        data: {
          ...profilerData,
          profiler: !!profiler ? profiler : "",
          birthDate: !!birthDate
            ? moment(birthDate, "DD/MM/YYYY").format("YYYY-MM-DD")
            : "",
        },
      },
    });

    yield put({
      type: "@profiler/SET_PERSON_SUCCESS",
      payload: {
        person: {
          name,
          gender,
          cellPhone,
          phone,
          email,
          picture,
          birthDate: !!birthDate
            ? moment(birthDate, "DD/MM/YYYY").format("YYYY-MM-DD")
            : "",
        },
      },
    });

    yield put({
      type: "@profiler/SET_PROFILER_ANSWERED_SUCCESS",
      payload: !!result,
    });

    yield put({
      type: "@profiler/SET_INVALID_LINK_SUCCESS",
      payload: false,
    });
  } catch (err) {
    // notification.error({
    //   message: "Erro referente ao token",
    //   description: `Não foi possível acessar por este token. Por favor, verifique se o mesmo ainda continua sendo válido.`,
    // });

    yield put({
      type: "@profiler/SET_ERROR",
      payload: true,
    });

    yield put({
      type: "@profiler/SET_INVALID_LINK_SUCCESS",
      payload: true,
    });
  } finally {
    yield put({
      type: "@utilities/LOADING",
      payload: { loading: false },
    });
  }
}

export default all([
  takeLatest("@profiler/GET_PASSAPORT_BY_TOKEN", getPassaportByTokenSaga),
]);
