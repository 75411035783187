import React, { useState, useCallback, useEffect } from "react";
import { Tooltip } from "antd";
import { ReactComponent as WhellDetails } from "../../../../../assets/svg/whellDetails.svg";
import { ReactComponent as WhellDetailsMobile } from "../../../../../assets/svg/whellDetailsMobile.svg";

import * as Styled from "./styles";
import getTokenLocale from "../../../../../utils/functions/getTokenLocale";

const Chart = (props) => {
  const data = [];
  if (props.data[0].length > 0) {
    data.push(
      {
        tolerancia: props.data[0][0].value / 100,
        planejamento: props.data[0][1].value / 100,
        empatia: props.data[0][2].value / 100,
        capac_ouvir: props.data[0][3].value / 100,
        concentracao: props.data[0][4].value / 100,
        condescendencia: props.data[0][5].value / 100,
        perfil_tecnico: props.data[0][6].value / 100,
        organizacao: props.data[0][7].value / 100,
        detalhismo: props.data[0][8].value / 100,
        rigorosidade: props.data[0][9].value / 100,
        orientadorsultado: props.data[0][10].value / 100,
        multitarefas: props.data[0][11].value / 100,
        automotivacao: props.data[0][12].value / 100,
        proatividade: props.data[0][13].value / 100,
        dinamismo: props.data[0][14].value / 100,
        dominacia: props.data[0][15].value / 100,
        extroversao: props.data[0][16].value / 100,
        relac_interpes: props.data[0][17].value / 100,
        sociabilidade: props.data[0][18].value / 100,
        orientadorelacionamento: props.data[0][19].value / 100,
      },
      {
        tolerancia: props.data[1][0].value / 100,
        planejamento: props.data[1][1].value / 100,
        empatia: props.data[1][2].value / 100,
        capac_ouvir: props.data[1][3].value / 100,
        concentracao: props.data[1][4].value / 100,
        condescendencia: props.data[1][5].value / 100,
        perfil_tecnico: props.data[1][6].value / 100,
        organizacao: props.data[1][7].value / 100,
        detalhismo: props.data[1][8].value / 100,
        rigorosidade: props.data[1][9].value / 100,
        orientadorsultado: props.data[1][10].value / 100,
        multitarefas: props.data[1][11].value / 100,
        automotivacao: props.data[1][12].value / 100,
        proatividade: props.data[1][13].value / 100,
        dinamismo: props.data[1][14].value / 100,
        dominacia: props.data[1][15].value / 100,
        extroversao: props.data[1][16].value / 100,
        relac_interpes: props.data[1][17].value / 100,
        sociabilidade: props.data[1][18].value / 100,
        orientadorelacionamento: props.data[1][19].value / 100,
      }
    );
  } else {
    data.push({
      tolerancia: props.data[0].value / 100,
      planejamento: props.data[1].value / 100,
      empatia: props.data[2].value / 100,
      capac_ouvir: props.data[3].value / 100,
      concentracao: props.data[4].value / 100,
      condescendencia: props.data[5].value / 100,
      perfil_tecnico: props.data[6].value / 100,
      organizacao: props.data[7].value / 100,
      detalhismo: props.data[8].value / 100,
      rigorosidade: props.data[9].value / 100,
      orientadorsultado: props.data[10].value / 100,
      multitarefas: props.data[11].value / 100,
      automotivacao: props.data[12].value / 100,
      proatividade: props.data[13].value / 100,
      dinamismo: props.data[14].value / 100,
      dominacia: props.data[15].value / 100,
      extroversao: props.data[16].value / 100,
      relac_interpes: props.data[17].value / 100,
      sociabilidade: props.data[18].value / 100,
      orientadorelacionamento: props.data[19].value / 100,
    });
  }

  const chartSize = 260; // Tamanho do Gráfico
  const numberOfScales = 4; // Número de circulos
  const scale = (value) => {
    // value dado por escala da constante numberOfScales ex 4 2 3 1
    return (
      <circle
        key={`scale-${value}`}
        cx={0}
        cy={0}
        r={((value / numberOfScales) * chartSize) / 2} // Para calcular o raio do círuclo
        fill="transparent"
        stroke="transparent"
        strokeWidth="0.2"
      />
    );
  };
  const polarToX = (angle, distance) =>
    Math.cos(angle - Math.PI / 2) * distance;
  const polarToY = (angle, distance) =>
    Math.sin(angle - Math.PI / 2) * distance;
  const pathDefinition = (points) => {
    let d = "M" + points[0][0].toFixed(4) + "," + points[0][1].toFixed(4);
    for (let i = 1; i < points.length; i++) {
      d += "L" + points[i][0].toFixed(4) + "," + points[i][1].toFixed(4);
    }
    return d + "z";
  };
  const [name, setName] = useState("");
  const [strokePrimary, setStrokePrimary] = useState("1");
  const [strokeSecondary, setStrokeSecondary] = useState("1");
  const [whellMobile, setWhellMobile] = useState(false);

  const nameUser = (peronName) => {
    if (peronName === undefined) return;
    const newName = peronName.split(" ");
    const name = `${newName[0]} ${newName[1] !== undefined ? newName[1] : ""}`;
    return name;
  };
  nameUser(props.primaryName);
  const _handleTooltipe = (item) => {
    if (item === 0) {
      setName(nameUser(props.primaryName));
      setStrokePrimary("3");
    } else {
      setName(nameUser(props.secondaryName));
      setStrokeSecondary("3");
    }
  };
  const _handleStrokerIn = (item) => {
    if (item === 0) {
      setStrokePrimary("1");
    } else {
      setStrokeSecondary("1");
    }
  };
  const shape = (columns) => (chartData, i) => {
    const data = chartData;
    return (
      <Tooltip
        overlayClassName="ant-tooltip-custumer"
        placement="top"
        title={name}
        key={i}
      >
        <path
          key={`shape-${i}`}
          d={pathDefinition(
            columns.map((col) => {
              const value = data[col.key];
              return [
                polarToX(col.angle, (value * chartSize) / 2),
                polarToY(col.angle, (value * chartSize) / 2),
              ];
            })
          )}
          stroke={i === 0 ? props.primaryColor : props.secondaryColor}
          strokeWidth={i === 0 ? strokePrimary : strokeSecondary}
          fill={i === 0 ? props.primaryColor : props.secondaryColor}
          fillOpacity={i === 0 ? ".25" : ".50"}
          onMouseEnter={() => _handleTooltipe(i)}
          onMouseLeave={() => _handleStrokerIn(i)}
        />
      </Tooltip>
    );
  };
  const points = (points) => {
    return points
      .map((point) => point[0].toFixed(4) + "," + point[1].toFixed(4))
      .join(" ");
  };
  const axis = () => (col, i) =>
    (
      <polyline
        style={{ display: "none" }}
        key={`poly-axis-${i}`}
        points={points([
          [0, 0],
          [
            polarToX(col.angle, chartSize / 2),
            polarToY(col.angle, chartSize / 2),
          ],
        ])}
        stroke="#555"
        strokeWidth="0"
        className="printNone"
      />
    );

  const groups = [];
  const scales = [];
  for (let i = numberOfScales; i > 0; i--) {
    scales.push(scale(i));
  }
  groups.push(<g key={`scales`}>{scales}</g>);
  const middleOfChart = (chartSize / 2).toFixed(4);
  const captions = Object.keys(data[0]);
  const columns = captions.map((key, i, all) => {
    return {
      key,
      angle: (Math.PI * 2 * i) / all.length,
    };
  });
  groups.push(<g key={` -axes`}>{columns.map(axis())}</g>);
  groups.push(<g key={`groups}`}>{data.map(shape(columns))}</g>);

  const resizeWhell = useCallback(
    (size) => {
      size < 702 ? setWhellMobile(true) : setWhellMobile(false);
      window.addEventListener("resize", function () {
        if (window.innerWidth < 702) {
          setWhellMobile(true);
        } else {
          setWhellMobile(false);
        }
      });
      if (getTokenLocale() !== "pt-BR") {
        setWhellMobile(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [whellMobile]
  );
  useEffect(() => {
    resizeWhell(window.innerWidth);
  }, [resizeWhell]);

  return (
    <Styled.StyledChart>
      {whellMobile ? <WhellDetailsMobile /> : <WhellDetails />}

      <div style={{ position: "absolute" }}>
        <svg
          version="1"
          xmlns="http://www.w3.org/2000/svg"
          width={chartSize}
          height={chartSize}
          viewBox={`0 0 ${chartSize} ${chartSize}`}
        >
          <g transform={`translate(${middleOfChart},${middleOfChart})`}>
            {groups}
          </g>
        </svg>
      </div>
    </Styled.StyledChart>
  );
};
export default Chart;
