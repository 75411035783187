export const theme = {
  colors: {
    communicator: "#FFBA00",
    executor: "#CA2037",
    planner: "#00A760",
    analyst: "#2657ED",

    communicatorLight: "#FFDA78",
    executorLight: "#EB8B97",
    plannerLight: "#5CC99B",
    analystLight: "#5C84FF",

    communicatorDark: "#DEA100",
    executorDark: "#992B3A",
    plannerDark: "#007546",
    analystDark: "#2C4BA8",
  },
};
