import styled from "styled-components";

export const Negative = styled.div`
    flex: 1;
    height: 40px;
`;

export const Content = styled.div`
    display: flex;
`;

export const OneDivision = styled.div`
    width: 100%;
    ${({ atencao }) =>
        atencao ? `background: #FFFFCC; z-index: 3 ` : `background: #fff`}
    padding: 5px 0px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    height: 40px;
    border-left: 1px solid #efedf3;
    border-top: 1px solid #efedf3;
    border-bottom: 1px solid #efedf3;
    font-size: 22px;
`;

export const OneDivisionItem = styled.div`
    display: flex;
    flex: 1;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: #716588;
    height: 20px;
    justify-content: flex-start;
    align-items: center;
    margin-left: 18px;

    @media screen and (max-width: 540px) {
        font-size: 16px;
    }
`;

export const OneDivisionItemPlus = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 3px;
    align-items: center;

    svg {
        width: 13px;
        height: 13px;
        margin-left: 2px;
        margin-right: 3px;
        path {
            color: #716588;
            fill: #716588;
        }
        rect {
            fill: #716588;
        }
    }
`;

export const SeccondDivision = styled.div`
    width: 100%;
    ${({ atencao }) =>
        atencao ? `background: #FFFFCC; z-index: 3 ` : `background: #fff`}
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    height: 40px;
    border-top: 1px solid #efedf3;
    border-bottom: 1px solid #efedf3;
    font-size: 22px;
`;

export const Result = styled.div`
    ${({ size }) => size && `width: ${size}`}
    ${({ type }) =>
        type === "executor"
            ? `background: #ac343a`
            : type === "comunicador"
            ? `background: #cc9500`
            : type === "planejador"
            ? `background: #46b157`
            : `background: #2880ae`};
    z-index: 2;
    height: 40px;
    display: flex;
    margin-right: -10px;
    justify-content: flex-end;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
`;

export const AtencaoDestaqueText = styled.div`
    margin-top: 0px !important;
    font-size: 14px;
    color: #5f4a87;
    font-weight: bold;
    font-family: "Roboto Condensed";
`;
