import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
   * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
  }
  html, body, #root {
    min-height: 100%;
  }
  body {
    -webkit-font-smoothing: antialiased !important;
    background: #f4f0eb;
  }
  button {
    cursor: pointer;
  }

  .ant-tooltip-break-line {
    word-wrap:break-word;
    white-space: pre-line !important;
  }

  @media print{
    *{
      box-shadow: none !important;
      
    }
    .bVkUOV{
      left:0 !important;
      
      box-shadow: none !important;
    }
    .ieVrbl, .eFoGLw,.sc-iruUXe, .sc-kStNKc  {
      margin-top: 0 !important;
    }
    body, .laagWT{
       -webkit-print-color-adjust: exact;
       background: #fff;
       margin: -35px auto  auto;
       max-width: 100% !important;
    }
      .printNone{ 
        display: none !important;
      }

      .gQzlLt, .dBApxY{
        display: none !important;
      }
      /** PRINT PROFILER PERSON */
    #talentsArea{
      margin: 40px 0 !important;
    }
    #talentArea {
      margin: 0px !important;
    }
    .kcSnVk{
      padding: 20px 0 !important;
    }
    #profilesBar, .ilRXjW {
      margin: 5px !important;
    }
    .dFFqEX{
        margin: 10px 0 !important;
      }
    .sc-ljUfdc svg text {
      font-size: 12px !important;
    }
      .cpOVUl{
        display: none;
      }
    
      #situationalIndicator{
        margin-top: 10px !important;
      }
      #situationalIndicator .sc-dTsoBL{
        font-weight: normal !important;
      }
      #chartinteraction {
        margin-top:60px !important;
      
        text-align: center !important;
        .dkriez {
            display: flex;
            margin: 50px 0;
        }
        svg text{
          font-size: 11.5px !important;
        }
      }
      
      #competences {
        margin-top: 200px !important;
      }
      
      .cZWVI{
        margin:10px !important;
        height: 29px !important;
      }
      .sc-gOhSNZ{
        margin:0 !important;
      }
      .sc-ePAWwb, .sc-kOnlKp > div{
        font-weight: normal !important;
      }
      #talentsArea {
        margin-top:25px !important;
      }
      #talentsArea .sc-bCQtTp{
        margin-top: 0;
      }
     .dORuQh {
          left: inherit !important;
          right: 0 !important;
        }

      .sc-eMgOci {
        margin-top: 0 !important;
      }
      .kyubDe{
        padding: 8px 0 !important;
        margin: 0 !important;
      }
      .bOPpCv {
        padding-top: 10px !important;
      }
      .jPCNSo{
        padding-top: 0 !important;
      }
      .eznCTY{
        margin-top: -20px  !important;
      }
      .jiyRnC{
        margin-left:15px !important;
      }
      .fodKaY{
        width: 200px;
      }
      .bOipZf{
        margin: 40px 0;
      }
      .sc-ekHBYt{
        margin-top:30px !important;
      }
      .cymmOJ{
        margin: 0 !important;
        max-width: 100% !important;
      }

      /** peopleAnalytics */
      .sc-clWJBl {
        margin-top: 0 !important;
      }
      .sc-kYnif  .sc-cVxdjG  .sc-fsPSfr {
        margin-top:110px !important;
      }
      .sc-bjPkoM{
        margin-top: 90px !important;
      }
      .sc-iclvYL, .jrwnOL{
        font-size: 12px !important;
      }
      .dutptL, .dQstJa{
        margin:15px 0;
      }
      .dJGTNo, .sc-cmUJln {
        font-weight: normal !important;
        font-size: 16px !important;
        top: -25px !important;
      }
      .sc-iupvsZ{
        margin-top: 35px;
      }
      .ftKQoK{
        height: 45px;
      }
      .ixgOCl {
        flex: 2.3;
      }
      .efZEj  {
        flex: 1;
      }
      
      .dutptL{
        margin-bottom: 0px;
      }

      .sc-jvjHmY, svg {
        padding: 0;
      }
      .sc-jvjHmY svg  text{
       font-size: 11px !important;
        padding: 0;
      }

      .jSgXYm{
        padding:25px 0;
      }

      .sc-jRnjsG{
        padding: 10px  0;
      }
      .hesgzm{
        margin: 15px 0;
      }
      .eHPwfc {
        width: 40%;
      }
        .sc-wRHdD, .sc-jwJjzT {
        font-size: 10px;
      }
      .sc-dtmqqe{
        margin-bottom:10px;
      }
 /** recruitment */
      #container_recruitment .bpcEGW {
          flex-wrap: wrap;
      }
      .eOFpSk {
        margin-top: -10px
      }

      /** recruitment */
      #container_recruitment .bpcEGW {
          flex-wrap: wrap;
      }
    .eOFpSk {
      margin-top: -10px
    }
    
    /***  print 9box */
    .iuLYym {
      padding: 0px 7px;
    }
   
    #headerQuestion {
      margin-top: 10px;
      margin-bottom: 0;
    }

    #percentage-ninebox{
        margin: 7px 0 7px;
    }
    #percentage-ninebox .ezzWgj {
      margin: 0px 22px 0px 0;
    }

    #quadrantPrint{
      width: 99%;
      margin: 0 auto;
      top: 5px;
      margin-bottom: 110px;
      position: relative;
      left: 10px;
    }
    #quadrantPrint .fCpWfH{
      margin: 0px 20px;
    }
   

    #firsPrintListPeople {
      margin-top: 120px !important;
      padding-top: 110px;
    }
    #firsPrintListPeople .iJyiJw  {
      margin:0 -27px;
      margin-top: 20px;
    }
    #firsPrintListPeople .cgvXJj  {
      padding:0;
      position: relative;
      left: 20px;
    }
 
  .insuficientes{
    padding-top: 200px !important;
  }
    #printNineBox {
      width: 100% !important;
      max-width: 100% !important;
      margin-top: -10px !important;
     
    }
    .gulbIq{
      left:0!important;
    }
  }
  
`;
