import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import ResultProfiler from '../person/body/profiler/resultProfiler';
import EmptyMessage from '../../components/emptyMessage';

import * as profilerActions from '../../store/modules/profiler/actions';

import * as Style from './style';

function Profiler({
  profiler,
  person,
  profilerAnswered,
  invalidLink,
  history,
  loading,
  error,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profilerActions.getPassportByToken());
  }, [dispatch]);

  const renderProfiler = () => {
    if (loading) {
      return (
        <EmptyMessage
          messagemTitle="Aguarde..."
          messageBox="Estamos carregando as informações do seu profiler."
          history={history}
        />
      );
    } else if (error) {
      return (
        <EmptyMessage
          messagemTitle="Algo de errado aconteceu."
          messageBox="Por favor, entre em contato com nosso time de suporte."
          history={history}
        />
      );
    } else {
      if (invalidLink === true) {
        return (
          <div style={{ height: '100vh', display: 'flex' }}>
            <EmptyMessage
              messagemTitle="Atenção !"
              messageBox="Esse link está desativado. Por favor, entre em contato com nosso suporte. "
              history={history}
            />
          </div>
        );
      } else {
        if (profilerAnswered === true && profiler !== {}) {
          return (
            <Style.BackgroundProfiler>
              <ResultProfiler
                textValidation={profiler.result.test_validation}
                chartData={profiler.result.chart_data}
                profileTexts={profiler.result.profile_texts}
                dataProfiler={profiler.result}
                gender={profiler.gender}
                name={profiler.name}
                image={profiler.picture}
                dateProfilerCurrent={profiler.profiler_last_date}
                typeReports={profiler.profilerType}
                infoPerson={{
                  logo: profiler.logoUrl,
                  name: profiler.name,
                  yearsOld: profiler.yearsOld,
                  position: profiler.position,
                  phone: profiler.phone,
                  email: profiler.email,
                  profiler: profiler.profiler,
                  nameEnterprise: profiler.nameEnterprise,
                }}
              />
            </Style.BackgroundProfiler>
          );
        } else {
          return (
            <div style={{ height: '100vh', display: 'flex' }}>
              <EmptyMessage
                messagemTitle="Atenção !"
                messageBox="Algo de errado aconteceu. Por favor, entre em contato com nosso suporte. "
                history={history}
              />
            </div>
          );
        }
      }
    }
  };

  return renderProfiler();
}

const mapStateToProps = (state) => ({
  profiler: state.profiler.profiler,
  person: state.profiler.person,
  profilerAnswered: state.profiler.profilerAnswered,
  invalidLink: state.profiler.invalidLink,
  loading: state.profiler.loading,
  error: state.profiler.error,
});

const mapDispatchToProps = {};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Profiler);
