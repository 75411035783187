import styled from "styled-components";

export const TalentsArea = styled.div`
  ${({ showTable }) => (showTable ? `display: flex` : `display: none`)}
  margin-top: 20px;
  flex-direction: column;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  min-height: 395px;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 360px) {
    margin-right: 0px;
  }
`;

export const ContainerTalents = styled.div`
  width: 300px;
  height: 300px;
  position: relative;
  /* top: 60px;
    right: 15px; */
`;

export const First = styled.div`
  display: flex;
  flex: 1;
  background: #fff;
  opacity: 0.7;
`;

export const Second = styled.div`
  display: flex;
  flex: 1;
  background: #fff;
  opacity: 0.7;
`;

//ONE
export const LineOne = styled.div`
  flex: 1;
  height: 75px;
  display: flex;
`;

export const Index4 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f4a87;
  font-weight: bold;
  font-size: 22px;
  font-family: "Roboto Condensed";
  flex: 1;
  margin: 1px;
  border-top-left-radius: 10px;
  background: rgba(255, 184, 0, 0.25);
`;

export const Index7_1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f4a87;
  font-weight: bold;
  font-size: 22px;
  font-family: "Roboto Condensed";
  flex: 1;
  margin: 1px 0px;
  background: rgba(255, 184, 0, 0.25);
`;

export const Index7_2 = styled.div`
  display: flex;
  flex: 1;
  margin: 1px 0px;
  background: rgba(38, 199, 64, 0.25);
  position: relative;
  span {
    position: absolute;
    top: 24px;
    left: -4.2px;
    color: #5f4a87;
    font-weight: bold;
    font-size: 22px;
    font-family: "Roboto Condensed";
  }
`;

export const Index8 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f4a87;
  font-weight: bold;
  font-size: 22px;
  font-family: "Roboto Condensed";
  flex: 1;
  margin: 1px;
  background: rgba(38, 199, 64, 0.25);
  border-top-right-radius: 10px;
`;

//TWO

export const LineTwo = styled.div`
  flex: 1;
  height: 75px;
  display: flex;
`;

export const Index3_1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f4a87;
  font-weight: bold;
  font-size: 22px;
  font-family: "Roboto Condensed";
  flex: 1;
  margin: 1px 1px 0px 1px;
  background: rgba(255, 184, 0, 0.25);
`;

export const Index5 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f4a87;
  font-weight: bold;
  font-size: 22px;
  font-family: "Roboto Condensed";
  flex: 1;
  margin: 1px;
  background: rgba(255, 184, 0, 0.25);
`;

export const Index6 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f4a87;
  font-weight: bold;
  font-size: 22px;
  font-family: "Roboto Condensed";
  flex: 1;
  margin: 1px;
  background: rgba(255, 184, 0, 0.25);
`;

export const Index9 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f4a87;
  font-weight: bold;
  font-size: 22px;
  font-family: "Roboto Condensed";
  flex: 2;
  margin: 1px 0px;
  background: rgba(38, 199, 64, 0.25);
  z-index: 20;
`;

export const Index10_1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f4a87;
  font-weight: bold;
  font-size: 22px;
  font-family: "Roboto Condensed";
  flex: 1;
  margin: 1px 1px 0px 1px;
  background: rgba(38, 199, 64, 0.25);
`;

//THREE

export const LineThree = styled.div`
  flex: 1;
  height: 75px;
  display: flex;
`;

export const Index3_2 = styled.div`
  display: flex;
  flex: 1;
  margin: 0px 1px 1px 1px;
  background: rgba(240, 81, 37, 0.25);
  position: relative;
  span {
    position: absolute;
    top: -12px;
    left: 19px;
    color: #5f4a87;
    font-weight: bold;
    font-size: 22px;
    font-family: "Roboto Condensed";
  }
`;

export const Index2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f4a87;
  font-weight: bold;
  font-size: 22px;
  font-family: "Roboto Condensed";
  flex: 1;
  margin: 1px;
  background: rgba(240, 81, 37, 0.25);
`;

export const Index12 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f4a87;
  font-weight: bold;
  font-size: 22px;
  font-family: "Roboto Condensed";
  flex: 2;
  margin: 1px 0px;
  background: rgba(50, 160, 218, 0.25);
`;

export const Index10_2 = styled.div`
  display: flex;
  flex: 1;
  margin: 0px 1px 1px 1px;
  background: rgba(50, 160, 218, 0.25);
  position: relative;
  span {
    position: absolute;
    top: -12px;
    left: 14px;
    color: #5f4a87;
    font-weight: bold;
    font-size: 22px;
    font-family: "Roboto Condensed";
  }
`;

//FOUR

export const LineFour = styled.div`
  flex: 1;
  height: 75px;
  display: flex;
`;

export const Index1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f4a87;
  font-weight: bold;
  font-size: 22px;
  font-family: "Roboto Condensed";
  flex: 1;
  margin: 1px 1px;
  background: rgba(240, 81, 37, 0.25);
  border-bottom-left-radius: 10px;
`;

export const Index13_1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f4a87;
  font-weight: bold;
  font-size: 22px;
  font-family: "Roboto Condensed";
  flex: 1;
  margin: 1px 0px;
  background: rgba(240, 81, 37, 0.25);
`;

export const Index13_2 = styled.div`
  display: flex;
  font-family: "Roboto Condensed";
  flex: 2;
  margin: 1px 0px;
  background: rgba(50, 160, 218, 0.25);
  position: relative;
  span {
    position: absolute;
    top: 24px;
    left: -10px;
    color: #5f4a87;
    font-weight: bold;
    font-size: 22px;
    font-family: "Roboto Condensed";
  }
`;

export const Index11 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5f4a87;
  font-weight: bold;
  font-size: 22px;
  font-family: "Roboto Condensed";
  flex: 2.4;
  margin: 1px;
  background: rgba(50, 160, 218, 0.25);
  border-bottom-right-radius: 10px;
`;

//POINTS

export const ContainerPointerBlue = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 27px;
  background: transparent;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ top }) => `top: ${top}px;`};
  ${({ left }) => `left: ${left}px;`};
`;

export const PointBlue = styled.div`
  width: 30px;
  height: 30px;
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 200px;
  border: 2px solid #fff;
`;

export const ContainerPointerRed = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 38px;
  background: transparent;
  /* border: 2px solid #e44c1a; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  ${({ top }) => `top: ${top}px;`}
  ${({ left }) => `left: ${left}px;`}
`;

export const PointRed = styled.div`
  width: 14px;
  height: 14px;
  background: #da222b;
  border: 1px solid #fff;
  border-radius: 3px;
`;

export const ContainerMark = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 2px;
  height: 2px;
  background: transparent;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ top }) => `top: ${top}px;`}
  ${({ left }) => `left: ${left}px;`}
`;

export const PointMark = styled.div`
  width: 2px;
  height: 2px;
  background: black;
  border-radius: 200px;
`;

//LEGENDS

export const Legends = styled.div`
  position: absolute;
  width: 300px;
  height: 300px;
  top: 66px;
`;

export const Emotion = styled.p`
  position: absolute;
  top: -25px;
  right: 140px;
  text-align: center;
  color: #601e82;

  @media screen and (max-width: 540px) {
    font-size: 14px;
  }
`;

export const Introversion = styled.p`
  position: absolute;
  top: 140px;
  transform: rotate(90deg);
  left: 260px;
  color: #601e82;

  @media screen and (max-width: 540px) {
    font-size: 14px;
  }
`;

export const Extroversion = styled.p`
  position: absolute;
  top: 140px;
  transform: rotate(270deg);
  right: 290px;
  color: #601e82;

  @media screen and (max-width: 540px) {
    font-size: 14px;
  }
`;

export const Reason = styled.p`
  position: absolute;
  top: 295px;
  right: 145px;
  color: #601e82;
  text-align: center;

  @media screen and (max-width: 540px) {
    font-size: 14px;
  }
`;

//LEGENDS GRAPH

export const LegendGraph = styled.div`
  position: absolute;
  display: flex;
  top: 200px;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  left: 550px;

  @media (max-width: 795px) {
    position: static;
    margin-top: 20px;
  }
`;

export const Blue = styled.div`
  width: 32px;
  height: 32px;
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  flex: 1;
`;

export const BlueWhite = styled.div`
  width: 36px;
  height: 36px;
  background: #5f4a87;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #fff;
    font-family: "Roboto Condensed";
  }
  border: 2px solid #fff;
`;

export const BlueWhitePoint = styled.div`
  width: 36px;
  height: 36px;
  background: #5f4a87;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  span {
    color: #fff;
    font-family: "Roboto Condensed";
  }
`;

export const Voce = styled.span`
  color: #8872b2;
  margin-left: 10px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
`;

export const Red = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Meio = styled.span`
  color: #da222b;
  margin-left: 10px;
  flex: 1;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
`;

//LIST

export const ListTalents = styled.div`
  flex: 1;
  margin-left: 25px;
  margin-top: 30px;
  width: 98%;

  @media screen and (max-width: 720px) {
    margin-top: 50px;
    margin-left: 0px;
    width: 100%;
  }
`;

export const ListColumn = styled.div`
  margin-top: 0px;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media screen and (max-width: 720px) {
    margin-left: -20px;
    margin-right: 5px;
  }
`;

//LIST ITEM

export const TalentsAreaItem = styled.div`
  padding: 5px;
  height: 30px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
`;

export const Number = styled.span`
  color: #601e82;
  font-weight: bold;
  font-size: 14px;
  flex: 0.54;
  font-family: "Roboto Condensed";
`;

export const Name = styled.span`
  flex: 4;
  font-size: 14px;
  color: #8872b2;
  font-weight: bold;
`;

export const ItemList = styled.div`
  flex: 10;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 20px;

  span {
    font-size: 12px;
    color: #5f4a87;
    font-weight: bold;
    font-family: Roboto Condensed;
    line-height: 24px;
    text-transform: uppercase;
  }
`;
export const Text = styled.div`
  margin: 9px 0 0 40px;
  border-left: 1px solid #efedf3;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 125%;
  text-align: justify;
  color: #8872b2;
  padding: 10px 18px;
  display: flex;
  align-items: center;
`;
