import styled from "styled-components";

export const MatchTalentsArea = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 40px;
    padding-right: 40px;
`;

export const LeftContainer = styled.div`
    flex: 1;
    border-right: 1px solid rgba(122, 134, 154, 0.15);
    padding-left: 50px;
    padding-right: 20px;
`;

export const RightContainer = styled.div`
    flex: 1;
    padding-left: 50px;
`;

export const List = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`;

export const TitleList = styled.span`
    color: #8872b2;
    font-weight: bold;
    font-size: 15px;
    font-family: "Roboto Condensed";
`;

export const TitleText = styled.span`
    color: #8872b2;
    font-size: 14px;
    font-family: "Roboto Condensed";
`;

export const TextImagePerson = styled.span`
    font-size: 16px;
    line-height: 10px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
`;

export const MatchImage = styled.div`
    width: 72px;
    height: 72px;
    ${({ data }) => data && `background-image: url(${data});`};
    ${({ data }) =>
        !data &&
        `align-items: center; display: flex; justify-content: center;`};
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 200px;
    border-radius: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;

export const ImgContent = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    z-index: 2;
    padding-bottom: 10px;
`;
