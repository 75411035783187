import styled from "styled-components";

export const ContainerMain = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: nowrap;
  position: relative;
  width: 100%;
  @media (max-width: 702px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ChartLeft = styled.div`
  svg {
    width: 300px;
  }
`;
export const ChartRight = styled.div`
  svg {
    width: 300px;
  }
  @media (max-width: 702px) {
    width: 100%;
    text-align: center;
  }
`;
export const Middle = styled.div`
  align-self: center;
  @media (max-width: 702px) {
    text-align: center;
  }

  @media (max-width: 480px) {
    flex: initial;
  }
`;
export const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
export const TextLegend = styled.div`
  padding: 0 10px;

  font-size: 11px;
  line-height: 105.2%;
  color: #601e82;
  text-transform: uppercase;
`;
export const Title = styled.h1`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 130%;
  color: #601e82;
  text-transform: uppercase;
  padding-top: 15px;
  padding-bottom: 20px;
  text-align: center;
  @media (max-width: 480px) {
    text-align: center;
  }
`;

export const Group = styled.g`
  .shadow {
    filter: drop-shadow(0 0 10px blue);
  }
`;

export const TooltipText = styled.div`
  display: block;
  background-color: #fff;
  color: #fff;
  text-align: center;
  padding: 5px;
  position: absolute;
  top: 0px;
  left: 15px;
  border-radius: 6px;
  z-index: 100;
  font-size: 0.75rem;
  visibility: visible;
  width: 237px;
`;
