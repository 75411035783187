import React, { useState, useEffect } from "react";
import * as Styled from "./style";
const Chartline = ({ chartline, ...rest }) => {
    const [charts, setCharts] = useState(chartline);

    useEffect(() => {
        setCharts(chartline);
    }, [chartline]);

    const renderChart = charts.map((item, index) => (
        <Styled.Progress key={index}>
            <Styled.ProgressRows>
                <Styled.Title>{item.title}</Styled.Title>
            </Styled.ProgressRows>
            <Styled.ProgressRows>
                <Styled.ContainerProgressStep
                    funnel="center"
                    progressBackground={item.progressBackground}
                >
                    <Styled.CurrentProgressStep
                        percentage={item.percentage}
                        progressColor={item.progressColor}
                    />
                    <Styled.Label funnel="center" percentage={item.percentage}>
                        {item.total}
                    </Styled.Label>
                </Styled.ContainerProgressStep>
                <Styled.percentage>
                    {parseInt(item.percentage)} %
                </Styled.percentage>
            </Styled.ProgressRows>
        </Styled.Progress>
    ));

    return (
        <Styled.Container>
            <Styled.ContentBox>{renderChart}</Styled.ContentBox>
        </Styled.Container>
    );
};

export default Chartline;
