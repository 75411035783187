import React from "react";
import Chart from "./chart";
import * as Styled from "./styles";

const Graphic = ({ data, primaryName, sencodaryName }) => {
  return (
    <Styled.GraphicTalents>
      <Styled.BoxResult>
        <Chart
          data={data}
          primaryColor="#601e82"
          secondaryColor="#1b1b1b"
          primaryName={primaryName}
          secondaryName={sencodaryName}
        />
      </Styled.BoxResult>
    </Styled.GraphicTalents>
  );
};

export default Graphic;
