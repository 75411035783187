import styled from "styled-components";

export const Horizontal = styled.hr`
    margin-top: 20px;
    border: 1px solid #f4f0eb;
    box-sizing: border-box;
    max-width: 90px;
    width: 88px;
    background: #f4f0eb;
    height: 1px;
    border: none;
`;

export const Vertical = styled.div`
    position: relative;
    top: 10px;
    height: 22px;
    border-left: 1px solid #f4f0eb;
`;

export const Container = styled.div`
    display: flex;
    flex-wrap: nowrap;
    margin-right: 17px;
`;

export const BoxTitle = styled.div`
    display: flex;
    width: 100%;
`;

export const TitleEmpty = styled.div`
    flex: 1;
`;

export const ContainerTitle = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 3.7;
    margin-bottom: 15px;
`;

export const ContainerMoods = styled.div`
    display: flex;
    justify-content: center;
    text-align: right;
    width: 255px;
    height: 40px;
    justify-content: flex-end;
    align-items: center;
    line-height: 12px;
`;

export const Mood = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #5f4a87;
    line-height: 15px;
`;

export const MainBox = styled.div`
    display: flex;
`;

export const SpanLines = styled.span`
    padding: 50px 0px 0px 0px;
    font-weight: bold;
    font-size: 10px;
    color: #8872b2;
`;

export const SpanMoods = styled.span`
    padding: 80px 0px 0px 0px;
    font-weight: bold;
    font-size: 12px;
    color: #5f4a87;
`;

export const TextImagePerson = styled.span`
    font-size: 12px;
    line-height: 10px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
`;

export const FirstPicture = styled.div`
    ${({ data }) => data && `background-image: url(${data});`};
    ${({ data }) =>
        !data &&
        `align-items: center; display: flex; justify-content: center;`};
    background-color: ${({ backgroundColor }) => backgroundColor};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    border: solid #fff 2px;
    z-index: 1;
    left: ${props =>
        props.location === 1
            ? "49px"
            : props.location === 2
            ? "137px"
            : props.location === 3
            ? "226px"
            : props.location === 4
            ? "315px"
            : props.location === 5
            ? "404px"
            : props.location === 6
            ? "493px"
            : props.location === 7
            ? "582px"
            : props.location === 1.5
            ? "38px"
            : props.location === 2.5
            ? "127px"
            : props.location === 3.5
            ? "217px"
            : props.location === 4.5
            ? "306px"
            : props.location === 5.5
            ? "395px"
            : props.location === 6.5
            ? "484px"
            : props.location === 7.5
            ? "574px"
            : "49px"};
    width: 32px;
    height: 32px;
    border-radius: 100px;
`;

export const Legend = styled.p`
    position: absolute;
    margin-top: 44px;
    font-size: 14px;
    width: 100px;
`;

export const SecondPicture = styled.div`
    ${({ data }) => data && `background-image: url(${data});`};
    ${({ data }) =>
        !data &&
        `align-items: center; display: flex; justify-content: center;`};
    background-color: ${({ backgroundColor }) => backgroundColor};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;
    border: solid #fff 2px;
    left: ${props =>
        props.location === 1
            ? "16px"
            : props.location === 2
            ? "105px"
            : props.location === 3
            ? "194px"
            : props.location === 4
            ? "283px"
            : props.location === 5
            ? "372px"
            : props.location === 6
            ? "461px"
            : props.location === 7
            ? "550px"
            : props.location === 1.5
            ? "26px"
            : props.location === 2.5
            ? "114px"
            : props.location === 3.5
            ? "204px"
            : props.location === 4.5
            ? "292px"
            : props.location === 5.5
            ? "381px"
            : props.location === 6.5
            ? "470px"
            : props.location === 7.5
            ? "560px"
            : "16px"};
    width: 32px;
    height: 32px;
    border-radius: 100px;
`;
