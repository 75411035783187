export const backgroundColorByProfiler = function(string) {
    if (!!string === false) {
        return "#5F4A87";
    }
    if (string === "Bloqueado") {
        return "#C6176E";
    } else if (string === "Não respondido" || string === "Expirado") {
        return "#E86303";
    }

    const profiler = string.split("");

    switch (profiler[0]) {
        case "E":
            return "#DA222B";
        case "C":
            return "#FFB800";
        case "A":
            return "#0EA2D0";
        case "P":
            return "#26C740";
        default:
            return "#5F4A87";
    }
};

export const backgroundColorBorderByProfiler = function(string) {
    if (!string) return "#5F4A87";

    const profiler = string
        .split("")
        .toString()
        .replace("E", "|E|")
        .replace("A", "|A|")
        .replace("P", "|P|")
        .replace("C", "|C|")
        .replace("|E|", "#DA222B")
        .replace("|A|", "#0EA2D0")
        .replace("|P|", "#26C740")
        .replace("|C|", "#FFB800");
    return profiler;
};

// DA222B Executor , 5F4A87 Dafult, FFB800 Comunicador, 0EA2D0 Analista, 26C740 Planejador
