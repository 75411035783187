import styled from "styled-components";

export const Text = styled.span`
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    padding: 0 5px;
`;
export const TitleToolip = styled.p`
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 3px;
`;

export const ContainerTooltip = styled.div`
    display: flex;
    flex-direction: column;
`;
