import { jwtDecode } from "jwt-decode";

function getTokenLocale() {
  try {
    const token = window.location.href.split("/")[3];
    const locale = jwtDecode(token).locale;

    return locale || "pt-BR";
  } catch {
    return "pt-BR";
  }
}

export default getTokenLocale;