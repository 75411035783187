import React from "react";
import * as Styled from "./styles";
import { getInitials } from "../../../../utils/functions/getInitials";
import { Tooltip } from "antd";
import getStaticTextsProfiler from "../../../../utils/functions/getStaticTextsProfiler";
import getTextLevel from "../../../../utils/functions/getTextLevel";

const TalentsArea = ({
    dx1,
    dy1,
    dx2,
    dy2,
    showTable = true,
    showList = true,
    image,
    name = "",
    textIndicador,
    pdfTemplate,
    showDescription,
}) => {
    const staticTextsProfiler = getStaticTextsProfiler();
    const auxDx1 = dx1 * 2.125 + 102;
    const auxDy1 = dy1 * -4.636 + 102;
    const auxDx2 = dx2 * 2.125 + 102;
    const auxDy2 = dy2 * -4.636 + 102;

    const calculaPontos = (number, topLeft, bottomRight) => {
        const calculaSideRed = (arr = [topLeft, bottomRight], auxDx, auxDy) => {
            const result = arr.map(e => {
                return Math.sqrt(
                    Math.pow(e.x - (auxDx + 47), 2) +
                        Math.pow(e.y - (auxDy + 47), 2)
                );
            });

            const distance = Math.floor(Math.max(0, 8 - result / 30));

            return { resultRed: result[0], distanceRed: distance };
        };

        const calculaSideBlue = (
            arr = [topLeft, bottomRight],
            auxDx,
            auxDy
        ) => {
            const result = arr.map(e => {
                return Math.sqrt(
                    Math.pow(e.x - (auxDx + 47), 2) +
                        Math.pow(e.y - (auxDy + 47), 2)
                );
            });

            const distance = Math.floor(Math.max(0, 8 - result / 30));

            return { resultBlue: result[0], distanceBlue: distance };
        };

        const center = {
            x: (bottomRight.x + topLeft.x) / 2,
            y: (bottomRight.y + topLeft.y) / 2,
        };

        return {
            ...calculaSideRed([center], auxDx2, auxDy2),
            ...calculaSideBlue([center], auxDx1, auxDy1),
            center,
        };
    };

    const map = [
        {
            id: 0,
            number: 1,
            ...calculaPontos(1, { x: 2, y: 227 }, { x: 73, y: 296 }),
        },
        {
            id: 1,
            number: 2,
            ...calculaPontos(2, { x: 77, y: 152 }, { x: 146, y: 221 }),
        },
        {
            id: 2,
            number: 3,
            ...calculaPontos(3, { x: 2, y: 150 }, { x: 71, y: 221 }),
        },
        {
            id: 3,
            number: 3,
            ...calculaPontos(3, { x: 2, y: 77 }, { x: 46, y: 148 }),
        },
        {
            id: 4,
            number: 4,
            ...calculaPontos(4, { x: 2, y: 2 }, { x: 73, y: 71 }),
        },
        {
            id: 5,
            number: 5,
            ...calculaPontos(5, { x: 52, y: 77 }, { x: 96, y: 146 }),
        },
        {
            id: 6,
            number: 6,
            ...calculaPontos(6, { x: 102, y: 77 }, { x: 145, y: 146 }),
        },
        {
            id: 7,
            number: 7,
            ...calculaPontos(7, { x: 77, y: 2 }, { x: 149, y: 70 }),
        },
        {
            id: 8,
            number: 7,
            ...calculaPontos(7, { x: 149, y: 2 }, { x: 221, y: 70 }),
        },
        {
            id: 9,
            number: 8,
            ...calculaPontos(8, { x: 225, y: 2 }, { x: 295, y: 70 }),
        },
        {
            id: 10,
            number: 9,
            ...calculaPontos(9, { x: 150, y: 77 }, { x: 245, y: 146 }),
        },
        {
            id: 11,
            number: 10,
            ...calculaPontos(10, { x: 249, y: 77 }, { x: 296, y: 148 }),
        },
        {
            id: 12,
            number: 10,
            ...calculaPontos(10, { x: 249, y: 150 }, { x: 296, y: 221 }),
        },
        {
            id: 13,
            number: 11,
            ...calculaPontos(11, { x: 218, y: 227 }, { x: 296, y: 296 }),
        },
        {
            id: 14,
            number: 12,
            ...calculaPontos(12, { x: 150, y: 152 }, { x: 245, y: 221 }),
        },
        {
            id: 15,
            number: 13,
            ...calculaPontos(13, { x: 150, y: 227 }, { x: 215, y: 296 }),
        },
        {
            id: 16,
            number: 13,
            ...calculaPontos(13, { x: 77, y: 227 }, { x: 148, y: 296 }),
        },
    ];

    const nameCrumb = name => {
        if (!!name) {
            const newName = name.split(" ");
            const firstName = newName[0];
            const secondName =
                newName[newName.length - 1] !== newName[0]
                    ? newName[newName.length - 1]
                    : "";
            return `${firstName} ${secondName}`;
        }
    };

    return (
        <Styled.TalentsArea showTable={showTable}>
            <Styled.ContainerTalents>
                <Styled.LineOne>
                    <Styled.First>
                        <Styled.Index4>4</Styled.Index4>
                        <Styled.Index7_1></Styled.Index7_1>
                    </Styled.First>
                    <Styled.Second>
                        <Styled.Index7_2>
                            <span>7</span>
                        </Styled.Index7_2>
                        <Styled.Index8>8</Styled.Index8>
                    </Styled.Second>
                </Styled.LineOne>
                <Styled.LineTwo>
                    <Styled.First>
                        <Styled.Index3_1></Styled.Index3_1>
                        <Styled.Index5>5</Styled.Index5>
                        <Styled.Index6>6</Styled.Index6>
                    </Styled.First>
                    <Styled.Second>
                        <Styled.Index9>9</Styled.Index9>
                        <Styled.Index10_1></Styled.Index10_1>
                    </Styled.Second>
                </Styled.LineTwo>
                <Styled.LineThree>
                    <Styled.First>
                        <Styled.Index3_2>
                            <span>3</span>
                        </Styled.Index3_2>
                        <Styled.Index2>2</Styled.Index2>
                    </Styled.First>
                    <Styled.Second>
                        <Styled.Index12>12</Styled.Index12>
                        <Styled.Index10_2>
                            <span>10</span>
                        </Styled.Index10_2>
                    </Styled.Second>
                </Styled.LineThree>
                <Styled.LineFour>
                    <Styled.First>
                        <Styled.Index1>1</Styled.Index1>
                        <Styled.Index13_1></Styled.Index13_1>
                    </Styled.First>
                    <Styled.Second>
                        <Styled.Index13_2>
                            <span>13</span>
                        </Styled.Index13_2>
                        <Styled.Index11>11</Styled.Index11>
                    </Styled.Second>
                </Styled.LineFour>
                <Styled.ContainerPointerBlue
                    id="talentArea"
                    top={auxDy1}
                    left={auxDx1}
                >
                    {!image ? (
                        <Styled.BlueWhitePoint>
                            <span>{getInitials(name)}</span>
                        </Styled.BlueWhitePoint>
                    ) : (
                        <Styled.PointBlue image={image}></Styled.PointBlue>
                    )}
                </Styled.ContainerPointerBlue>
                <Styled.ContainerPointerRed top={auxDy2} left={auxDx2}>
                    <Styled.PointRed></Styled.PointRed>
                </Styled.ContainerPointerRed>
            </Styled.ContainerTalents>
            <Styled.LegendGraph id="legendResult">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {!image ? (
                        <Styled.BlueWhite>
                            <span>{getInitials(name)}</span>
                        </Styled.BlueWhite>
                    ) : (
                        <Styled.Blue image={image} />
                    )}
                    <Styled.Voce>{nameCrumb(name)}</Styled.Voce>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginTop: 10,
                    }}
                >
                    <Styled.Red>
                        <div
                            style={{
                                width: 12,
                                height: 12,
                                background: "#da222b",
                                borderRadius: "3px",
                            }}
                        />
                    </Styled.Red>
                    <Styled.Meio>{staticTextsProfiler.complementares.ambiente}</Styled.Meio>
                </div>
            </Styled.LegendGraph>

            {showList && (
                <Styled.ListTalents>
                    <Styled.ListColumn>
                        <Item
                            number={1}
                            name={textIndicador.comandante.titulo}
                            red={map.filter(e => e.number === 1)[0].distanceRed}
                            blue={
                                map.filter(e => e.number === 1)[0].distanceBlue
                            }
                            textIndicador={
                                showDescription && textIndicador && textIndicador.comandante
                            }
                        />
                        <Item
                            number={2}
                            name={textIndicador.competidor.titulo}
                            red={map.filter(e => e.number === 2)[0].distanceRed}
                            blue={
                                map.filter(e => e.number === 2)[0].distanceBlue
                            }
                            textIndicador={
                                showDescription && textIndicador && textIndicador.competidor
                            }
                        />
                        <Item
                            number={3}
                            name={textIndicador.administrador.titulo}
                            red={Math.floor(
                                Math.max(
                                    0,
                                    8 -
                                        (map.filter(e => e.number === 3)[0]
                                            .resultRed +
                                            map.filter(e => e.number === 3)[1]
                                                .resultRed) /
                                            2 /
                                            30
                                )
                            )}
                            blue={
                                map.filter(e => e.number === 3)[0].distanceBlue
                            }
                            textIndicador={
                                showDescription && textIndicador && textIndicador.administrador
                            }
                        />
                        <Item
                            number={4}
                            name={textIndicador.motivador.titulo}
                            red={map.filter(e => e.number === 4)[0].distanceRed}
                            blue={
                                map.filter(e => e.number === 4)[0].distanceBlue
                            }
                            textIndicador={
                                showDescription && textIndicador && textIndicador.motivador
                            }
                        />
                        <Item
                            number={5}
                            name={textIndicador.vendedor.titulo}
                            red={map.filter(e => e.number === 5)[0].distanceRed}
                            blue={
                                map.filter(e => e.number === 5)[0].distanceBlue
                            }
                            textIndicador={
                                showDescription && textIndicador && textIndicador.vendedor
                            }
                            classNamePdf={textIndicador && "nextPage"}
                        />
                        <Item
                            number={6}
                            name={textIndicador.diplomata.titulo}
                            red={map.filter(e => e.number === 6)[0].distanceRed}
                            blue={
                                map.filter(e => e.number === 6)[0].distanceBlue
                            }
                            textIndicador={
                                showDescription && textIndicador && textIndicador.diplomata
                            }
                        />
                        <Item
                            number={7}
                            name={textIndicador.aconselhador.titulo}
                            red={Math.floor(
                                Math.max(
                                    0,
                                    8 -
                                        (map.filter(e => e.number === 7)[0]
                                            .resultRed +
                                            map.filter(e => e.number === 7)[1]
                                                .resultRed) /
                                            2 /
                                            30
                                )
                            )}
                            blue={
                                map.filter(e => e.number === 7)[0].distanceBlue
                            }
                            textIndicador={
                                showDescription && textIndicador && textIndicador.aconselhador
                            }
                        />
                        <Item
                            number={8}
                            name={textIndicador.atendente.titulo}
                            red={map.filter(e => e.number === 8)[0].distanceRed}
                            blue={
                                map.filter(e => e.number === 8)[0].distanceBlue
                            }
                            textIndicador={
                                showDescription && textIndicador && textIndicador.atendente
                            }
                        />
                        <Item
                            number={9}
                            name={textIndicador.professoral.titulo}
                            red={map.filter(e => e.number === 9)[0].distanceRed}
                            blue={
                                map.filter(e => e.number === 9)[0].distanceBlue
                            }
                            textIndicador={
                                showDescription && textIndicador && textIndicador.professoral
                            }
                        />
                        <Item
                            number={10}
                            name={textIndicador.tecnico.titulo}
                            red={Math.floor(
                                Math.max(
                                    0,
                                    8 -
                                        (map.filter(e => e.number === 10)[0]
                                            .resultRed +
                                            map.filter(e => e.number === 10)[1]
                                                .resultRed) /
                                            2 /
                                            30
                                )
                            )}
                            blue={
                                map.filter(e => e.number === 10)[0].distanceBlue
                            }
                            textIndicador={
                                showDescription && textIndicador && textIndicador.tecnico
                            }
                        />
                        <Item
                            number={11}
                            name={textIndicador.especialista.titulo}
                            red={
                                map.filter(e => e.number === 11)[0].distanceRed
                            }
                            blue={
                                map.filter(e => e.number === 11)[0].distanceBlue
                            }
                            textIndicador={
                                showDescription && textIndicador && textIndicador.especialista
                            }
                        />
                        <Item
                            number={12}
                            name={textIndicador.estrategista.titulo}
                            red={
                                map.filter(e => e.number === 12)[0].distanceRed
                            }
                            blue={
                                map.filter(e => e.number === 12)[0].distanceBlue
                            }
                            textIndicador={
                                showDescription && textIndicador && textIndicador.estrategista
                            }
                            classNamePdf={textIndicador && "nextPage"}
                        />
                        <Item
                            number={13}
                            name={textIndicador.controlador.titulo}
                            red={Math.floor(
                                Math.max(
                                    0,
                                    8 -
                                        (map.filter(e => e.number === 13)[0]
                                            .resultRed +
                                            map.filter(e => e.number === 13)[1]
                                                .resultRed) /
                                            2 /
                                            30
                                )
                            )}
                            blue={
                                map.filter(e => e.number === 13)[0].distanceBlue
                            }
                            textIndicador={
                                showDescription && textIndicador && textIndicador.controlador
                            }
                        />
                    </Styled.ListColumn>
                </Styled.ListTalents>
            )}
        </Styled.TalentsArea>
    );
};

const Item = ({
    number,
    name,
    red = 0,
    blue = 0,
    isTooltip = false,
    tooltipContent = [],
    textIndicador,
    classNamePdf,
}) => {
    if (isTooltip) {
        return (
            <Tooltip
                overlayClassName="ant-tooltip-custumer"
                placement="top"
                title={tooltipContent}
            >
                <Styled.TalentsAreaItem>
                    <Styled.Number>{number}</Styled.Number>
                    <Styled.Name>{name}</Styled.Name>
                    <Styled.ItemList>
                        <div style={{ marginTop: -12 }}>
                            <span>Baixo</span>
                        </div>
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                marginTop: 7,
                            }}
                        >
                            <div
                                style={{
                                    background: "#EFEDF3",
                                    width: "100%",
                                    position: "absolute",
                                    height: 8,
                                    top: -11,
                                    borderRadius: 20,
                                }}
                            ></div>
                            <div
                                style={{
                                    background: "#5F4A87",
                                    width: 20,
                                    position: "absolute",
                                    height: 8,
                                    top: -11,
                                    borderRadius: 20,
                                }}
                            ></div>
                        </div>
                    </Styled.ItemList>
                </Styled.TalentsAreaItem>
            </Tooltip>
        );
    }

    const renderDistanceValueBlue = () => {
        switch (blue) {
            case 0:
                return `6%`;
            case 1:
                return `${100 * (1 / 7)}%`;
            case 2:
                return `${100 * (2 / 7)}%`;
            case 3:
                return `${100 * (3 / 7)}%`;
            case 4:
                return `${100 * (4 / 7)}%`;
            case 5:
                return `${100 * (5 / 7)}%`;
            case 6:
                return `${100 * (6 / 7)}%`;
            case 7:
                return `${100 * (7 / 7)}%`;
            default:
                return "0%";
        }
    };

    const renderDistanceValueRed = () => {
        switch (red) {
            case 0:
                return `4%`;
            case 1:
                return `${100 * (1 / 7)}%`;
            case 2:
                return `${100 * (2 / 7) - 2}%`;
            case 3:
                return `${100 * (3 / 7) - 2}%`;
            case 4:
                return `${100 * (4 / 7) - 2}%`;
            case 5:
                return `${100 * (5 / 7) - 2}%`;
            case 6:
                return `${100 * (6 / 7) - 2}%`;
            case 7:
                return `${100 * (7 / 7) - 2}%`;
            default:
                return "0%";
        }
    };

    return (
        <>
            <Styled.TalentsAreaItem className={`${classNamePdf} pdfTemplate`}>
                <Styled.Number>{number}</Styled.Number>
                <Styled.Name>{name}</Styled.Name>
                <Styled.ItemList>
                    <div style={{ marginTop: -12 }}>
                        <span>{getTextLevel(blue + 1)}</span>
                    </div>
                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            marginTop: 7,
                        }}
                    >
                        <div
                            style={{
                                background: "#EFEDF3",
                                width: "100%",
                                position: "absolute",
                                height: 8,
                                top: -11,
                                borderRadius: 20,
                            }}
                        >
                            <Tooltip
                                overlayClassName="ant-tooltip-custumer"
                                placement="top"
                                title={`Exigência do meio: ${getTextLevel(red + 1)}`}
                            >
                                <div
                                    id="red"
                                    style={{
                                        background: "#DA222B",
                                        position: "absolute",
                                        width: 12,
                                        height: 12,
                                        zIndex: 6,
                                        top: -2,
                                        border: "1px solid #fff",
                                        borderRadius: 4,
                                        left: renderDistanceValueRed(),
                                    }}
                                />
                            </Tooltip>
                        </div>
                        <div
                            style={{
                                background: "#5F4A87",
                                width: renderDistanceValueBlue(),
                                position: "absolute",
                                height: 8,
                                top: -11,
                                borderRadius: 20,
                            }}
                        ></div>
                    </div>
                </Styled.ItemList>
            </Styled.TalentsAreaItem>
            {textIndicador && textIndicador.texto !== "" && (
                <Styled.Text>{textIndicador.texto}</Styled.Text>
            )}
        </>
    );
};

export default TalentsArea;
