import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 235px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  ${({ first }) => (first ? `margin-top: 20px;` : `margin-top: 0px;`)}
  justify-content: space-between;
`;

export const InfoTextContainer = styled.div`
  flex: 2;
  padding-right: 20px;
  align-self: center;
  text-align: right;
`;

export const InfoText = styled.span`
  color: #5f4a87;

  @media screen and (max-width: 540px) {
    font-size: 12px;
  }
`;

export const ContentChart = styled.div`
  flex: 9;
  ${(props) =>
    `grid-template-columns: ${props.dominante} ${props.informal} ${props.condescendente} ${props.formal}`}
  align-self: center;
`;

export const Chart = styled.div`
  ${(props) =>
    `grid-template-columns: ${props.dominante} ${props.informal} ${props.condescendente} ${props.formal}`}
  display: grid;
  width: 100%;
  flex-direction: row;
  max-width: 700px;
  z-index: 1px;
`;

export const Item = styled.div`
  ${({ dominante, informal, condescendente }) =>
    dominante
      ? `background: #BFBAC8`
      : informal
      ? `background: #601e82`
      : condescendente
      ? `background: #F4F0EB; border-top: 1.5px solid #5F4A87; border-bottom: 1.5px solid #5F4A87`
      : `background: #5F4A87`}
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 1;

  ${({ first }) =>
    first
      ? `border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;`
      : `border-left-top-radius: 0; border-top-bottom-radius: 0;`}
  ${({ last }) =>
    last === true
      ? `border-top-right-radius: 25px;border-bottom-right-radius: 25px; `
      : `border-top-right-radius: 0;border-bottom-right-radius: 0px;`}
`;

export const ItemText = styled.span`
  color: #fff;
  font-size: 22px;
  padding-right: 10px;
`;

export const ContentLegend = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const ContentBlank = styled.div`
  flex: 2;
  padding-right: 20px;
  align-self: center;
  text-align: right;
`;

export const ContentLegendInfo = styled.div`
  flex: 9;
`;

export const ContentLegendInfoMain = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  width: 100%;
  height: 20px;
  position: relative;
  max-width: 700px;
  z-index: 2px;
  bottom: 190px;
  align-items: center;
`;

export const Info = styled.div`
  height: 210px;
  width: 100%;
  ${({ last }) =>
    last ? `border-right: 0;` : `border-right: 1px solid #efedf333;`}
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

export const ItemInfo = styled.span`
  ${({ dominante, informal, condescendente }) =>
    dominante
      ? `color: #5F4A87`
      : informal
      ? `color: #5F4A87`
      : condescendente
      ? `color: #5F4A87;`
      : `color: #5F4A87`}
  font-size: 16px;
  position: relative;
  @media screen and (max-width: 720px) {
    font-size: 14px;
  }

  @media screen and (max-width: 540px) {
    font-size: 12px;
  }
`;

export const BallItem = styled.div`
  position: absolute;
  left: 50%;
  background: #fff;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  transform: translate(-50%);
  box-shadow: 0px 1px 0 0px #5f4a87;
`;
