import React from "react";
import * as Styled from "./styles";
import { ReactComponent as Up } from "../../../../assets/svg/upCard.svg";
import { ReactComponent as Down } from "../../../../assets/svg/downCard.svg";
import { ReactComponent as Equals } from "../../../../assets/svg/equalsCard.svg";
import CircleProfiler from "../../../circleProfile";
import { getInitials } from "../../../../utils/functions/getInitials";
import { backgroundColorByProfiler } from "../../../../utils/functions/backgroundColorByProfiler";
// import { Container } from './styles';

export default function MatchProfiler({
    primary = {
        name: "solides",
        img:
            "https://avatars.slack-edge.com/2019-10-23/808278080631_36f0e314dd957949aa50_72.jpg",
        profiler: "PA",
        executor: 25,
        comunicador: 25,
        planejador: 25,
        analista: 25,
    },
    secondary = {
        solides: "solides",
        img:
            "https://avatars.slack-edge.com/2019-10-23/808278080631_36f0e314dd957949aa50_72.jpg",
        profiler: "AC",
        executor: 25,
        comunicador: 25,
        planejador: 25,
        analista: 25,
    },
    ...rest
}) {
    const Chart = ({
        executor = 25,
        comunicador = 25,
        planejador = 25,
        analista = 25,
    }) => {
        const exec = executor;
        const plane = planejador;
        const comuni = comunicador;
        const anali = analista;

        return (
            <Styled.Chart
                executor={executor}
                planejador={planejador}
                comunicador={comunicador}
                analista={analista}
            >
                <Styled.ItemExecutor>
                    <Styled.ItemText>
                        {plane > 5 && anali > 5 && comuni > 5 && exec > 5
                            ? `${parseInt(executor).toFixed()}%`
                            : ""}
                    </Styled.ItemText>
                </Styled.ItemExecutor>
                <Styled.ItemComunicador>
                    <Styled.ItemText>
                        {plane > 5 && anali > 5 && comuni > 5 && exec > 5
                            ? `${parseInt(comunicador).toFixed()}%`
                            : ""}
                    </Styled.ItemText>
                </Styled.ItemComunicador>
                <Styled.ItemPlanejador>
                    <Styled.ItemText>
                        {plane > 5 && anali > 5 && comuni > 5 && exec > 5
                            ? `${parseInt(planejador).toFixed()}%`
                            : ""}
                    </Styled.ItemText>
                </Styled.ItemPlanejador>
                <Styled.ItemAnalista>
                    <Styled.ItemText>
                        {plane > 5 && anali > 5 && comuni > 5 && exec > 5
                            ? `${parseInt(analista).toFixed()}%`
                            : ""}
                    </Styled.ItemText>
                </Styled.ItemAnalista>
            </Styled.Chart>
        );
    };

    const ChartInfo = ({
        executor = 25,
        planejador = 25,
        comunicador = 25,
        analista = 25,
        second = false,
        dominante = "",
        plus = { executor: 2, comunicador: 2, planejador: -1, analista: 0 },
    }) => {
        const exec = executor;
        const plane = planejador;
        const comuni = comunicador;
        const anali = analista;
        return (
            <Styled.ProfilerBarInfo>
                <Styled.Info>
                    {dominante.includes("E") && (
                        <Styled.Dominante>DOMINANTE</Styled.Dominante>
                    )}
                    {second && (
                        <Styled.Executor dominante={exec >= 25}>
                            Executor
                            {exec <= 5 ||
                            plane <= 5 ||
                            comuni <= 5 ||
                            anali <= 5
                                ? ` ${parseFloat(exec).toFixed()}%`
                                : ""}
                            {plus &&
                                plus.executor !== undefined &&
                                (plus.executor > 0 ? (
                                    <>
                                        <Up />
                                        <span>
                                            {plus.executor
                                                .toFixed(2)
                                                .toString()}
                                            %
                                        </span>
                                    </>
                                ) : plus.executor < 0 ? (
                                    <>
                                        <Down />
                                        <span>
                                            {plus.executor
                                                .toFixed(2)
                                                .toString()
                                                .substr(
                                                    1,
                                                    plus.executor.toString()
                                                        .length
                                                )}
                                            %
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <Equals />
                                        <span>{plus.executor.toFixed(2)}%</span>
                                    </>
                                ))}
                        </Styled.Executor>
                    )}
                </Styled.Info>

                <Styled.Info>
                    {dominante.includes("C") && (
                        <Styled.Dominante>DOMINANTE</Styled.Dominante>
                    )}
                    {second && (
                        <Styled.Comunicador dominante={comuni >= 25}>
                            Comunicador{" "}
                            {exec <= 5 ||
                            plane <= 5 ||
                            comuni <= 5 ||
                            anali <= 5
                                ? ` ${parseFloat(comuni).toFixed()}%`
                                : ""}
                            {plus &&
                                plus.comunicador !== undefined &&
                                (plus.comunicador > 0 ? (
                                    <>
                                        <Up />
                                        <span>
                                            {plus.comunicador
                                                .toFixed(2)
                                                .toString()}
                                            %
                                        </span>
                                    </>
                                ) : plus.comunicador < 0 ? (
                                    <>
                                        <Down />
                                        <span>
                                            {plus.comunicador
                                                .toFixed(2)
                                                .toString()
                                                .substr(
                                                    1,
                                                    plus.comunicador.toString()
                                                        .length
                                                )}
                                            %
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <Equals />
                                        <span>{plus.comunicador}%</span>
                                    </>
                                ))}
                        </Styled.Comunicador>
                    )}
                </Styled.Info>
                <Styled.Info>
                    {dominante.includes("P") && (
                        <Styled.Dominante>DOMINANTE</Styled.Dominante>
                    )}
                    {second && (
                        <Styled.Planejador dominante={plane >= 25}>
                            Planejador{" "}
                            {exec <= 5 ||
                            plane <= 5 ||
                            comuni <= 5 ||
                            anali <= 5
                                ? ` ${parseFloat(plane).toFixed()}%`
                                : ""}
                            {plus &&
                                plus.planejador !== undefined &&
                                (plus.planejador > 0 ? (
                                    <>
                                        <Up />
                                        <span>
                                            {plus.planejador
                                                .toFixed(2)
                                                .toString()}
                                            %
                                        </span>
                                    </>
                                ) : plus.planejador < 0 ? (
                                    <>
                                        <Down />
                                        <span>
                                            {plus.planejador
                                                .toFixed(2)
                                                .toString()
                                                .substr(
                                                    1,
                                                    plus.planejador.toString()
                                                        .length
                                                )}
                                            %
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <Equals />
                                        <span>{plus.planejador}%</span>
                                    </>
                                ))}
                        </Styled.Planejador>
                    )}
                </Styled.Info>
                <Styled.Info last>
                    {dominante.includes("A") && (
                        <Styled.Dominante>DOMINANTE</Styled.Dominante>
                    )}
                    {second && (
                        <Styled.Analista dominante={anali >= 25}>
                            Analista{" "}
                            {exec <= 5 ||
                            plane <= 5 ||
                            comuni <= 5 ||
                            anali <= 5
                                ? `${parseFloat(anali).toFixed()}%`
                                : ""}
                            {plus &&
                                plus.analista !== undefined &&
                                (plus.analista > 0 ? (
                                    <>
                                        <Up />
                                        <span>
                                            {plus.analista
                                                .toFixed(2)
                                                .toString()}
                                            %
                                        </span>
                                    </>
                                ) : plus.analista < 0 ? (
                                    <>
                                        <Down />
                                        <span>
                                            {plus.analista
                                                .toFixed(2)
                                                .toString()
                                                .substr(
                                                    1,
                                                    plus.analista.toString()
                                                        .length
                                                )}
                                            %
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <Equals />
                                        <span>{plus.analista}%</span>
                                    </>
                                ))}
                        </Styled.Analista>
                    )}
                </Styled.Info>
            </Styled.ProfilerBarInfo>
        );
    };

    const execPrimary = primary.executor;
    const comuPrimary = primary.comunicador;
    const planePrimary = primary.planejador;
    const analistPrimary = primary.analista;

    const execSecondary = secondary.executor;
    const comuSecondary = secondary.comunicador;
    const planeSecondary = secondary.planejador;
    const analistSecondary = secondary.analista;

    return (
        <Styled.MatchProfilerContent {...rest}>
            <Styled.MatchFirst>
                <Styled.ContentPhoto>
                    {primary.img ? (
                        <Styled.MatchImage data={primary.img} />
                    ) : (
                        <Styled.MatchImage
                            backgroundColor={backgroundColorByProfiler(
                                primary.profiler
                            )}
                        >
                            <Styled.TextImagePerson>
                                {getInitials(`${primary.name}`)}
                            </Styled.TextImagePerson>
                        </Styled.MatchImage>
                    )}
                    <CircleProfiler type={primary.profiler} small />
                </Styled.ContentPhoto>
                <Styled.ContentChart>
                    <ChartInfo
                        executor={primary.executor}
                        planejador={primary.planejador}
                        comunicador={primary.comunicador}
                        analista={primary.analista}
                        dominante={primary.profiler}
                    />
                    <Chart
                        executor={primary.executor}
                        planejador={primary.planejador}
                        comunicador={primary.comunicador}
                        analista={primary.analista}
                    />
                </Styled.ContentChart>
            </Styled.MatchFirst>
            <Styled.MatchFirst>
                <Styled.ContentPhoto>
                    {secondary.img ? (
                        <Styled.MatchImage data={secondary.img} />
                    ) : (
                        <Styled.MatchImage
                            backgroundColor={backgroundColorByProfiler(
                                secondary.profiler
                            )}
                        >
                            <Styled.TextImagePerson>
                                {getInitials(secondary.name)}
                            </Styled.TextImagePerson>
                        </Styled.MatchImage>
                    )}
                    <CircleProfiler type={secondary.profiler} small />
                </Styled.ContentPhoto>
                <Styled.ContentChart>
                    <ChartInfo
                        executor={secondary.executor}
                        planejador={secondary.planejador}
                        comunicador={secondary.comunicador}
                        analista={secondary.analista}
                        dominante={secondary.profiler}
                        second={true}
                        plus={{
                            executor: execPrimary - execSecondary,
                            comunicador: comuPrimary - comuSecondary,
                            planejador: planePrimary - planeSecondary,
                            analista: analistPrimary - analistSecondary,
                        }}
                    />
                    <Chart
                        executor={secondary.executor}
                        planejador={secondary.planejador}
                        comunicador={secondary.comunicador}
                        analista={secondary.analista}
                    />
                </Styled.ContentChart>
            </Styled.MatchFirst>
        </Styled.MatchProfilerContent>
    );
}
