import React from "react";
import * as Styled from "./styles";
import { Tooltip } from "antd";

const MediumRequirement = ({
    voce = {
        executor: "30%",
        planejador: "30%",
        comunicador: "10%",
        analista: "20%",
    },
    meio = {
        executor: "40%",
        planejador: "10%",
        comunicador: "20%",
        analista: "30%",
    },
    flexibilidade = {
        executor: "25%",
        planejador: "25%",
        comunicador: "25%",
        analista: "25%",
    },
    isTooltip = false,
    name,
}) => {
    const tooltipTitle = (title, tooltip = "") => {
        if (isTooltip) {
            return (
                <>
                    <Tooltip
                        overlayClassName="ant-tooltip-custumer"
                        placement="left"
                        title={tooltip}
                    >
                        <Styled.InfoText>{title}</Styled.InfoText>
                    </Tooltip>
                </>
            );
        }
        return <Styled.InfoText>{title}</Styled.InfoText>;
    };

    const wordUpper = palavra => {
        const firstName = palavra
            .substring(0, 1)
            .toUpperCase()
            .concat(palavra.substring(1))
            .split(" ")[0];
        return firstName;
    };

    return (
        <Styled.Container>
            <Styled.Content first>
                <Styled.InfoTextContainer>
                    {tooltipTitle(
                        name ? wordUpper(name) : "Você",
                        `Como você se comporta`
                    )}
                </Styled.InfoTextContainer>
                <Styled.ContentChart
                    executor={voce.executor === "0%" ? "5%" : voce.executor}
                    planejador={
                        voce.planejador === "0%" ? "5%" : voce.planejador
                    }
                    comunicador={
                        voce.comunicador === "0%" ? "5%" : voce.comunicador
                    }
                    analista={voce.analista === "0%" ? "5%" : voce.analista}
                >
                    <Styled.Chart
                        executor={voce.executor === "0%" ? "5%" : voce.executor}
                        planejador={
                            voce.planejador === "0%" ? "5%" : voce.planejador
                        }
                        comunicador={
                            voce.comunicador === "0%" ? "5%" : voce.comunicador
                        }
                        analista={voce.analista === "0%" ? "5%" : voce.analista}
                    >
                        {isTooltip ? (
                            <Tooltip
                                overlayClassName="ant-tooltip-custumer"
                                placement="top"
                                title={`Executor ${parseFloat(
                                    voce.executor
                                ).toFixed(2)} %`}
                            >
                                <Styled.Item executor first>
                                    {parseFloat(voce.executor) > 25 && (
                                        <Styled.BallItem />
                                    )}
                                </Styled.Item>
                            </Tooltip>
                        ) : (
                            <Styled.Item executor first>
                                {parseFloat(voce.executor) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        )}
                        {isTooltip ? (
                            <Tooltip
                                overlayClassName="ant-tooltip-custumer"
                                placement="top"
                                title={`Comunicador ${parseFloat(
                                    voce.comunicador
                                ).toFixed(2)} %`}
                            >
                                <Styled.Item comunicador>
                                    {parseFloat(voce.comunicador) > 25 && (
                                        <Styled.BallItem />
                                    )}
                                </Styled.Item>
                            </Tooltip>
                        ) : (
                            <Styled.Item comunicador>
                                {parseFloat(voce.comunicador) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        )}
                        {isTooltip ? (
                            <Tooltip
                                overlayClassName="ant-tooltip-custumer"
                                placement="top"
                                title={`Planejador ${parseFloat(
                                    voce.planejador
                                ).toFixed(2)} %`}
                            >
                                <Styled.Item planejador>
                                    {parseFloat(voce.planejador) > 25 && (
                                        <Styled.BallItem />
                                    )}
                                </Styled.Item>
                            </Tooltip>
                        ) : (
                            <Styled.Item planejador>
                                {parseFloat(voce.planejador) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        )}
                        {isTooltip ? (
                            <Tooltip
                                overlayClassName="ant-tooltip-custumer"
                                placement="top"
                                title={`Analista ${parseFloat(
                                    voce.analista
                                ).toFixed(2)} %`}
                            >
                                <Styled.Item analista last>
                                    {parseFloat(voce.analista) > 25 && (
                                        <Styled.BallItem />
                                    )}
                                </Styled.Item>
                            </Tooltip>
                        ) : (
                            <Styled.Item analista>
                                {parseFloat(voce.analista) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        )}
                    </Styled.Chart>
                </Styled.ContentChart>
            </Styled.Content>
            <Styled.Content>
                <Styled.InfoTextContainer>
                    {tooltipTitle("Meio", "Como o meio exige de você")}
                </Styled.InfoTextContainer>
                <Styled.ContentChart
                    executor={meio.executor === "0%" ? "5%" : meio.executor}
                    planejador={
                        meio.planejador === "0%" ? "5%" : meio.planejador
                    }
                    comunicador={
                        meio.comunicador === "0%" ? "5%" : meio.comunicador
                    }
                    analista={meio.analista === "0%" ? "5%" : meio.analista}
                >
                    <Styled.Chart
                        executor={meio.executor === "0%" ? "5%" : meio.executor}
                        planejador={
                            meio.planejador === "0%" ? "5%" : meio.planejador
                        }
                        comunicador={
                            meio.comunicador === "0%" ? "5%" : meio.comunicador
                        }
                        analista={meio.analista === "0%" ? "5%" : meio.analista}
                    >
                        {isTooltip ? (
                            <Tooltip
                                overlayClassName="ant-tooltip-custumer"
                                placement="top"
                                title={`Executor ${parseFloat(
                                    meio.executor
                                ).toFixed(2)} %`}
                            >
                                <Styled.Item executor first>
                                    {parseFloat(meio.executor) > 25 && (
                                        <Styled.BallItem />
                                    )}
                                </Styled.Item>
                            </Tooltip>
                        ) : (
                            <Styled.Item executor first>
                                {parseFloat(meio.executor) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        )}
                        {isTooltip ? (
                            <Tooltip
                                overlayClassName="ant-tooltip-custumer"
                                placement="top"
                                title={`Comunicador ${parseFloat(
                                    meio.comunicador
                                ).toFixed(2)} %`}
                            >
                                <Styled.Item comunicador>
                                    {parseFloat(meio.comunicador) > 25 && (
                                        <Styled.BallItem />
                                    )}
                                </Styled.Item>
                            </Tooltip>
                        ) : (
                            <Styled.Item comunicador>
                                {parseFloat(meio.comunicador) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        )}
                        {isTooltip ? (
                            <Tooltip
                                overlayClassName="ant-tooltip-custumer"
                                placement="top"
                                title={`Planejador ${parseFloat(
                                    meio.planejador
                                ).toFixed(2)} %`}
                            >
                                <Styled.Item planejador>
                                    {parseFloat(meio.planejador) > 25 && (
                                        <Styled.BallItem />
                                    )}
                                </Styled.Item>
                            </Tooltip>
                        ) : (
                            <Styled.Item planejador>
                                {parseFloat(meio.planejador) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        )}
                        {isTooltip ? (
                            <Tooltip
                                overlayClassName="ant-tooltip-custumer"
                                placement="top"
                                title={`Analista ${parseFloat(
                                    meio.analista
                                ).toFixed(2)} %`}
                            >
                                <Styled.Item analista last>
                                    {parseFloat(meio.analista) > 25 && (
                                        <Styled.BallItem />
                                    )}
                                </Styled.Item>
                            </Tooltip>
                        ) : (
                            <Styled.Item analista>
                                {parseFloat(meio.analista) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        )}
                    </Styled.Chart>
                </Styled.ContentChart>
            </Styled.Content>
            <Styled.Content>
                <Styled.InfoTextContainer>
                    {tooltipTitle(
                        "Adaptabilidade",
                        "Como você se adapta ao meio"
                    )}
                </Styled.InfoTextContainer>
                <Styled.ContentChart
                    executor={
                        flexibilidade.executor === "0%"
                            ? "5%"
                            : flexibilidade.executor
                    }
                    planejador={
                        flexibilidade.planejador === "0%"
                            ? "5%"
                            : flexibilidade.planejador
                    }
                    comunicador={
                        flexibilidade.comunicador === "0%"
                            ? "5%"
                            : flexibilidade.comunicador
                    }
                    analista={
                        flexibilidade.analista === "0%"
                            ? "5%"
                            : flexibilidade.analista
                    }
                >
                    <Styled.Line />
                    <Styled.Chart
                        executor={
                            flexibilidade.executor === "0%"
                                ? "5%"
                                : flexibilidade.executor
                        }
                        planejador={
                            flexibilidade.planejador === "0%"
                                ? "5%"
                                : flexibilidade.planejador
                        }
                        comunicador={
                            flexibilidade.comunicador === "0%"
                                ? "5%"
                                : flexibilidade.comunicador
                        }
                        analista={
                            flexibilidade.analista === "0%"
                                ? "5%"
                                : flexibilidade.analista
                        }
                    >
                        {isTooltip ? (
                            <Tooltip
                                overlayClassName="ant-tooltip-custumer"
                                placement="top"
                                title={`Executor ${parseFloat(
                                    flexibilidade.executor
                                ).toFixed(2)} %`}
                            >
                                <Styled.Item executor first>
                                    {parseFloat(flexibilidade.executor) >
                                        25 && <Styled.BallItem />}
                                </Styled.Item>
                            </Tooltip>
                        ) : (
                            <Styled.Item executor first>
                                {parseFloat(flexibilidade.executor) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        )}
                        {isTooltip ? (
                            <Tooltip
                                overlayClassName="ant-tooltip-custumer"
                                placement="top"
                                title={`Comunicador ${parseFloat(
                                    flexibilidade.comunicador
                                ).toFixed(2)} %`}
                            >
                                <Styled.Item comunicador>
                                    {parseFloat(flexibilidade.comunicador) >
                                        25 && <Styled.BallItem />}
                                </Styled.Item>
                            </Tooltip>
                        ) : (
                            <Styled.Item comunicador>
                                {parseFloat(flexibilidade.comunicador) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        )}
                        {isTooltip ? (
                            <Tooltip
                                overlayClassName="ant-tooltip-custumer"
                                placement="top"
                                title={`Planejador ${parseFloat(
                                    flexibilidade.planejador
                                ).toFixed(2)} %`}
                            >
                                <Styled.Item planejador>
                                    {parseFloat(flexibilidade.planejador) >
                                        25 && <Styled.BallItem />}
                                </Styled.Item>
                            </Tooltip>
                        ) : (
                            <Styled.Item planejador>
                                {parseFloat(flexibilidade.planejador) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        )}
                        {isTooltip ? (
                            <Tooltip
                                overlayClassName="ant-tooltip-custumer"
                                placement="top"
                                title={`Analista ${parseFloat(
                                    flexibilidade.analista
                                ).toFixed(2)} %`}
                            >
                                <Styled.Item analista last>
                                    {parseFloat(flexibilidade.analista) >
                                        25 && <Styled.BallItem />}
                                </Styled.Item>
                            </Tooltip>
                        ) : (
                            <Styled.Item analista>
                                {parseFloat(flexibilidade.analista) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        )}
                    </Styled.Chart>
                </Styled.ContentChart>
            </Styled.Content>

            <Styled.ContentLegend>
                <Styled.ContentBlank />
                <Styled.ContentLegendInfo>
                    <Styled.ContentLegendInfoMain>
                        <Styled.Info>
                            <Styled.ItemInfo>{`${" "}`}</Styled.ItemInfo>
                            <Styled.ItemInfo executor>Executor</Styled.ItemInfo>
                        </Styled.Info>
                        <Styled.Info>
                            <Styled.ItemInfo>{`${" "}`}</Styled.ItemInfo>
                            <Styled.ItemInfo comunicador>
                                Comunicador
                            </Styled.ItemInfo>
                        </Styled.Info>
                        <Styled.Info>
                            <Styled.ItemInfo>{`${" "}`}</Styled.ItemInfo>
                            <Styled.ItemInfo planejador>
                                Planejador
                            </Styled.ItemInfo>
                        </Styled.Info>
                        <Styled.Info last>
                            <Styled.ItemInfo>{`${" "}`}</Styled.ItemInfo>
                            <Styled.ItemInfo analista>Analista</Styled.ItemInfo>
                        </Styled.Info>
                    </Styled.ContentLegendInfoMain>
                </Styled.ContentLegendInfo>
            </Styled.ContentLegend>
        </Styled.Container>
    );
};

export default MediumRequirement;
