const INITIAL_STATE = {
    loading: false,
    modal: {
        show: false,
        type: "",
        data: [],
        sizeMode: "fullscreen",
    },
    alert: {
        show: false,
        type: "",
        data: [],
        sizeMode: "fullscreen",
    },
    menu: {
        expanded: true,
        selected: "peopleAnalytics",
    },
    help: {
        type: "",
        show: false,
        visible: false,
    },
    dataCep: {
        cep: "",
        logradouro: "",
        complemento: "",
        bairro: "",
        localidade: "",
        uf: "",
        unidade: "",
        ibge: "",
        gia: "",
        latitude: "",
        longitude: "",
    },
    countries: [],
    states: [],
    cities: [],
};

export default function auth(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "@utilities/CHANGE_MENU_SELECTED":
            return {
                ...state,
                menu: { ...state.menu, selected: action.payload.selected },
            };
        case "@utilities/CHANGE_MENU_EXPANDED":
            return {
                ...state,
                menu: { ...state.menu, expanded: action.payload.expanded },
            };
        case "@utilities/LOADING":
            return {
                ...state,
                loading: action.payload.loading,
            };
        case "@utilities/SHOW_MODAL":
            return {
                ...state,
                modal: {
                    show: action.payload.show,
                    type: action.payload.type,
                    data: action.payload.data,
                    sizeMode: action.payload.sizeMode,
                },
            };
        case "@utilities/SHOW_ALERT":
            return {
                ...state,
                alert: {
                    show: action.payload.show,
                    type: action.payload.type,
                    data: action.payload.data,
                    sizeMode: action.payload.sizeMode,
                },
            };
        case "@utilities/SHOW_HELPERS_SUCCESS":
            return { ...state, help: { ...action.payload } };
        case "@auth/LOGOUT":
            return INITIAL_STATE;

        case "@utilities/CEP_SUCCESS":
            return {
                ...state,
                dataCep: action.payload,
            };

        case "@utilities/COUNTRIES_SUCCESS":
            return {
                ...state,
                countries: action.payload,
            };

        case "@utilities/STATES_SUCCESS":
            return {
                ...state,
                states: action.payload,
            };

        case "@utilities/CITIES_SUCCESS":
            return {
                ...state,
                cities: action.payload,
            };

        default:
            return state;
    }
}
