const INITIAL_STATE = {
  data: {},
  person: {},
  profiler: {},
  profilerAnswered: false,
  invalidLink: false,
  loading: true,
  error: false,
};

export default function reducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@profiler/SET_PROFILER_SUCCESS":
      return {
        ...state,
        profiler: action.payload.data,
        loading: false,
      };

    case "@profiler/SET_PROFILER_ANSWERED_SUCCESS":
      return {
        ...state,
        profilerAnswered: action.payload,
        loading: false,
      };

    case "@profiler/SET_INVALID_LINK_SUCCESS":
      return {
        ...state,
        invalidLink: action.payload,
      };

    case "@profiler/SET_ERROR":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}
