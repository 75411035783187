import React from "react";
import { ReactComponent as Up } from "../../../../../assets/svg/upCard.svg";
import { ReactComponent as Down } from "../../../../../assets/svg/downCard.svg";
import { ReactComponent as Equals } from "../../../../../assets/svg/equalsCard.svg";
import { Tooltip } from "antd";
import * as Styled from "./styles";
import getTextLevel from "../../../../../utils/functions/getTextLevel";

const Item = ({
    index,
    type,
    status,
    plus,
    tooltipColumnRight,
    isTooltip,
    isFemin = false,
    textIndicador,
    classNamePdf,
}) => {
    const renderType = () => {
        switch (type) {
            case "tolerancia":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "tolerancia"
                                )[0].render
                            }
                        >
                            Tolerância
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "planejamento":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "planejamento"
                                )[0].render
                            }
                        >
                            Planejamento
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "empatia":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "empatia"
                                )[0].render
                            }
                        >
                            Empatia
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "capac_ouvir":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "capac_ouvir"
                                )[0].render
                            }
                        >
                            Capacidade de ouvir
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "concentracao":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "concentracao"
                                )[0].render
                            }
                        >
                            Concentração
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "condescendencia":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "condescendencia"
                                )[0].render
                            }
                        >
                            Condescendência
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "perfil_tecnico":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "perfil_tecnico"
                                )[0].render
                            }
                        >
                            Perfil Técnico
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;
            case "organizacao":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "organizacao"
                                )[0].render
                            }
                        >
                            Organização
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "detalhismo":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "detalhismo"
                                )[0].render
                            }
                        >
                            Detalhismo
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "rigorosidade":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "rigorosidade"
                                )[0].render
                            }
                        >
                            Rigorosidade
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "orientadorsultado":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "orientadorsultado"
                                )[0].render
                            }
                        >
                            Orientado por resultado
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "multitarefas":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "multitarefas"
                                )[0].render
                            }
                        >
                            Multitarefas
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "automotivacao":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "automotivacao"
                                )[0].render
                            }
                        >
                            Automotivação
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "proatividade":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "proatividade"
                                )[0].render
                            }
                        >
                            Proatividade
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "dinamismo":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "dinamismo"
                                )[0].render
                            }
                        >
                            Dinamismo
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "dominancia":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "dominancia"
                                )[0].render
                            }
                        >
                            Dominância
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "extroversao":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "extroversao"
                                )[0].render
                            }
                        >
                            Extroversão
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "relac_interpes":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "relac_interpes"
                                )[0].render
                            }
                        >
                            Relacionamento interpessoal
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "sociabilidade":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "sociabilidade"
                                )[0].render
                            }
                        >
                            Sociabilidade
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;

            case "orientadorelacionamento":
                if (isTooltip) {
                    return (
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={
                                tooltipColumnRight.filter(
                                    e => e.type === "orientadorelacionamento"
                                )[0].render
                            }
                        >
                            Orientado por relacionamento
                        </Tooltip>
                    );
                }
                return textIndicador.titulo;
            default:
                break;
        }
    };

    return (
        <>
            <div className={classNamePdf}>
                <Styled.Item className="competenceItem">
                    <Styled.ItemIndex>
                        <Styled.ItemIndexText>{index + 1}</Styled.ItemIndexText>
                        <Styled.ItemTypeText>
                            {renderType()}
                        </Styled.ItemTypeText>
                    </Styled.ItemIndex>
                    {plus !== undefined && (
                        <Styled.ItemPlus>
                            {plus > 0 ? (
                                <Up />
                            ) : plus < 0 ? (
                                <Down />
                            ) : (
                                <Equals />
                            )}
                        </Styled.ItemPlus>
                    )}
                    <Styled.ValueBars>
                        <Styled.StatusBar widthValues={status} />
                        <Styled.ItemStatusText>
                            {getTextLevel(status)}
                        </Styled.ItemStatusText>
                    </Styled.ValueBars>
                </Styled.Item>
                {textIndicador && textIndicador.texto !== "" && (
                    <Styled.Text>{textIndicador.texto}</Styled.Text>
                )}
            </div>
        </>
    );
};

export default Item;
