import React from "react";
import * as Styled from "./styled";
import { getInitials } from "../../../../utils/functions/getInitials";
import { backgroundColorByProfiler } from "../../../../utils/functions/backgroundColorByProfiler";

export const Matriz = ({
    locationOne,
    locationTwo,
    pictureOne,
    pictureTwo,
    nameUserPrimary,
    nameUserSecondary,
    name,
    primaryProfiler,
    secondaryProfiler,
}) => {
    const positionPicture = currentLocation => {
        if (locationOne === 1 && locationTwo === 1) {
            return 1.5;
        } else if (locationOne === 2 && locationTwo === 2) {
            return 2.5;
        } else if (locationOne === 3 && locationTwo === 3) {
            return 3.5;
        } else if (locationOne === 4 && locationTwo === 4) {
            return 4.5;
        } else if (locationOne === 5 && locationTwo === 5) {
            return 5.5;
        } else if (locationOne === 6 && locationTwo === 6) {
            return 6.5;
        } else if (locationOne === 7 && locationTwo === 7) {
            return 7.5;
        } else {
            return currentLocation;
        }
    };
    return (
        <Styled.MainBox>
            <Styled.ContainerMoods>
                <Styled.Mood>{name}</Styled.Mood>
            </Styled.ContainerMoods>
            <Styled.Container>
                {pictureOne ? (
                    <Styled.FirstPicture
                        data={pictureOne}
                        location={positionPicture(locationOne)}
                    />
                ) : (
                    <Styled.FirstPicture
                        backgroundColor={backgroundColorByProfiler(
                            primaryProfiler
                        )}
                        location={positionPicture(locationOne)}
                    >
                        <Styled.TextImagePerson>
                            {getInitials(nameUserPrimary)}
                        </Styled.TextImagePerson>
                    </Styled.FirstPicture>
                )}
                {pictureTwo ? (
                    <Styled.SecondPicture
                        data={pictureTwo}
                        location={positionPicture(locationTwo)}
                    />
                ) : (
                    <Styled.SecondPicture
                        backgroundColor={backgroundColorByProfiler(
                            secondaryProfiler
                        )}
                        location={positionPicture(locationTwo)}
                    >
                        <Styled.TextImagePerson>
                            {getInitials(nameUserSecondary)}
                        </Styled.TextImagePerson>
                    </Styled.SecondPicture>
                )}
                <Styled.Vertical />
                <Styled.Horizontal />
                <Styled.Vertical />
                <Styled.Horizontal />
                <Styled.Vertical />
                <Styled.Horizontal />
                <Styled.Vertical />
                <Styled.Horizontal />
                <Styled.Vertical />
                <Styled.Horizontal />
                <Styled.Vertical />
                <Styled.Horizontal />
                <Styled.Vertical />
            </Styled.Container>
        </Styled.MainBox>
    );
};

export const TitleMatriz = () => {
    return (
        <Styled.BoxTitle>
            <Styled.TitleEmpty />
            <Styled.ContainerTitle>
                <Styled.SpanLines>MUITO BAIXA</Styled.SpanLines>
                <Styled.SpanLines>BAIXA</Styled.SpanLines>
                <Styled.SpanLines>NORMAL BAIXA</Styled.SpanLines>
                <Styled.SpanLines>NORMAL</Styled.SpanLines>
                <Styled.SpanLines>NORMAL ALTA</Styled.SpanLines>
                <Styled.SpanLines>ALTA</Styled.SpanLines>
                <Styled.SpanLines>MUITO ALTA</Styled.SpanLines>
            </Styled.ContainerTitle>
        </Styled.BoxTitle>
    );
};
