import axios from "axios";

export const getPassportByToken = async (token) => {
  // http://localhost:3000/idClifor=212&idEmp=1&idPass=1115&type=c&locale=pt-BR

  // const url = window.location.href.split("/");
  // const params = url[3];
  // const idClifor = params.split("&")[0].split("=")[1];
  // const idEmp = params.split("&")[1].split("=")[1];
  // const idPass = params.split("&")[2].split("=")[1];
  // const type = params.split("&")[3].split("=")[1];
  // const locale = params.split("&")[4].split("=")[1];

  return new Promise((resolve, reject) => {
    axios
      .post(
        `https://cit.solides.adm.br/service/wl/report`,
        {},
        {
          headers: {
            Token: `${token}`,
          },
        }
      )
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
