import React from "react";
import Item from "./item";
import * as Styled from "./styles";

import Graphic from "./graphic";

const Competences = ({
    data = [
        { type: "tolerancia", value: 20, status: 1 },
        { type: "planejamento", value: 30, status: 2 },
        { type: "empatia", value: 33, status: 3 },
        { type: "capac_ouvir", value: 16, status: 4 },
        { type: "concentracao", value: 31, status: 5 },
        { type: "condescendencia", value: 43, status: 6 },
        { type: "perfil_tecnico", value: 73, status: 7 },
        { type: "organizacao", value: 63, status: 8 },
        { type: "detalhismo", value: 73, status: 1 },
        { type: "rigorosidade", value: 83, status: 1 },
        { type: "orientadorsultado", value: 33, status: 1 },
        { type: "multitarefas", value: 43, status: 1 },
        { type: "automotivacao", value: 53, status: 1 },
        { type: "proatividade", value: 33, status: 1 },
        { type: "dinamismo", value: 43, status: 1 },
        { type: "dominancia", value: 53, status: 1 },
        { type: "extroversao", value: 63, status: 1 },
        { type: "relac_interpes", value: 33, status: 1 },
        { type: "sociabilidade", value: 32, status: 1 },
        { type: "orientadorelacionamento", value: 33, status: 1 },
    ],
    withList = true,
    objectSliders,
    primaryColor,
    primaryName,
    sencodaryName,
    secondaryColor,
    isTooltip = false,
    tooltipColumnRight = [],
    withOutGraphic = true,
}) => {
    return (
        <Styled.CompetencesChart>
            <Styled.Chart>
                {withOutGraphic ? (
                    <Styled.GraphicContainer>
                        <Graphic
                            primaryColor={primaryColor}
                            secondaryColor={secondaryColor}
                            primaryName={primaryName}
                            sencodaryName={sencodaryName}
                            data={data}
                            objectSliders={objectSliders}
                        />
                    </Styled.GraphicContainer>
                ) : null}

                {withList === true && (
                    <Styled.ListContainer>
                        {data.map((e, i) => {
                            if (isTooltip) {
                                return (
                                    <Item
                                        key={i}
                                        type={e.type}
                                        status={e.status}
                                        plus={e.plus}
                                        index={i}
                                        tooltipColumnRight={tooltipColumnRight}
                                        isTooltip={isTooltip}
                                        textIndicador={e.textIndicador}
                                    />
                                );
                            } else {
                                return (
                                    <Item
                                        key={i}
                                        type={e.type}
                                        status={e.status}
                                        plus={e.plus}
                                        index={i}
                                        textIndicador={e.textIndicador}
                                        classNamePdf={e.classNamePdf}
                                    />
                                );
                            }
                        })}
                    </Styled.ListContainer>
                )}
            </Styled.Chart>
        </Styled.CompetencesChart>
    );
};

export default Competences;
