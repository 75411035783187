/* eslint-disable max-len */
export const SpanishStaticTextsProfiler = {
    interacao_ambiente: {
        titulo: `Interacción con el Ambiente`,
        texto: `Este grafico muestra el perfil interno y el cobro del medio externo. El primero punto de cada línea es
        el perfil Ejecutor; el segundo punto de cada línea es el Comunicador; tercero Planeador y cuarto Analista. La
        línea azul indica el perfil interno, como la persona realmente es, en su naturaleza, y como se comporta en
        ambientes familiares. La línea roja indica como el medio externo está cobrando que ella sea. Observe las
        diferencias de cambio de comportamiento exigidas en cada punto, y la fuerza de estas alteraciones requeridas.
        Fuertes alteraciones requieren consumación de energía pero también conduzen al aprendizado. Pocas
        alteraciones indican buena adaptación de la persona a las funciones ejercidas pero también pocos desafíos.`,
    },
    estilio_lideranca: {
        titulo: `Estilo de Liderazgo`,
        texto: `El primero punto es la Dominancia; el segundo la Informalidad; el terceiro Condescendencia y el cuarto
        Formalidad. Observe los puntos que estan arriba de la línea horizontal central para verificar los perfiles
        predominantes. La línea azul es el perfil interno y la línea roja es el cobro del médio externo. Ve la
        intensidad de diferencias entre cada punto entre las líneas azul y roja. Verifica, en los indices, el nivel
        del IEM para ver la clasificación de la intensidad de las alteraciones demandadas, y la Energía para observar
        la fuerza para la persona lidiar con estas alteraciones. Liderazgo Actual indica como la persona actua
        actualmente en relación a su estilo de liderazgo. Verifica el dibujo de la línea con lo que el ambiente externo
        demanda en el grafico anterior para verificar si la persona está entregando el modelo demandado o si necesita
        de ajustes en su estilo o en la propia demanda.`,
    },
    situational_Indicator: {
        energia: {
            titulo: `Energía`,
            texto: `Indica el "pique" para el trabajo, la capacidad de cambiar y la habilidad de absorber el estrés
            más facilmente. En casos de energía baja, muy baja o extremadamente baja, observe el IEM abajo para
            confirmar si las exigências del medio externo están muy grandes, demandando muchas habilidades no
            naturales del perfil.`,
        },
        nivelestress: {
            titulo: `Exigencia del Medio`,
            texto: `Mide cuán fuerte es la exigencia del medio externo. Además del índice, podremos ver en los
            gráficos los cobros del medio externo de manera comparativa. Tenemos nuestro perfil interno y nuestra
            percepción de cómo deberíamos ser para tener un desempeño mejor. Esta segunda parte es el cobro del
            medio externo.`,
        },
        ia_nivel: {
            titulo: `Aprovechamiento`,
            texto: `Indica que la persona se encuentra en un momento en que se percibe que sus habilidades están
            siendo bien aprovechadas en sus actividades. IA bajo significa subaprovechamiento, es una subutilización
            de sus habilidades, puede ser por flujo de trabajo muy lento, exceso de personas en el departamento,
            paralizaciones o interrupciones de trabajo muy frecuentes.`,
        },
        moral_nivel: {
            titulo: `Moral`,
            texto: `Indica el nivel de auto aprobación de la persona en términos de su desempeño profesional e/o
            personal. Moral alto: Significa que la persona tiene una buena auto aprobación profesional, cree que está
            caminando en la dirección correcta de su desarrollo. Moral baja: Significa que la persona siente que
            muchos cambios deben ocurrir para que su desempeño mejore.`,
        },
        nivelip: {
            titulo: `Positividad`,
            texto: `Mide el autoestima del individuo. Indica el nivel de autoaprobación en términos de su desempeño
            principalmente en cuestiones personales. Es la relación entre las características positivas y las
            negativas que el individuo ve en si mismo en cuestión de autovalor o de autocrítica.`,
        },
        flex_nivel: {
            titulo: `Flexibilidad`,
            texto: `Mide cuanto la persona puede cambiar su comportamiento y con qué facilidad puede hacerlo. Además
            de la actitud, muestra con qué facilidad el individuo acepta y absorbe nuevos conceptos y quiebra de
            paradigmas, cuando necesario. IF varia de acuerdo con el tiempo en el cual el cuestionario fué respondido,
            pués además del perfil, envuelve el estado emotivo y profesional para absorción de nuevos conceptos y
            desafíos en aquel momento.`,
        },
        amplitude_nivel: {
            titulo: `Amplitud`,
            texto: `Indica cuán fuerte es la importancia del ambiente de trabajo en la productividad del profesional.
            Una Amplitud alta significa que el ambiente propicio para este perfil aumenta su productividad. Indica
            también cuanto la persona impacta en su ambiente de trabajo. La amplitud alta significa que la persona
            provoca un impacto (positivo y/o negativo) en el grupo dónde está insertado.`,
        },
        nivel_energperfil: {
            titulo: `Automotivación`,
            texto: `Es la energía natural del perfil de la persona. Este indicador traduz la energía del perfil
            comportamental y no por la situación en que la persona se encuentra. Sería como el potencial de energía
            que él puede entregar de acuerdo con su perfil.`,
        },
        nivel_incitab: {
            titulo: `Incitabilidad`,
            texto: `Es el potencial de reacción a estímulos. Es cuanto la persona se queda animada con una nueva idea
            o desafío.`,
        },
    },
    competences: {
        tolerancia: {
            titulo: `Tolerância`,
            texto: `Capacidad para tolerar diferentes maneras de pensar, actuar y sentir, incluso si son contrarias a
            las adoptadas por uno mismo.`,
        },
        planejamento: {
            titulo: `Planificacion`,
            texto: `Capacidad para planificar acciones, procesos y actividades.`,
        },
        empatia: {
            titulo: `Empatía`,
            texto: `Capacidad de entender el sentimiento o reacción de otra persona imaginandose en las mismas
            circunstancias.`,
        },
        capac_ouvir: {
            titulo: `Capacidad de Escuchar`,
            texto: `Capacidad de escucha activa, capacidad de escuchar atentamente.`,
        },
        concentracao: {
            titulo: `Concentración`,
            texto: `Nível de capacidad/necesidad de concentración para ejecución de un trabajo que demanda atención y
            constancia. Esa competencia mensura no solo la capacidad de la persona en concentrarse como también su
            necesidad de un ambiente adecuado para esta actividad.`,
        },
        condescendencia: {
            titulo: `Condescendencia`,
            texto: `Indica cuanto la persona considera y pondera las intenciones, deseos y opinión de los otros,
            actuando con complacencia para buscar la mejor acción posible.`,
        },
        perfil_tecnico: {
            titulo: `Perfil Técnico`,
            texto: `Aptidud para habilidades técnicas indica la capacidad de la persona en "tecnificarse", dar o
            proporcionar recursos técnicos a una actividade para optimizarla.`,
        },
        organizacao: {
            titulo: `Organizacion`,
            texto: `Es la capacidad de un individuo para organizar sus ideas de forma clara y bien definida. Esta
            competencia también se aplica al entorno físico como la capacidad de mantener el entorno limpio y
            ordenado.`,
        },
        detalhismo: {
            titulo: `Detallismo`,
            texto: `Capacidad de exposición minuciosa de factos, planes o proyectos, con atención a detalles.`,
        },
        rigorosidade: {
            titulo: `Rigor`,
            texto: `Competencia de una persona exacta, precisa, exigente y de razonamiento riguroso.`,
        },
        orientadorsultado: {
            titulo: `Orientado a Resultados`,
            texto: `Es la competencia que identifica cuánto se desarrolla la persona a través del trabajo y la acción,
            invirtiendo más tiempo en la realización de las tareas.`,
        },
        multitarefas: {
            titulo: `Multitareas`,
            texto: `Capacidad de ejecutar várias tareas al mismo tiempo.`,
        },
        automotivacao: {
            titulo: `Automotivación`,
            texto: `Indica el nivel de la capacidad de la persona en automotivarse, la capacidad de la persona en ser
            motivada al entusiasmo.`,
        },
        proatividade: {
            titulo: `Proactividad`,
            texto: `Indica la capacidad de actuar con antelación, resolviendo situaciones y problemas futuros sin que
            sea necesariamente necesario.`,
        },
        dinamismo: {
            titulo: `Dinamismo`,
            texto: `Particularidad y característica de quienes demuestran energía, movimiento y vitalidad, se enfrentan
            bien a los cambios y ambientes dinámicos.`,
        },
        dominancia: {
            titulo: `Dominancia`,
            texto: `Competencia de quien ejerce una postura dominante y firme. Normalmente toma decisiones rápidas, ya
            que tiene menos aversión al riesgo y asume una postura dominante.`,
        },
        extroversao: {
            titulo: `Extroversão`,
            texto: `Característica de quien es extrovertido, expansivo, comunicativo y sociable. Una persona
            extrovertida tiene facilidad en socializarse.`,
        },
        relac_interpes: {
            titulo: `Relacionamiento interpersonal`,
            texto: `Capacidad para establecer conexiones o vínculos con otras personas dentro de un contexto
            determinado.`,
        },
        sociabilidade: {
            titulo: `Sociabilidad`,
            texto: `Indica la necesidad y la tendencia a buscar por relacionamiento social con las otras personas, de
            forma expansiva y extrovertida.`,
        },
        orientadorelacionamento: {
            titulo: `Relación Orientada`,
            texto: `El nivel de foco de la persona en relacionamientos. Esta competencia indica cuanto la persona se
            desarrolla por relacionamientos, con atención a las personas y cuanto ella se fija de gastar su tiempo con
            gente.`,
        },
    },
    talentsArea: {
        comandante: {
            titulo: `Comandante`,
            texto: `Personas con el perfil de comandante son emprendedoras, independientes, exigentes con ellas mismas
            y con los otros. Son buenos iniciadores de nuevos negocios por asumir riesgos. Son óptimos resolvedores de
            problemas y en funcciones que exigen un pulso fuerte en comando y liderazgo de equipo altamente
            condescendente. Profesiones: Abogado, Cargos de Comando, Lider, Supervisores, Gerentes de Producción,
            Comandantes, Jefes de Construcción.`,
        },
        competidor: {
            titulo: `Competidor`,
            texto: `Viven de victorias y cada día es una disputa para él, de la cual él tiene que salir victorioso.
            Pueden actuar como Vendedores, Ingenieros o Profesores. Respetan más a las reglas do que los vendedores
            con alto índice de comunicación, pero si especializarse en áreas más técnicas, se sentirán
            intimidados para negociar. Tienen pulso y garra, y les gusta competir. Profesiones: Abogado, Líder,
            Trabajos motivados por Competición, Reportero, Área Financiera, Alto-Secretario, Contable, Controller.`,
        },
        administrador: {
            titulo: `Administrador`,
            texto: `Tienen habilidades en gerenciar sistemas y también personas. Son buenos comunicadores, buenos
            gerentes de marketing. Son orientados para Resultados. Son rápidos, intensos e asumen riesgos. Tienen
            iniciativa y les gusta resolver problemas. Les gustan desafíos cambios. Son buenos gerentes, emprendedores
            y administradores. Profesiones: Administrador, Reportero, Líder, Gerentes, Marketing.`,
        },
        motivador: {
            titulo: `Motivador`,
            texto: `Son la Comunicaciones en Persona, dan su vida a lo que dicen y son buenos conferencistas,
            motivadores y vendedores. Son capaces de vender el intangible. Tienen habilidad para ayudar a las personas
            a desarrollarse en su trabajo. Son buenos consejeros personales. Profesiones: Conferencista, Orador,
            Relaciones Públicas, Recepcionistas, Trabajos Voluntarios.`,
        },
        vendedor: {
            titulo: `Vendedor`,
            texto: `Son personas que venden ideas, beneficios y prestigio. Son políticos habilidosos. Son
            independientes, no les gusta seguir sistemas o reglas y, por eso, pueden no lidiar muy bien con detalles y
            reglas. Son buenos gerentes ejecutores y tienen sentido de urgencia. Hay personas que se encuadran en este
            perfil que son Gerentes natos, pero aunque tengan las habilidades de ventas, no les gusta la función
            exacta de abordaje de ventas de productos. Pero si tienen esta habilidad, venden cualquier cosa, productos
            tangibles o intangibles. Profesiones: Comercial, Ventas, Adm Ventas, Depto Personal, Relaciones Públicas,
            Publicidad, Conferencista, Consultor, Periodista, Gerente.`,
        },
        diplomata: {
            titulo: `Diplomático`,
            texto: `Tienen habilidad de solucionar conflitos, son buenos oyentes y comunicanse muy bien. Saben pasar
            sus conocimientos y pueden ser Profesores o Instructores. Tienen habilidades para ventas y negociaciones.
            Profesiones: Diplomático, Negociador, Depto Personal, Publicidad, Relaciones Publicas, Auxiliar,
            Publicitario, Trabajo Voluntario, Psicólogo.`,
        },
        aconselhador: {
            titulo: `Consejero`,
            texto: `Buenos oyentes e interesados en asuntos sociales. Psicólogos, Consejeros, Articuladores,
            Harmonizadores y Pacificadores generalmente tienen este perfil. Son agradables y trabajan bien en equipo.
            Quedanse tranquilos en medios que sean más familiares y necesitan de un poco de estructura. Son amistosos
            y con gran habilidad para servicios sociales. A ellos les gusta estar con las personas, orientarlas y
            ayudarlas. Pueden desempeñar trabajos Profesorales, de Diplomacia y trabajos Burocráticos. Profesiones:
            Auxiliar Social, Auxiliar, Recepcionista, Vendedor, Médico, Enfermero, Psicólogo, Consejero.`,
        },
        atendente: {
            titulo: `Auxiliar`,
            texto: `También conocidos como Protectores, a ellos les gusta trabajar con relacionamientos positivos y un
            equipo en el cual los miembros se ayudan mutuamente. Profesiones: Trabajos de Atendimiento, Servicios
            Burocráticos.`,
        },
        professoral: {
            titulo: `Profesoral`,
            texto: `Son buenos Profesores y Instructores. Trabajan bien con Soporte Técnico o otras funciones que
            demandan conocimientos técnicos y transmisión de conocimiento. Son gerentes comprensibles. Profesiones:
            Soporte Técnico, Profesor, Gerente, Vendedor, Recepcionista, Telefonista, Secretário, Pedagogía.`,
        },
        tecnico: {
            titulo: `Técnico`,
            texto: `Son autodidactas, pueden realizar todo lo que viene a la mano para hacer. Son especialistas,
            recuperan trabajos aparentemente perdidos. No les gustan cambios y necesitan mucha estructura. Son buenos
            Analistas de Sistemas Computacionales, Desarrolladores y creadores de sistemas. Lidian bien con números,
            tablas y gráficos. Si su primero factor predominante fuera Planeador, pierde bastante de su iniciativa y
            toma postura más operacional y condescendiente. Profesiones: Analistas (de sistemas, crédito, etc), Área
            de Computación y Informática, Desarrolladores de Softwares, Estadística, Matemática, Ingeniería.`,
        },
        especialista: {
            titulo: `Experto`,
            texto: `Controlan el trabajo de acuerdo con las reglas. Son buenos secretários, mecánicos, pintores y
            electricistas y operadores de computadora. Realizan bien todo el servicio de contabilidad y oficina. Buenos
            operadores de máquinas y en el montaje de electrónicos. Cajas de banco y de supermercado pueden poseer
            ese perfil. Necesitan de reglas y entrenamiento para ejecutar sus tareas y las hacen muy bien hechas.
            Profesiones: Operador de Caja, Área Financiera, Tesorero, Arquitecto, Designer Gráfico, Artista, Músico,
            Escritor.`,
        },
        estrategista: {
            titulo: `Estrategista`,
            texto: `Son inventores y organizadores, ejecutan bien trabajos técnicos y analíticos. No son buenos
            comunicadores. Son buenos con problemas fiscales, contables y con trabajos estadísticos. Poseen habilidad
            en trabajos sistematizados. Profesiones: Contabilista, Inventor, Arquitecto, Designer Gráfico, Artista,
            Músico, Escritor.`,
        },
        controlador: {
            titulo: `Controlador`,
            texto: `Son rápidos y eficientes; exigentes con ellos mismos y con los otros. Tienen alto patrón de
            desempeño y son disciplinados. Son reservados cuando se comunican. Son buenos Gerentes de Proyectos y de
            trabajos que exigen la ejecución con rapidez y cualidad. Profesiones: Gerentes, Supervisores, Ejecutores.`,
        },
    },
    tipos: {
        masculino: {
            A: `Analista`,
            E: `Ejecutor`,
            C: `Comunicador`,
            P: `Planificador`,
        },
        feminino: {
            A: `Analista`,
            E: `Ejecutora`,
            C: `Comunicadora`,
            P: `Planificadora`,
        },
    },
    niveis: {
        alto: `Alto`,
        baixo: `Bajo`,
        e_alto: `Extremadamente Alto`,
        e_baixo: `Extremadamente Bajo`,
        m_alto: `Muy Alto`,
        m_baixo: `Muy Bajo`,
        n_alto: `Normal Alto`,
        n_baixo: `Normal Bajo`,
    },
    complementares: {
        adaptabilidade: `Adaptabilidad`,
        ambiente: `Ambiente`,
        area_talentos: `Área de talentos`,
        competencias: `Habilidades`,
        condescendente: `Condescendiente`,
        distribuicao_fatores: `Esta es la distribución de factores <b>positivos</b> o <b>negativos</b> por perfil.`,
        dominante: `Dominante`,
        em: `en`,
        formal: `Formal`,
        indicadores_situacionais: `Indicadores Situacionales`,
        informal: `Informal`,
        minuto: `minuto`,
        minutos: `minutos`,
        neste_momento: `En este momento, %{nome} es:`,
        perfil_atual: `Perfil Actual`,
        segundos: `segundos`,
        tempo_resposta: `Tiempo de Respuesta`,
    },
};