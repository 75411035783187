import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentBox = styled.div`
  width: 100%;
`;

export const Label = styled.p`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.05em;
  color: #ffffff;
  position: absolute;
  height: calc(100% - 6px);

  ${({ percentage }) =>
    percentage === 0 && " color: #000; padding-left: 0px;"}/* ${({
    percentage,
  }) =>
    percentage < 15 &&
    css`
      text-indent: 0;
      position: absolute;
      top: 0px;
      left: 49%;
      transform: translateX(-50%);
    `} */
`;

export const Progress = styled.div`
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  margin-top: 16px;
`;

export const ContainerProgressStep = styled.div`
  width: 100%;
  border-radius: 50px;
  display: flex;
  justify-content: ${({ funnel }) => (funnel ? funnel : "flex-start")};
  background: ${({ progressBackground }) =>
    progressBackground ? progressBackground : "#efedf3"};
  height: 32px;
  padding: 3px;
  position: relative;
`;

export const CurrentProgressStep = styled.div`
  ${({ percentage }) =>
    percentage < 15 ? " width: 64px" : ` width: ${percentage}%;`}
  ${({ percentage }) => percentage === 0 && " width: 0px;"}
    height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: ${({ progressColor }) =>
    progressColor ? progressColor : "#601e82;"};

  ${({ percentage }) =>
    percentage < 15 &&
    css`
      position: relative;
    `}
`;

export const ProgressRows = styled.div`
  display: grid;
  grid-template-columns: 94% 6%;
  grid-gap: 2px;
`;

export const percentage = styled.div`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 98%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: -0.05em;
  color: #601e82;
`;

export const Title = styled.div`
  font-family: "Roboto Condensed";
  font-weight: bold;
  font-size: 12px;
  color: #601e82;
  text-indent: 24px;
  text-transform: uppercase;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
