import styled from "styled-components";

export const PageBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const NotFound = styled.div``;
export const Text = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-size: 16px;
  color: #601e82;
  margin-top: 20px;
  text-align: center;
`;

export const TextDate = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-size: 18px;
  color: #601e82;
  text-align: center;
`;
export const ContainerTextReport = styled.div`
  display: flex;
  flex-direction: ${({ twoColmun }) => (twoColmun ? "row" : "column")};
  ${({ marginTop }) => marginTop && `margin-top:${marginTop}px`};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : "40px"};
  width: 100%;

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;

    span {
      ${({ id }) =>
        id === "vantagensDesvantagens" &&
        `
                 margin-top: 30px;
                display: block;
            `}
    }
  }
`;
export const ColumnText = styled.div`
  width: ${({ width }) => (width ? `${width}%` : "100%")};
  padding-left: ${({ paddingLeft }) => paddingLeft && `${paddingLeft}px`};
  padding-right: ${({ paddingRight }) => paddingRight && `${paddingRight}px`};

  @media screen and (max-width: 600px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const TitleTextReports = styled.span`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 150%;
  text-align: justify;
  color: #5f4a87;
  text-transform: uppercase;
`;
export const ContentTextReports = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: justify;
  color: #601e82;
`;
export const SpaceBox = styled.div`
  margin: 10px 0 30px 0;
  /* padding: 0 20px; */
  display: flex;
  flex-direction: column;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`}
  ${({ margin }) => margin && `margin: ${margin}`}

    ${({ id }) =>
    id === "chartinteraction" && ` height: 340px; margin-bottom: 40px; `}

     @media (max-width: 702px) {
    height: auto;
    margin-bottom: 0;
  }
`;
export const ContentCulturaAtualContentCard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -10px;
`;

export const Title = styled.h1`
  color: #601e82 !important;
  text-align: center;
  font-size: 36px;
  margin: 0px 0px 0px 0px;
  font-weight: bold;
`;
export const BoxTextValidation = styled.div`
  background: #f5f4f8;
  border-radius: 10px;
`;

export const BoxReports = styled.div`
  display: flex;
  align-items: center;

  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "space-between"};
`;
export const WrapperSelect = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 179px;
  align-self: flex-end;
  align-items: flex-start;
`;
export const LabelSelect = styled.div`
  font-size: 12px;
  color: #601e82;
  padding-left: 10px;
`;
export const TextValidation = styled.p`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-size: 16px;
  color: #601e82;
  margin: 10px 0;
  text-align: center;
  padding: 0 10px;
`;
export const TitleSecondary = styled.h1`
  font-size: 24px;
  text-transform: uppercase;
  font-weight: bold;
  color: #5f4a87;
  text-align: ${({ center }) => (center ? "left" : "center")};
  font-family: "Roboto Condensed";
  margin-bottom: 0;
`;
export const Description = styled.p`
  font-size: 16px;
  color: #601e82;
  text-align: center;
  line-height: 150%;
  padding: 0px 0 20px 0;
`;

export const ContainerSecondaryChart = styled.div`
  margin-top: 20px;
`;

export const SituationalIndicator = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 0px;
  grid-row: 2 span;
  padding: 10px 0;
`;

export const SituationalIndicator4 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  grid-row: 2 span;
  padding: 20px 0;
`;

export const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const InfoTime = styled.div`
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;
export const Time = styled.div`
  font-family: "Roboto Condensed";
  font-size: 14px;
  line-height: 14px;
  color: #601e82;
  padding-bottom: 5px;
`;
export const Nivel = styled.div`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #601e82;
  text-transform: uppercase;
`;

export const BoxInfoPerson = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BoxInfoPersonEnterprise = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 72px;
`;

export const LogoImage = styled.img`
  ${({ nullable }) => (nullable ? "width: 15%" : "width: 25%")}
`;

export const LogoImageText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #601e82;
  margin-top: 12px;
`;

export const BoxInfoPersonPersonal = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
`;

export const BoxInfoPersonPersonalLeft = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const BoxInfoPersonPersonalRight = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const BoxInfoPersonPersonalTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #601e82;
`;

export const BoxInfoPersonPersonalDesc = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #601e82;
`;
