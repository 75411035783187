import styled from "styled-components";

export const BackgroundProfiler = styled.div`
  margin: 0 auto;
  max-width: 1044px;
  background: #fff;
  border-radius: 20px;
  padding-top: 60px;
  margin-top: 50px !important;
  margin-bottom: 50px;

  @media screen and (min-width: 1280px) {
    padding: 0 150px 150px;
    padding-top: 60px;
  }
  #legendResult {
    @media (max-width: 1280px) {
      left: 672px;
    }
    @media (max-width: 992px) {
      left: 619px;
    }
  }
  @media screen and (max-width: 1280px) {
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
  }
`;
