import React from "react";
import { Tooltip } from "antd";
import * as Styled from "./styles";

const SearchResults = ({
    voce = {
        dominante: "25%",
        informal: "25%",
        condescendente: "25%",
        formal: "25%",
    },
    meio = {
        dominante: "25%",
        informal: "25%",
        condescendente: "25%",
        formal: "25%",
    },
    flexibilidade = {
        dominante: "25%",
        informal: "25%",
        condescendente: "25%",
        formal: "25%",
    },
    isTooltip,
    tooltipContent = [],
    name,
}) => {
    const _renderDominante = () => {
        return (
            <Styled.Info>
                <Styled.ItemInfo></Styled.ItemInfo>
                <Styled.ItemInfo dominante>Dominante</Styled.ItemInfo>
            </Styled.Info>
        );
    };
    const _renderInformal = () => {
        return (
            <Styled.Info>
                <Styled.ItemInfo>{`${" "}`}</Styled.ItemInfo>
                <Styled.ItemInfo informal>Informal</Styled.ItemInfo>
            </Styled.Info>
        );
    };

    const _renderCondescendente = () => {
        return (
            <Styled.Info>
                <Styled.ItemInfo>{`${" "}`}</Styled.ItemInfo>
                <Styled.ItemInfo condescendente>Condescendente</Styled.ItemInfo>
            </Styled.Info>
        );
    };
    const _renderFormal = () => {
        return (
            <Styled.Info last>
                <Styled.ItemInfo>{`${" "}`}</Styled.ItemInfo>
                <Styled.ItemInfo formal>Formal</Styled.ItemInfo>
            </Styled.Info>
        );
    };
    const renderTooltip = () => {
        if (isTooltip) {
            return (
                <>
                    <Tooltip
                        overlayClassName="ant-tooltip-custumer"
                        placement="bottom"
                        title={tooltipContent.dominante}
                    >
                        {_renderDominante()}
                    </Tooltip>
                    <Tooltip
                        overlayClassName="ant-tooltip-custumer"
                        placement="bottom"
                        title={tooltipContent.informal}
                    >
                        {_renderInformal()}
                    </Tooltip>
                    <Tooltip
                        overlayClassName="ant-tooltip-custumer"
                        placement="bottom"
                        title={tooltipContent.condescendente}
                    >
                        {_renderCondescendente()}
                    </Tooltip>
                    <Tooltip
                        overlayClassName="ant-tooltip-custumer"
                        placement="bottom"
                        title={tooltipContent.formal}
                    >
                        {_renderFormal()}
                    </Tooltip>
                </>
            );
        }

        return (
            <>
                {_renderDominante()}
                {_renderInformal()}
                {_renderCondescendente()}
                {_renderFormal()}
            </>
        );
    };

    const tooltipTitle = (title, tooltip = "") => {
        if (isTooltip) {
            return (
                <>
                    <Tooltip
                        overlayClassName="ant-tooltip-custumer"
                        placement="left"
                        title={tooltip}
                    >
                        <Styled.InfoText>{title}</Styled.InfoText>
                    </Tooltip>
                </>
            );
        }
        return <Styled.InfoText>{title}</Styled.InfoText>;
    };
    const wordUpper = palavra => {
        const firstName = palavra
            .substring(0, 1)
            .toUpperCase()
            .concat(palavra.substring(1))
            .split(" ")[0];
        return firstName;
    };
    return (
        <Styled.Container>
            <Styled.Content first>
                <Styled.InfoTextContainer>
                    {tooltipTitle(
                        name ? wordUpper(name) : "Você",
                        "como você se comporta"
                    )}
                </Styled.InfoTextContainer>
                <Styled.ContentChart
                    dominante={voce.dominante}
                    informal={voce.informal}
                    condescendente={voce.condescendente}
                    formal={voce.formal}
                >
                    <Styled.Chart
                        dominante={voce.dominante}
                        informal={voce.informal}
                        condescendente={voce.condescendente}
                        formal={voce.formal}
                    >
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={`Dominante ${parseFloat(
                                voce.dominante
                            ).toFixed(2)} %`}
                        >
                            <Styled.Item dominante first>
                                {parseFloat(voce.dominante) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        </Tooltip>
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={`Informal ${parseFloat(
                                voce.informal
                            ).toFixed(2)} %`}
                        >
                            <Styled.Item informal>
                                {parseFloat(voce.informal) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        </Tooltip>
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={`Condescendente ${parseFloat(
                                voce.condescendente
                            ).toFixed(2)} %`}
                        >
                            <Styled.Item condescendente>
                                {parseFloat(voce.condescendente) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        </Tooltip>
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={`Formal ${parseFloat(voce.formal).toFixed(
                                2
                            )} %`}
                        >
                            <Styled.Item formal last>
                                {parseFloat(voce.formal) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        </Tooltip>
                    </Styled.Chart>
                </Styled.ContentChart>
            </Styled.Content>
            <Styled.Content>
                <Styled.InfoTextContainer>
                    {tooltipTitle("Meio", "como o meio exige de você")}
                </Styled.InfoTextContainer>
                <Styled.ContentChart
                    dominante={meio.dominante}
                    informal={meio.informal}
                    condescendente={meio.condescendente}
                    formal={meio.formal}
                >
                    <Styled.Chart
                        dominante={meio.dominante}
                        informal={meio.informal}
                        condescendente={meio.condescendente}
                        formal={meio.formal}
                    >
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={`Dominante ${parseFloat(
                                meio.dominante
                            ).toFixed(2)} %`}
                        >
                            <Styled.Item dominante first>
                                {parseFloat(meio.dominante) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        </Tooltip>
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={`Informal ${parseFloat(
                                meio.informal
                            ).toFixed(2)} %`}
                        >
                            <Styled.Item informal>
                                {parseFloat(meio.informal) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        </Tooltip>
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={`Condescendente ${parseFloat(
                                meio.condescendente
                            ).toFixed(2)} %`}
                        >
                            <Styled.Item condescendente>
                                {parseFloat(meio.condescendente) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        </Tooltip>
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={`Formal ${parseFloat(meio.formal).toFixed(
                                2
                            )} %`}
                        >
                            <Styled.Item formal last>
                                {parseFloat(meio.formal) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        </Tooltip>
                    </Styled.Chart>
                </Styled.ContentChart>
            </Styled.Content>
            <Styled.Content>
                <Styled.InfoTextContainer>
                    {tooltipTitle(
                        "Adaptabilidade",
                        "como você se adapta ao meio"
                    )}
                </Styled.InfoTextContainer>
                <Styled.ContentChart
                    dominante={flexibilidade.dominante}
                    informal={flexibilidade.informal}
                    condescendente={flexibilidade.condescendente}
                    formal={flexibilidade.formal}
                >
                    <Styled.Chart
                        dominante={flexibilidade.dominante}
                        informal={flexibilidade.informal}
                        condescendente={flexibilidade.condescendente}
                        formal={flexibilidade.formal}
                    >
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={`Dominante ${parseFloat(
                                flexibilidade.dominante
                            ).toFixed(2)} %`}
                        >
                            <Styled.Item dominante first>
                                {parseFloat(flexibilidade.dominante) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        </Tooltip>
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={`Informal ${parseFloat(
                                flexibilidade.informal
                            ).toFixed(2)} %`}
                        >
                            <Styled.Item informal>
                                {parseFloat(flexibilidade.informal) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        </Tooltip>
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={`Condescendente ${parseFloat(
                                flexibilidade.condescendente
                            ).toFixed(2)} %`}
                        >
                            <Styled.Item condescendente>
                                {parseFloat(flexibilidade.condescendente) >
                                    25 && <Styled.BallItem />}
                            </Styled.Item>
                        </Tooltip>
                        <Tooltip
                            overlayClassName="ant-tooltip-custumer"
                            placement="top"
                            title={`Formal ${parseFloat(
                                flexibilidade.formal
                            ).toFixed(2)} %`}
                        >
                            <Styled.Item formal last>
                                {parseFloat(flexibilidade.formal) > 25 && (
                                    <Styled.BallItem />
                                )}
                            </Styled.Item>
                        </Tooltip>
                    </Styled.Chart>
                </Styled.ContentChart>
            </Styled.Content>

            <Styled.ContentLegend>
                <Styled.ContentBlank />
                <Styled.ContentLegendInfo>
                    <Styled.ContentLegendInfoMain>
                        {renderTooltip()}
                    </Styled.ContentLegendInfoMain>
                </Styled.ContentLegendInfo>
            </Styled.ContentLegend>
        </Styled.Container>
    );
};

export default SearchResults;
