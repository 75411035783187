import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
`;

export const Info = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

export const NegativeInfo = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-start;
`;

export const PositiveInfo = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

export const TextPositive = styled.span`
    font-weight: bold;
    font-size: 14px;
    color: #716588;
    margin-right: 30px;

    @media screen and (max-width: 540px) {
        font-size: 12px;
    }
`;

export const TextNegative = styled.span`
    font-weight: bold;
    font-size: 14px;
    color: #716588;
    margin-left: 30px;

    @media screen and (max-width: 540px) {
        font-size: 12px;
    }
`;

export const Chart = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 3px;
`;

export const InfoBottom = styled.div`
    display: flex;
    margin-top: 10px;
`;

export const Points = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-start;
`;

export const PointsText = styled.span`
    font-weight: normal;
    font-size: 10px;
    color: #716588;
    margin-left: 30px;
`;

export const Highlights = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

export const HighlightsText = styled.span`
    font-weight: normal;
    font-size: 10px;
    color: #716588;
    margin-right: 30px;
`;
