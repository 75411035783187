import styled from "styled-components";

export const MatchNegativePositiveContent = styled.div`
    position: relative;
    width: 100%;
`;

export const FirstContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const Line = styled.div`
    position: absolute;
    width: 91%;
    height: 1px;
    background: #f1f1f1;
    margin-left: 36px;
`;

export const ImgContent = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    z-index: 2;
`;

export const SecondContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`;

export const TextImagePerson = styled.span`
    font-size: 16px;
    line-height: 10px;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
`;

export const MatchImage = styled.div`
    width: 40px;
    height: 40px;
    ${({ data }) => data && `background-image: url(${data});`};
    ${({ data }) =>
        !data &&
        `align-items: center; display: flex; justify-content: center;`};
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-right: 5px;
`;

export const ChartContent = styled.div`
    flex: 6;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const Item = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    height: 60px;
    align-items: center;
    position: relative;
`;

export const Info = styled.div`
    width: 1px;
    height: 50px;
    background: #7a869a;
    opacity: 0.15;
    position: absolute;
`;

export const Text = styled.div`
    position: absolute;
`;

export const InfoNegative = styled.span`
    position: absolute;
    top: -30px;
    left: 15px;
    font-size: 13px;
    font-family: "Roboto Condensed;";
    ${({ executor, planejador, comunicador }) =>
        executor
            ? `color: #D74149;`
            : comunicador
            ? `color: #FFBA00;`
            : planejador
            ? `color: #57DD6D;`
            : `color: #00C9DB;`};
    font-weight: bold;
`;

export const InfoPositive = styled.span`
    position: absolute;
    top: -30px;
    right: 15px;
    font-size: 13px;
    font-family: "Roboto Condensed";
    ${({ executor, planejador, comunicador }) =>
        executor
            ? `color: #D74149;`
            : comunicador
            ? `color: #FFBA00;`
            : planejador
            ? `color: #57DD6D;`
            : `color: #00C9DB;`};
    font-weight: bold;
`;

export const PositiveContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
`;

export const NegativeContainer = styled.div`
    flex: 1;
`;

export const Positive = styled.div`
    ${({ percentage }) => percentage && `width: ${percentage}px`}
    height: 30px;
    ${({ executor, planejador, comunicador }) =>
        executor
            ? `background: #D74149;`
            : comunicador
            ? `background: #FFBA00;`
            : planejador
            ? `background: #57DD6D;`
            : `background: #00C9DB;`};

    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
`;

export const Negative = styled.div`
    ${({ percentage }) => percentage && `width: ${percentage}px;`}
    height: 30px;
    ${({ executor, planejador, comunicador }) =>
        executor
            ? `background: #AC343A;`
            : comunicador
            ? `background: #CC9500;`
            : planejador
            ? `background: #46B157;`
            : `background: #26A0AB;`};
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
`;
