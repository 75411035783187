/* eslint-disable max-len */
export const profilerTextStatic = {
    interacao_ambiente: {
        titulo: `Interação com o Ambiente`,
        texto: `Este gráfico mostra o perfil interno e a cobrança do meio externo. O primeiro ponto de cada linha é o
        perfil Executor, o segundo ponto de cada linha é o Comunicador, terceiro Planejador e quarto Analista. A linha
        azul indica o perfil interno, como a pessoa realmente é, em sua natureza, e como age em ambientes familiares. A
        linha vermelha indica como o meio externo está cobrando que ela seja. Observe as diferenças que estão sendo
        exigidas de mudança de comportamento em cada ponto, e a força destas alterações demandadas. Fortes alterações
        demandam consumo de energia mas também conduzem a aprendizado. Poucas alterações indicam boa adaptação da
        pessoa às funções exercidas mas também poucos desafios.`,
    },
    estilio_lideranca: {
        titulo: `Estilo de Liderança`,
        texto: `O primeiro ponto é o de Dominância, o segundo de Informalidade, o terceiro Condescendência e o quarto
        Formalidade. Observe os pontos que estão acima da linha horizontal central para checar os perfis predominantes.
        Também aqui a linha azul é o perfil interno e a linha vermelha é a cobrança do meio externo. Perceba a
        intensidade de diferenças entre cada ponto entre as linhas azul e vermelha. Confira nos índices, o nível do IEM
        para ver a classificação da intensidade das alterações demandadas, e a Energia para observar a força para a
        pessoa lidar com estas alterações. Liderança Atual indica como a pessoa está agindo atualmente com relação ao
        seu estilo de lideranca. Verifique o desenho da linha com o que o ambiente externo está exigindo no gráfico
        anterior para checar se a pessoa está entregando o modelo demandado ou se precisa de ajustes em seu estilo ou
        na própria demanda.`,
    },
    situational_Indicator: {
        energia: {
            titulo: `Energia`,
            texto: `Indica o "pique" para o trabalho, a capacidade de mudar e a habilidade de absorver o estresse mais
            facilmente. Se ela estiver Baixa, Muito Baixa ou Extremamente Baixa, observe o IEM abaixo para checar se as
            exigências do meio externo estão muito grandes, demandando muitas habilidades não naturais do perfil.`,
        },
        nivelestress: {
            titulo: `Exigência do Meio`,
            texto: `Mede o quão forte é a exigência do meio externo. Além do índice poderemos ver nos gráficos as
            cobranças do meio externo de maneira comparativa. Temos nosso perfil interno e nossa percepção de como
            deveríamos ser para ter um desempenho melhor. Esta segunda parte é a cobrança do meio externo.`,
        },
        ia_nivel: {
            titulo: `Aproveitamento`,
            texto: `Indica que a pessoa se encontra num momento em que percebe que suas habilidades estão sendo bem
            aproveitadas em suas atividades. O IA baixo significa sub aproveitamento, é uma subutilização de suas
            habilidades, pode ser por fluxo de trabalho muito lento, excesso de pessoal no departamento, paralisações
            ou interrupções de trabalho muito frequentes.`,
        },
        moral_nivel: {
            titulo: `Moral`,
            texto: `Indica o nível de auto aprovação da pessoa em termos de seu desempenho profissional e/ou pessoal.
            Moral alto: Significa que a pessoa tem uma boa auto aprovação profissional, acredita que está caminhando
            na direção certa de seu desenvolvimento. Moral baixo: Significa que a pessoa sente que muitas mudanças
            devem ocorrer para que seu desempenho melhore.`,
        },
        nivelip: {
            titulo: `Positividade`,
            texto: `Mede a autoestima do indivíduo. Indica o nível de auto aprovação em termos de seu desempenho
            principalmente em questões pessoais. É a relação entre as características positivas e as negativas que o
            indivíduo enxerga em si mesmo em questão de autovalor ou de autocrítica.`,
        },
        flex_nivel: {
            titulo: `Flexibilidade`,
            texto: `Mede o quanto a pessoa pode mudar seu comportamento e com que facilidade pode fazê-lo. Além da
            atitude aponta com que facilidade o indivíduo aceita e absorve novos conceitos e quebra de paradigmas,
            quando necessário. O IF varia de acordo com a época em que foi respondido o questionário, pois envolve além
            do perfil, o estado emocional e profissional para absorção de novos conceitos e desafios naquele momento.`,
        },
        amplitude_nivel: {
            titulo: `Amplitude`,
            texto: `Indica o quão forte é a importância do ambiente de trabalho na produtividade do profissional. Uma
            Amplitude alta significa que o ambiente propício para este perfil aumenta sua produtividade. Também indica
            o quanto a pessoa impacta em seu ambiente de trabalho. A amplitude alta significa que a pessoa provoca um
            impacto (positivo e/ou negativo) no grupo onde está inserido.`,
        },
        nivel_energperfil: {
            titulo: `Automotivação`,
            texto: `É a energia natural do perfil da pessoa. Este indicador traduz a energia pelo perfil
            comportamental, e não pela situação em que a pessoa está passando. Seria como o potencial de energia que
            ele pode entregar em seu perfil.`,
        },
        nivel_incitab: {
            titulo: `Incitabilidade`,
            texto: `É o potencial de reação a estímulos. É o quanto a pessoa incendeia com uma nova idéia ou desafio.`,
        },
    },
    competences: {
        tolerancia: {
            titulo: `Tolerância`,
            texto: `Capacidade de tolerar diferentes maneiras de pensar, agir e sentir, mesmo sendo opostas às adotadas
            por si mesmo.`,
        },
        planejamento: {
            titulo: `Planejamento`,
            texto: `Capacidade de planejar as ações, processos e atividades`,
        },
        empatia: {
            titulo: `Empatia`,
            texto: `Capacidade de compreender o sentimento ou reação da outra pessoa imaginando-se nas mesmas
            circunstâncias.`,
        },
        capac_ouvir: {
            titulo: `Capacidade de Ouvir`,
            texto: `Capacidade de escuta ativa, habilidade de ouvir com atenção.`,
        },
        concentracao: {
            titulo: `Concentracão`,
            texto: `Nível de capacidade/necessidade de concentração para execução de um trabalho que exige atenção e
            constância. Essa competência mensura não só a capacidade de concentração da pessoa como também sua
            necessidade de estar em um ambiente adequado para tal atividade.`,
        },
        condescendencia: {
            titulo: `Condescendência`,
            texto: `Indica o quanto a pessoa considera e pondera as intenções, desejos e opinião de outrem, agindo com
            complacência para buscar a melhor ação possível.`,
        },
        perfil_tecnico: {
            titulo: `Perfil Técnico`,
            texto: `Aptidão para habilidades técnicas. Indica a capacidade de a pessoa se "tecnificar", dar ou
            proporcionar recursos técnicos a uma atividade para otimizá-la.`,
        },
        organizacao: {
            titulo: `Organização`,
            texto: `É a capacidade de um indivíduo de organizar suas ideias de maneira clara e bem definida. Essa
            competência resvala no ambiente físico também como a capacidade de manter o ambiente limpo e arrumado.`,
        },
        detalhismo: {
            titulo: `Detalhismo`,
            texto: `Capacidade de exposição minuciosa de fatos, planos ou projetos, com atenção a detalhes.`,
        },
        rigorosidade: {
            titulo: `Rigorosidade`,
            texto: `Competência de uma pessoa exata, precisa, exigente e de raciocínio rigoroso.`,
        },
        orientadorsultado: {
            titulo: `Orientado por Resultados`,
            texto: `É a competência que identifica o quanto a pessoa se desenvolve pelo trabalho e pela ação,
            investindo mais tempo na execução das tarefas.`,
        },
        multitarefas: {
            titulo: `Multitarefas`,
            texto: `Capacidade de executar várias tarefas ao mesmo tempo.`,
        },
        automotivacao: {
            titulo: `Automotivação`,
            texto: `Indica o nível da capacidade de a pessoa se auto-motivar, a capacidade de motivar-se ao
            entusiasmo.`,
        },
        proatividade: {
            titulo: `Proatividade`,
            texto: `Indica a capacidade de agir antecipadamente, resolvendo situações e problemas futuros sem que seja
            obrigatoriamente requerido.`,
        },
        dinamismo: {
            titulo: `Dinamismo`,
            texto: `Particularidade e característica de quem demonstra energia, movimento e vitalidade, lida bem com
            mudanças e ambientes dinâmicos.`,
        },
        dominancia: {
            titulo: `Dominância`,
            texto: `Competência de quem exerce uma postura dominante, firme. Normalmente toma decisões rápidas por ser
            menos avesso a risco e assume uma postura de comando.`,
        },
        extroversao: {
            titulo: `Extroversão`,
            texto: `Característica de quem é extrovertido, expansivo, comunicativo e sociável. Uma pessoa extrovertida
            tem facilidade em se sociabilizar.`,
        },
        relac_interpes: {
            titulo: `Relacionamento Interpessoal`,
            texto: `Capacidade de estabelecer conexões ou vínculos com outras pessoas dentro de um determinado
            contexto.`,
        },
        sociabilidade: {
            titulo: `Sociabilidade`,
            texto: `Indica a necessidade e a tendência à busca por relacionamento social com as outras pessoas, de
            forma expansiva e extrovertida.`,
        },
        orientadorelacionamento: {
            titulo: `Orientado por Relacionamento`,
            texto: `O nível de foco da pessoa em relacionamentos. Esta competência indica o quanto a pessoa se
            desenvolve por relacionamentos e os prioriza em suas tarefas.`,
        },
    },
    talentsArea: {
        comandante: {
            titulo: `Comandante`,
            texto: `Pessoas com o perfil de comandante são empreendedoras, independentes, exigentes consigo mesmas e
            com os outros. São bons iniciadores de novos negócios por serem assumidores de risco. São ótimos
            resolvedores de problemas e em funções que exijam um pulso forte no comando e liderança de equipe altamente
            condescendente. Profissões: Advogado, Cargos de Comando, Líder, Supervisores, Gerentes de Produção,
            Comandantes, Chefes de Construção.`,
        },
        competidor: {
            titulo: `Competidor`,
            texto: `Se alimentam de vitórias e cada dia é uma disputa para ele na qual ele precisa se sair vitorioso.
            Podem atuar como Vendedores, Engenheiros ou Professores. Respeitam mais as regras que os vendedores com
            alto índice de comunicação, mas se especializar em áreas mais técnicas, se sentirá pouco à vontade para
            fechamento de negócios. Têm pulso e garra, e gostam de competir. Profissões: Advogado, Líder, Trabalhos
            motivados por Competição, Repórter, Área Financeira, Alto-Secretário, Contabilista, Controller.`,
        },
        administrador: {
            titulo: `Administrador`,
            texto: `Têm habilidades em gerenciar sistemas e também pessoas. São bons comunicadores, bons gerentes de
            marketing. São orientados para Resultados. São rápidos, intensos e assumem riscos. Têm iniciativa e gostam
            de resolver problemas. Gostam de desafios e de mudanças. São bons gerentes, empreendedores e
            administradores. Profissões: Administrador, Repórter Líder, Gerentes, Marketing.`,
        },
        motivador: {
            titulo: `Motivador`,
            texto: `São a Comunicação em Pessoa, dão vida ao que falam e são bons palestrantes, motivadores e
            vendedores. São capazes de vender o intangível. Têm habilidade para ajudar as pessoas a se desenvolverem em
            seu trabalho. São bons conselheiros pessoais. Profissões: Palestrante, Orador, Relações Públicas,
            Recepcionistas, Trabalhos Voluntários.`,
        },
        vendedor: {
            titulo: `Vendedor`,
            texto: `São pessoas Vendem idéias, benefícios e prestígio. São políticos habilidosos. São independentes,
            não gostam de seguir sistemas ou regras e, por isso, podem não lidar bem com detalhes e regras. São bons
            gerentes executores e têm senso de urgência. Há pessoas que se enquadram neste perfil que são Gerentes
            natos, mas apesar de ter as habilidades de vendas, não gostam da função exata de abordagem de vendas de
            produtos. Mas se voltados à esta habilidade, vendem qualquer coisa, produtos tangíveis ou intangíveis.
            Profissões: Comercial, Vendas, Adm Vendas, Depto Pessoal, Relações Públicas, Publicidade, Palestrante,
            Consultor, Jornalista, Gerente.`,
        },
        diplomata: {
            titulo: `Diplomata`,
            texto: `Têm habilidade de solucionar conflitos, são bons ouvintes e se comunicam bem. Sabem passar seus
            conhecimentos e podem ser Professores ou Instrutores. Têm habilidades para vendas e negociações.
            Profissões: Diplomata, Negociador, Depto Pessoal, Publicidade, Relações Públicas, Atendente, Publicitário,
            Trabalho Voluntário, Psicólogo.`,
        },
        aconselhador: {
            titulo: `Aconselhador`,
            texto: `Bons ouvintes e voltados a interesses sociais. Psicólogos, Conselheiros, Articuladores,
            Harmonizadores e Pacificadores geralmente têm este perfil. São agradáveis e trabalham bem em equipe. Ficam
            mais a vontade em meios que lhe são mais familiares e precisam de um pouco de estrutura. São amistosos e
            com grande habilidade para serviços sociais. Gostam de estar com as pessoas, orientá-las e ajudá-las. Podem
            desempenhar trabalhos Professorais, de Diplomacia e trabalhos Burocráticos. Profissões: Assistente Social,
            Atendente, Recepcionista, Balconista, Médico, Enfermeiro, Psicólogo, Conselheiro.`,
        },
        atendente: {
            titulo: `Atendente`,
            texto: `Também conhecidos como Protetores, gostam de trabalhar com relacionamentos positivos e uma equipe
            onde os membros se ajudam mutuamente. Profissões: Trabalhos de Atendimento, Serviços Burocráticos.`,
        },
        professoral: {
            titulo: `Professoral`,
            texto: `São bons Professores e Instrutores. Trabalham bem com Suporte Técnico ou outras funções que
            demandam conhecimentos técnicos e transmissão de conhecimento. São gerentes compreensíveis. Profissões:
            Suporte Técnico, Professor, Gerente, Atendente, Recepcionista, Telefonista, Secretariado, Pedagogo.`,
        },
        tecnico: {
            titulo: `Técnico`,
            texto: `São auto didatas, conseguem realizar tudo o que vem à mão para fazer. São especialistas, recuperam
            trabalhos aparentemente perdidos. Não gostam de mudanças e precisam de muita estrutura. São bons Analistas
            de Sistemas Computacionais, Desenvolvedores e criadores de sistemas. Lidam bem com números, tabelas e
            gráficos. Se seu primeiro fator predominante for Planejador, perde bastante de sua iniciativa e toma
            postura mais operacional e condescendente. Profissões: Analistas (de sistemas, crédito, etc), Área de
            Computação e Informática, Desenvolvedores de Softwares, Estatística, Matemática, Engenharia.`,
        },
        especialista: {
            titulo: `Especialista`,
            texto: `Controlam o trabalho de acordo com as regras. São bons secretários, mecânicos, pintores e
            eletricistas e operadores de computador. Realizam bem todo o serviço de contabilidade e escritório. Bons
            operadores de máquinas e na montagem de eletrônicos. Caixas de banco e de supermercado podem possuir esse
            perfil. Precisam de regras e treinamento para executarem suas tarefas e as faz muito bem feito. Profissões:
            Operador de Caixa, Área Financeira, Tesoureiro, Arquiteto, Designer Gráfico, Artista, Musico, Escritor.`,
        },
        estrategista: {
            titulo: `Estrategista`,
            texto: `São inventores e organizadores, executam bons trabalhos técnicos e analíticos. Não são bons
            comunicadores. São bons com problemas fiscais, contábeis e com trabalhos estatísticos. Possuem habilidade
            em trabalhos sistematizados. Profissões: Contabilista, Inventor, Arquiteto, Designer Gráfico, Artista,
            Músico, Escritor.`,
        },
        controlador: {
            titulo: `Controlador`,
            texto: `São rápidos e eficientes; exigentes consigo mesmo e com os outros. Têm alto padrão de desempenho e
            são disciplinados. São reservados ao se comunicarem. São bons Gerentes de Projetos e de trabalhos que
            exijam a execução com rapidez e qualidade. Profissões: Gerentes, Supervisores, Executores.`,
        },
    },
    tipos: {
        masculino: {
            A: `Analista`,
            E: `Executor`,
            C: `Comunicador`,
            P: `Planejador`,
        },
        feminino: {
            A: `Analista`,
            E: `Executora`,
            C: `Comunicador`,
            P: `Planejadora`,
        },
    },
    niveis: {
        alto: `Alto`,
        baixo: `Baixo`,
        e_alto: `Extremamente Alto`,
        e_baixo: `Extremamente Baixo`,
        m_alto: `Muito Alto`,
        m_baixo: `Muito Baixo`,
        n_alto: `Normal Alto`,
        n_baixo: `Normal Baixo`,
    },
    complementares: {
        adaptabilidade: `Adaptabilidade`,
        ambiente: `Ambiente`,
        area_talentos: `Área de Talentos`,
        competencias: `Competências`,
        condescendente: `Condescendente`,
        distribuicao_fatores: `Esta é a distribuição de fatores <b>positivos</b> ou <b>negativos</b> por perfil.`,
        dominante: `Dominante`,
        em: `em`,
        formal: `Formal`,
        indicadores_situacionais: `Indicadores Situacionais`,
        informal: `Informal`,
        minuto: `minuto`,
        minutos: `minutos`,
        neste_momento: `Neste momento, %{nome} está:`,
        perfil_atual: `Perfil Atual`,
        segundos: `segundos`,
        tempo_resposta: `Tempo de Resposta`,
    },
};
