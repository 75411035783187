import getStaticTextsProfiler from "./getStaticTextsProfiler";

function getTextLevel(index) {
  const staticTextsProfiler = getStaticTextsProfiler();
  const TextsLevelMap = {
    1: staticTextsProfiler.niveis.e_baixo,
    2: staticTextsProfiler.niveis.m_baixo,
    3: staticTextsProfiler.niveis.baixo,
    4: staticTextsProfiler.niveis.n_baixo,
    5: staticTextsProfiler.niveis.n_alto,
    6: staticTextsProfiler.niveis.alto,
    7: staticTextsProfiler.niveis.m_alto,
    8: staticTextsProfiler.niveis.e_alto,
  };

  return TextsLevelMap[index] || null;
}

export default getTextLevel;