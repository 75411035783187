/* eslint-disable max-len */
export const EnglishStaticTextsProfiler = {
    interacao_ambiente: {
        titulo: `Interaction with the Environment`,
        texto: `This graph shows the internal profile and the charge from the external environment. The first point of
        each line is the Executor profile, the second point of each line is the Communicator, third the Planner and the
        fourth Analyst. The blue line indicates the inner profile, how the person really is, in his nature, and how he
        acts in familiar environments. The red line indicates how the external environment is charging it to be. Note the
        differences that are being required of behavior change at each point, and the strength of these required changes.
        Strong changes demand energy consumption but also lead to learning. Few changes indicate a good adaptation of the
        person to the functions performed but also few challenges.`,
    },
    estilio_lideranca: {
        titulo: `Leadership Style`,
        texto: `The first point is Dominance, the second is Informality, the third is Condescension and the fourth is
        Formality. Note the points that are above the horizontal center line to check the predominant profiles. Here,
        too, the blue line is the internal profile and the red line is the charge for the external environment. Note the
        intensity of differences between each point between the blue and red lines. Check in the indexes, the level of
        the IEM to see the classification of the intensity of the changes demanded, and the Energy to observe the
        strength for the person to deal with these changes. Current Leadership indicates how the person is currently
        acting in relation to their leadership style. Check the line drawing with what the external environment is
        demanding in the previous graph to check if the person is delivering the demanded model or if it needs
        adjustments in its style or in the demand itself.`,
    },
    situational_Indicator: {
        energia: {
            titulo: `Energy`,
            texto: `Indicates the drive to work, the ability to change and the ability to absorb stress more easily. If
            it is Low, Very Low or Extremely Low, look at the IEM below to check if the demands of the external
            environment are very large, emitting a lot of unnatural abilities from the profile.`,
        },
        nivelestress: {
            titulo: `Environmental Requirement`,
            texto: `Measures how strong the demand from the external environment is. In addition to the index, we can
            see in the graphs the demands of the external environment in a comparative way. We have our internal profile
            and our perception of how we should be in order to have a better performance. second part is the collection
            of the external environment.`,
        },
        ia_nivel: {
            titulo: `Utilization`,
            texto: `Indicates that the person is at a time when he realizes that his skills are being put to good use in
            his activities. Low AI means underutilization, it is an underutilization of his skills, it may be due to a
            very slow workflow, overstaffing in the department, stoppages or very frequent work interruptions.`,
        },
        moral_nivel: {
            titulo: `Moral`,
            texto: `It indicates the person's level of self-approval in terms of their professional and/or personal
            performance. High morals: It means that the person has a good professional self-approval, believes that they
            are moving in the right direction of their development. Morals low: It means that the person feels that many
            changes must occur for their performance to improve.`,
        },
        nivelip: {
            titulo: `Positivity`,
            texto: `Measures the individual's self-esteem. It indicates the level of self-approval in terms of their
            performance, mainly in personal matters. It is the relationship between the positive and negative
            characteristics that the individual sees in himself in terms of self-worth or self-criticism.`,
        },
        flex_nivel: {
            titulo: `Flexibility`,
            texto: `Measures how much a person can change their behavior and how easily they can do so. In addition to
            attitude, it indicates how easily the individual accepts and absorbs new concepts and breaks paradigms, when
            necessary. The IF varies according to with the time when the questionnaire was answered, as it involves, in
            addition to the profile, the emotional and professional state to absorb new concepts and challenges at that
            moment.`,
        },
        amplitude_nivel: {
            titulo: `Amplitude`,
            texto: `It indicates how strong is the importance of the work environment in the professional's productivity.
            A high amplitude means that the environment conducive to this profile increases their productivity. It also
            indicates how much the person impacts their work environment. high amplitude means that the person causes an
            impact (positive and/or negative) in the group where he is inserted.`,
        },
        nivel_energperfil: {
            titulo: `Self Motivation`,
            texto: `It's the natural energy of the person's profile. This indicator translates the energy by the
            behavioral profile, and not by the situation the person is going through. It would be like the energy
            potential that he can deliver in his profile.`,
        },
        nivel_incitab: {
            titulo: `Incitability`,
            texto: `It's the potential to react to stimuli. It's how much a person fires up with a new idea or
            challenge.`,
        },
    },
    competences: {
        tolerancia: {
            titulo: `Tolerance`,
            texto: `Ability to tolerate different ways of thinking, acting and feeling, even if they are opposite to
            those adopted by oneself.`,
        },
        planejamento: {
            titulo: `Planning`,
            texto: `Ability to plan actions, processes and activities.`,
        },
        empatia: {
            titulo: `Empathy`,
            texto: `Ability to understand another person's feeling or reaction by imagining yourself in the same
            circumstances.`,
        },
        capac_ouvir: {
            titulo: `Ability to Listen`,
            texto: `Ability to listen actively, ability to listen carefully.`,
        },
        concentracao: {
            titulo: `Concentration`,
            texto: `Level of ability/need for concentration to perform a job that requires attention and consistency.
            This competency measures not only a person's ability to concentrate but also their need to be in a suitable
            environment for such activity.`,
        },
        condescendencia: {
            titulo: `Condescension`,
            texto: `Indicates how much the person considers and weighs the intentions, desires and opinions of others,
            acting complacently to seek the best possible action.`,
        },
        perfil_tecnico: {
            titulo: `Technical Profile`,
            texto: `Aptitude for technical skills. Indicates the ability of the person to "technify", to give or provide
            technical resources to an activity to optimize it.`,
        },
        organizacao: {
            titulo: `Organization`,
            texto: `It is the ability of an individual to organize their ideas in a clear and well-defined way. This
            competence slips into the physical environment as well as the ability to keep the environment clean and
            tidy.`,
        },
        detalhismo: {
            titulo: `Detailism`,
            texto: `Capacity for meticulous exposition of facts, plans or projects, with attention to detail.`,
        },
        rigorosidade: {
            titulo: `Strictness`,
            texto: `Competence of an exact, precise, demanding person with rigorous reasoning.`,
        },
        orientadorsultado: {
            titulo: `Results Oriented`,
            texto: `It is the competence that identifies how much the person develops through work and action, investing
            more time in the execution of tasks.`,
        },
        multitarefas: {
            titulo: `Multitasking`,
            texto: `Ability to perform multiple tasks at the same time.`,
        },
        automotivacao: {
            titulo: `Self Motivation`,
            texto: `Indicates the level of the person's ability to motivate himself, the ability to motivate himself to
            enthusiasm.`,
        },
        proatividade: {
            titulo: `Proactivity`,
            texto: `Indicates the ability to act in advance, solving future situations and problems without necessarily
            being required.`,
        },
        dinamismo: {
            titulo: `Dynamism`,
            texto: `Particularity and characteristic of those who demonstrate energy, movement and vitality, deal well
            with changes and dynamic environments.`,
        },
        dominancia: {
            titulo: `Dominance`,
            texto: `Competence of those who exercise a dominant, firm posture. Usually makes quick decisions because they
            are less risk averse and assume a commanding posture.`,
        },
        extroversao: {
            titulo: `Extroversion`,
            texto: `Characteristic of someone who is extroverted, outgoing, communicative and sociable. An extroverted
            person is easy to socialize.`,
        },
        relac_interpes: {
            titulo: `Interpersonal relationship`,
            texto: `Ability to establish connections or bonds with other people within a given context.`,
        },
        sociabilidade: {
            titulo: `Sociability`,
            texto: `Indicates the need and tendency to seek social relationships with other people, in an expansive and
            extroverted way.`,
        },
        orientadorelacionamento: {
            titulo: `Relationship Oriented`,
            texto: `The person's level of focus on relationships. This competency indicates how much a person develops
            through relationships and prioritizes them in their tasks.`,
        },
    },
    talentsArea: {
        comandante: {
            titulo: `Commander`,
            texto: `People with the commander profile are enterprising, independent, demanding with themselves and with
            others. They are good initiators of new businesses because they are risk takers. They are great problem
            solvers and in roles that require a strong pulse in the command and leadership of highly condescending team.
            Professions: Lawyer, Command Positions, Leader, Supervisors, Production Managers, Commanders, Heads of
            Construction.`,
        },
        competidor: {
            titulo: `Competitor`,
            texto: `They feed on victories and each day is a dispute for him in which he needs to be victorious. They can
            act as salespeople, Engineers or Teachers. They respect the rules more than salespeople with a high level of
            communication, but specialize in more technical areas, you will feel uncomfortable closing deals. They have a
            pulse and will, and like to compete. Professions: Lawyer, Leader, Jobs motivated by Competition, Reporter,
            Financial Area, High-Secretary, Accountant, Controller.`,
        },
        administrador: {
            titulo: `Administrator`,
            texto: `They have skills in managing systems as well as people. They are good communicators, good marketing
            managers. They are Results-oriented. They are fast, intense and take risks. They have initiative and like to
            solve problems. They like challenges and changes . They are good managers, entrepreneurs and administrators.
            Professions: Administrator, Lead Reporter, Managers, Marketing.`,
        },
        motivador: {
            titulo: `Motivator`,
            texto: `They are Communication in Person, they give life to what they say and they are good speakers,
            motivators and salespeople. They are able to sell the intangible. They have the ability to help people
            develop in their work. They are good personal advisors. Professions: Speaker, Speaker, Public Relations,
            Receptionists, Volunteer Work.`,
        },
        vendedor: {
            titulo: `Seller`,
            texto: `They are people who sell ideas, benefits and prestige. They are skillful politicians. They are
            independent, don't like to follow systems or rules and therefore may not handle details and rules well. They
            are good executing managers and have a sense of urgency. There are people who fit this profile who are born
            managers, but despite having sales skills, they don't like the products exactly as a sales role approach. But
            if anything tangible to this, skill, tangible or intangible products Professions: Commercial, Sales, Sales
            Admin, Personnel Department, Public Relations, Advertising, Speaker, Consultant, Journalist, Manager.`,
        },
        diplomata: {
            titulo: `Diplomat`,
            texto: `They have the ability to resolve conflicts, are good listeners and communicate well. They know how to
            pass on their knowledge and can be Teachers or Instructors. They have sales and negotiation skills.
            Professions: Diplomat, Negotiator, Personnel Department, Advertising, Public Relations , Attendant,
            Publicist, Volunteer Work, Psychologist.`,
        },
        aconselhador: {
            titulo: `Counselor`,
            texto: `Good listeners and social interests. Psychologists, Counselors, Articulators, Harmonizers and
            Peacemakers usually have this profile. They are pleasant and work well in a team. They are more comfortable
            in environments that are more familiar to them and need a little structure. They are friendly and have great
            ability for social services. They like to be with people, guide them and help them. They can perform
            Teaching, Diplomacy and Bureaucratic work. Professions: Social Worker, Attendant, Receptionist, Clerk,
            Doctor, Nurse, Psychologist, Counselor.`,
        },
        atendente: {
            titulo: `Attendant`,
            texto: `Also known as Protectors, they like to work with positive relationships and a team where members help
            each other out. Professions: Attendance Jobs, Bureaucratic Services.`,
        },
        professoral: {
            titulo: `Professorial`,
            texto: `They are good Teachers and Instructors. They work well with Technical Support or other functions that
            demand technical knowledge and transmission of knowledge. They are understandable managers. Professions:
            Technical Support, Teacher, Manager, Attendant, Receptionist, Operator, Secretariat, Pedagogue.`,
        },
        tecnico: {
            titulo: `Technician`,
            texto: `They are self-taught, they can accomplish everything that comes to hand to do. They are specialists,
            recovering apparently lost work. They don't like change and need a lot of structure. They are good Computer
            Systems Analysts, Developers and System Designers. They handle numbers, tables and graphs well. If your first
            predominant factor is Planner, you lose a lot of your initiative and take a more operational and
            condescending stance. Professions: Analysts (systems, credit, etc.), Computing and Informatics, Software
            Developers, Statistics, Mathematics, Engineering.`,
        },
        especialista: {
            titulo: `Specialist`,
            texto: `They control the work according to the rules. They are good secretaries, mechanics, painters and
            electricians and computer operators. They do all the accounting and office work well. Good operators in
            machines and in the assembly of electronics. bank and supermarket can have this profile. They need rules and
            training to perform their tasks and do them very well. Professions: Cashier, Financial Area, Treasurer,
            Architect, Graphic Designer, Artist, Musician, Writer.`,
        },
        estrategista: {
            titulo: `Strategist`,
            texto: `They are inventors and organizers, they do good technical and analytical work. They are not good
            communicators. They are good with fiscal, accounting and statistical work. They are skilled in systematized
            work. Professions: Accountant, Inventor, Architect, Graphic Designer, Artist, Musician, Writer.`,
        },
        controlador: {
            titulo: `Controller`,
            texto: `They are fast and efficient; demanding with themselves and with others. They have a high standard of
            performance and are disciplined. They are reserved when communicating. They are good Project and Job Managers
            that require fast and quality execution. Professions: Managers, Supervisors, Executors.`,
        },
    },
    tipos: {
        masculino: {
            A: `Analyst`,
            E: `Executor`,
            C: `Communicator`,
            P: `Planner`,
        },
        feminino: {
            A: `Analyst`,
            E: `Executor`,
            C: `Communicator`,
            P: `Planner`,
        }
    },
    niveis: {
        alto: `High`,
        baixo: `Low`,
        e_alto: `Extremely High`,
        e_baixo: `Extremely Low`,
        m_alto: `Very High`,
        m_baixo: `Very Low`,
        n_alto: `Normal High`,
        n_baixo: `Normal Low`,
    },
    complementares: {
        tempo_resposta: `Response Time`,
        area_talentos: `Talent Area`,
        minuto: `minute`,
        minutos: `minutes`,
        segundos: `seconds`,
        neste_momento: `At this moment, %{nome} is:`,
        em: `in`,
        distribuicao_fatores: `This is the distribution of <b>positive</b> or <b>negative</b> factors per profile.`,
        indicadores_situacionais: `Situational Indicators`,
        perfil_atual: `Current Profile`,
        adaptabilidade: `Adaptability`,
        ambiente: `Environment`,
        competencias: `Skills`,
        dominante: `Dominant`,
        informal: `Informal`,
        condescendente: `Condescending`,
        formal: `Formal`,
    },
};
