import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "react-datepicker/dist/react-datepicker.css";

import "./assets/tooltip.scss";
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
