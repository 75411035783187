import React from "react";
import { getInitials } from "../../../../utils/functions/getInitials";
import { backgroundColorByProfiler } from "../../../../utils/functions/backgroundColorByProfiler";
import * as Styled from "./styles";

export default function MatchNegativePositive({
  primary = {
    name: "solides",
    img: "",
    positive: {
      executor: "30%",
      planejador: "30%",
      comunicador: "20%",
      analista: "20%",
    },
    negative: {
      executor: "30%",
      planejador: "30%",
      comunicador: "20%",
      analista: "20%",
    },
  },
  secondary = {
    name: "solides",
    img: "",
    positive: {
      executor: "30%",
      planejador: "30%",
      comunicador: "20%",
      analista: "20%",
    },
    negative: {
      executor: "30%",
      planejador: "30%",
      comunicador: "20%",
      analista: "20%",
    },
  },
}) {
  const execPositivePrimary = primary.positive.executor.split("%");
  const execNegativePrimary = primary.negative.executor.split("%");

  const comuniPositivePrimary = primary.positive.comunicador.split("%");
  const comuniNegativePrimary = primary.negative.comunicador.split("%");

  const planePositivePrimary = primary.positive.planejador.split("%");
  const planeNegativePrimary = primary.negative.planejador.split("%");

  const analistPositivePrimary = primary.positive.analista.split("%");
  const analistNegativePrimary = primary.negative.analista.split("%");

  const execPositiveSecondary = secondary.positive.executor.split("%");
  const execNegativeSecondary = secondary.negative.executor.split("%");

  const comuniPositiveSecondary = secondary.positive.comunicador.split("%");
  const comuniNegativeSecondary = secondary.negative.comunicador.split("%");

  const planePositiveSecondary = secondary.positive.planejador.split("%");
  const planeNegativeSecondary = secondary.negative.planejador.split("%");

  const analistPositiveSecondary = secondary.positive.analista.split("%");
  const analistNegativeSecondary = secondary.negative.analista.split("%");

  return (
    <Styled.MatchNegativePositiveContent>
      <Styled.FirstContent>
        <Styled.Line />
        <Styled.ImgContent>
          {primary.img ? (
            <Styled.MatchImage data={primary.img} />
          ) : (
            <Styled.MatchImage
              backgroundColor={backgroundColorByProfiler(primary.profiler)}
            >
              <Styled.TextImagePerson name={primary.name}>
                {getInitials(primary.name)}
              </Styled.TextImagePerson>
            </Styled.MatchImage>
          )}
        </Styled.ImgContent>
        <Styled.ChartContent>
          <Styled.Item>
            <Styled.PositiveContainer>
              <Styled.Positive executor percentage={execPositivePrimary[0]} />
            </Styled.PositiveContainer>

            <Styled.NegativeContainer>
              <Styled.Negative executor percentage={execNegativePrimary[0]} />
            </Styled.NegativeContainer>
            <Styled.Info />
            <Styled.Text>
              <Styled.InfoPositive executor>
                +{primary.positive.executor}
              </Styled.InfoPositive>
              <Styled.InfoNegative executor>
                -{primary.negative.executor}
              </Styled.InfoNegative>
            </Styled.Text>
          </Styled.Item>
          <Styled.Item>
            <Styled.PositiveContainer>
              <Styled.Positive
                comunicador
                percentage={comuniPositivePrimary[0]}
              />
            </Styled.PositiveContainer>
            <Styled.NegativeContainer>
              <Styled.Negative
                comunicador
                percentage={comuniNegativePrimary[0]}
              />
            </Styled.NegativeContainer>
            <Styled.Info />
            <Styled.Text>
              <Styled.InfoPositive comunicador>
                +{primary.positive.comunicador}
              </Styled.InfoPositive>
              <Styled.InfoNegative comunicador>
                -{primary.negative.comunicador}
              </Styled.InfoNegative>
            </Styled.Text>
          </Styled.Item>
          <Styled.Item>
            <Styled.PositiveContainer>
              <Styled.Positive
                planejador
                percentage={planePositivePrimary[0]}
              />
            </Styled.PositiveContainer>
            <Styled.NegativeContainer>
              <Styled.Negative
                planejador
                percentage={planeNegativePrimary[0]}
              />
            </Styled.NegativeContainer>
            <Styled.Info />
            <Styled.Text>
              <Styled.InfoPositive planejador>
                +{primary.positive.planejador}
              </Styled.InfoPositive>
              <Styled.InfoNegative planejador>
                -{primary.negative.planejador}
              </Styled.InfoNegative>
            </Styled.Text>
          </Styled.Item>
          <Styled.Item>
            <Styled.PositiveContainer>
              <Styled.Positive
                analista
                percentage={analistPositivePrimary[0]}
              />
            </Styled.PositiveContainer>
            <Styled.NegativeContainer>
              <Styled.Negative
                analista
                percentage={analistNegativePrimary[0]}
              />
            </Styled.NegativeContainer>
            <Styled.Info />
            <Styled.Text>
              <Styled.InfoPositive analista>
                {primary.positive.analista}
              </Styled.InfoPositive>
              <Styled.InfoNegative analista>
                -{primary.negative.analista}
              </Styled.InfoNegative>
            </Styled.Text>
          </Styled.Item>
        </Styled.ChartContent>
      </Styled.FirstContent>
      <Styled.SecondContent>
        <Styled.Line />
        <Styled.ImgContent>
          {secondary.img ? (
            <Styled.MatchImage data={secondary.img} />
          ) : (
            <Styled.MatchImage
              backgroundColor={backgroundColorByProfiler(secondary.profiler)}
            >
              <Styled.TextImagePerson>
                {getInitials(secondary.name)}
              </Styled.TextImagePerson>
            </Styled.MatchImage>
          )}
        </Styled.ImgContent>
        <Styled.ChartContent>
          <Styled.Item>
            <Styled.PositiveContainer>
              <Styled.Positive executor percentage={execPositiveSecondary[0]} />
            </Styled.PositiveContainer>
            <Styled.NegativeContainer>
              <Styled.Negative executor percentage={execNegativeSecondary[0]} />
            </Styled.NegativeContainer>
            <Styled.Info />
            <Styled.Text>
              <Styled.InfoPositive executor>
                +{secondary.positive.executor}
              </Styled.InfoPositive>
              <Styled.InfoNegative executor>
                -{secondary.negative.executor}
              </Styled.InfoNegative>
            </Styled.Text>
          </Styled.Item>
          <Styled.Item>
            <Styled.PositiveContainer>
              <Styled.Positive
                comunicador
                percentage={comuniPositiveSecondary[0]}
              />
            </Styled.PositiveContainer>
            <Styled.NegativeContainer>
              <Styled.Negative
                comunicador
                percentage={comuniNegativeSecondary[0]}
              />
            </Styled.NegativeContainer>
            <Styled.Info />
            <Styled.Text>
              <Styled.InfoPositive comunicador>
                +{secondary.positive.comunicador}
              </Styled.InfoPositive>
              <Styled.InfoNegative comunicador>
                -{secondary.negative.comunicador}
              </Styled.InfoNegative>
            </Styled.Text>
          </Styled.Item>
          <Styled.Item>
            <Styled.PositiveContainer>
              <Styled.Positive
                planejador
                percentage={planePositiveSecondary[0]}
              />
            </Styled.PositiveContainer>
            <Styled.NegativeContainer>
              <Styled.Negative
                planejador
                percentage={planeNegativeSecondary[0]}
              />
            </Styled.NegativeContainer>
            <Styled.Info />
            <Styled.Text>
              <Styled.InfoPositive planejador>
                +{secondary.positive.planejador}
              </Styled.InfoPositive>
              <Styled.InfoNegative planejador>
                -{secondary.negative.planejador}
              </Styled.InfoNegative>
            </Styled.Text>
          </Styled.Item>
          <Styled.Item>
            <Styled.PositiveContainer>
              <Styled.Positive
                analista
                percentage={analistPositiveSecondary[0]}
              />
            </Styled.PositiveContainer>
            <Styled.NegativeContainer>
              <Styled.Negative
                analista
                percentage={analistNegativeSecondary[0]}
              />
            </Styled.NegativeContainer>
            <Styled.Info />
            <Styled.Text>
              <Styled.InfoPositive analista>
                +{secondary.positive.analista}
              </Styled.InfoPositive>
              <Styled.InfoNegative analista>
                -{secondary.negative.analista}
              </Styled.InfoNegative>
            </Styled.Text>
          </Styled.Item>
        </Styled.ChartContent>
      </Styled.SecondContent>
    </Styled.MatchNegativePositiveContent>
  );
}
