import getStaticTextsProfiler from "./getStaticTextsProfiler";

export default function getProfilerName(profiler, gender = "not_binary") {
  if (profiler === undefined || gender === undefined) return "";

  gender = gender === null ? "not_binary" : gender;

  const staticTextsProfiler = getStaticTextsProfiler();

  const _profilers = {
    not_binary: staticTextsProfiler.tipos.masculino,
    male: staticTextsProfiler.tipos.masculino,
    female: staticTextsProfiler.tipos.feminino,
  };
  try {
    const profilersName = [...profiler].map((letterProfiler) => {
      return _profilers[gender][letterProfiler];
    });
    return profilersName.join(" ");
  } catch (err) {
  }

  return "";
}
