import React from "react";
import ChartSvg from "./chartSvg";
import GridChartSvg from "./gridChartSvg";
import * as Styled from "./style";
import { theme } from "../../../../styles/theme";
import getStaticTextsProfiler from "../../../../utils/functions/getStaticTextsProfiler";

const ChartInteraction = ({
  mediumRequirement,
  searchResults,
  pdfTemplate,
  pdfLeader,
}) => {
  const staticTextsProfiler = getStaticTextsProfiler();
  const valueIntial = 100;
  const path =
    "M4.60557 0.682257L0.723607 8.44619C0.391156 9.11109 0.874652 9.8934 1.61803 9.8934H9.38197C10.1253 9.8934";
  return (
    <Styled.ContainerMain>
      <Styled.TooltipText id="tooltip" />
      {mediumRequirement && (
        <Styled.ChartLeft>
          {!pdfTemplate ? (
            <Styled.Title>
              {staticTextsProfiler.interacao_ambiente.titulo}
            </Styled.Title>
          ) : (
            <>
              <br />
              <br />
            </>
          )}

          <GridChartSvg
            textTitle={[
              staticTextsProfiler.tipos.masculino.E,
              staticTextsProfiler.tipos.masculino.C,
              staticTextsProfiler.tipos.masculino.A,
              staticTextsProfiler.tipos.masculino.P
            ]}
          >
            <ChartSvg
              options={mediumRequirement.meio}
              type="meio"
              valueIntial={valueIntial}
              color={theme.colors.executor}
            />
            <ChartSvg
              options={mediumRequirement.voce}
              type="voce"
              valueIntial={valueIntial}
              color="#2F0A77ed"
            />
            <ChartSvg
              options={mediumRequirement.flexibilidade}
              type="flexibilidade"
              valueIntial={valueIntial}
              color="#333333"
            />
          </GridChartSvg>
        </Styled.ChartLeft>
      )}
      {!pdfLeader && (
        <Styled.Middle>
          <Styled.Legend>
            <svg
              width="12"
              height="12"
              version="1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="6"
                cy="6"
                r="5.5"
                stroke="black"
                strokeWidth="0"
                fill="#2F0A77ed"
              />
            </svg>
            <Styled.TextLegend>
              {staticTextsProfiler.complementares.perfil_atual.toUpperCase()}
            </Styled.TextLegend>
          </Styled.Legend>
          <Styled.Legend>
            <svg
              width="11"
              height="11"
              version="1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="11"
                height="11"
                rx="4"
                stroke={theme.colors.executor}
                fill={theme.colors.executor}
                strokeWidth="1"
              />
            </svg>
            <Styled.TextLegend>
              {staticTextsProfiler.complementares.ambiente.toUpperCase()}
            </Styled.TextLegend>
          </Styled.Legend>
          <Styled.Legend>
            <svg
              width="11"
              height="11"
              version="1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d={`${path} 10.6088 9.11109 10.2764 8.44619L6.39443 0.682256C6.0259 -0.0547922 4.9741 -0.0547915 4.60557 0.682257Z`}
                fill="#333333"
              />
            </svg>
            <Styled.TextLegend>
              {staticTextsProfiler.complementares.adaptabilidade.toUpperCase()}
            </Styled.TextLegend>
          </Styled.Legend>
        </Styled.Middle>
      )}
      {searchResults && (
        <Styled.ChartRight>
          {!pdfTemplate ? (
            <Styled.Title>
              {staticTextsProfiler.estilio_lideranca.titulo}
            </Styled.Title>
          ) : (
            <>
              <br />
              <br />
            </>
          )}
          <GridChartSvg
            textTitle={[
              staticTextsProfiler.complementares.dominante,
              staticTextsProfiler.complementares.informal,
              staticTextsProfiler.complementares.formal,
              staticTextsProfiler.complementares.condescendente
            ]}
          >
            <ChartSvg
              options={searchResults.meio}
              type="meio"
              valueIntial={valueIntial}
              color={theme.colors.executor}
              searchResults={true}
            />
            <ChartSvg
              options={searchResults.voce}
              type="voce"
              valueIntial={valueIntial}
              color="#2F0A77ed"
              searchResults={true}
            />
            <ChartSvg
              options={searchResults.flexibilidade}
              type="flexibilidade"
              valueIntial={valueIntial}
              color="#333333"
              searchResults={true}
            />
          </GridChartSvg>
        </Styled.ChartRight>
      )}
      {pdfLeader && (
        <Styled.Middle>
          <Styled.Legend>
            <svg
              width="12"
              height="12"
              version="1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="6"
                cy="6"
                r="5.5"
                stroke="black"
                strokeWidth="0"
                fill="#2F0A77ed"
              />
            </svg>
            <Styled.TextLegend>PERFIL ATUAL</Styled.TextLegend>
          </Styled.Legend>
          <Styled.Legend>
            <svg
              width="11"
              height="11"
              version="1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="11"
                height="11"
                rx="4"
                stroke={theme.colors.executor}
                fill={theme.colors.executor}
                strokeWidth="1"
              />
            </svg>
            <Styled.TextLegend>AMBIENTE</Styled.TextLegend>
          </Styled.Legend>
          <Styled.Legend>
            <svg
              width="11"
              height="11"
              version="1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d={`${path} 10.6088 9.11109 10.2764 8.44619L6.39443 0.682256C6.0259 -0.0547922 4.9741 -0.0547915 4.60557 0.682257Z`}
                fill="#333333"
              />
            </svg>
            <Styled.TextLegend>ADAPTABILIDADE</Styled.TextLegend>
          </Styled.Legend>
        </Styled.Middle>
      )}
    </Styled.ContainerMain>
  );
};

export default ChartInteraction;
