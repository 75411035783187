import React from "react";
import * as Styled from "./styles";

export default function data({
    data = {
        positive: {
            executor: "30%",
            planejador: "30%",
            comunicador: "20%",
            analista: "20%",
        },
        negative: {
            executor: "30%",
            planejador: "30%",
            comunicador: "20%",
            analista: "20%",
        },
    },
}) {
    const execPositiveData = data.positive.executor.split("%");
    const execNegativeData = data.negative.executor.split("%");

    const comuniPositiveData = data.positive.comunicador.split("%");
    const comuniNegativeData = data.negative.comunicador.split("%");

    const planePositiveData = data.positive.planejador.split("%");
    const planeNegativeData = data.negative.planejador.split("%");

    const analistPositiveData = data.positive.analista.split("%");
    const analistNegativeData = data.negative.analista.split("%");

    return (
        <Styled.PositiveNegative>
            <Styled.FirstContent>
                <Styled.Line />
                <Styled.ChartContent>
                    <Styled.Item>
                        <Styled.PositiveContainer>
                            <Styled.Positive
                                executor
                                percentage={execPositiveData[0]}
                            />
                        </Styled.PositiveContainer>

                        <Styled.NegativeContainer>
                            <Styled.Negative
                                executor
                                percentage={execNegativeData[0]}
                            />
                        </Styled.NegativeContainer>
                        <Styled.Info />
                        <Styled.Text>
                            <Styled.InfoPositive executor>
                                +{data.positive.executor}
                            </Styled.InfoPositive>
                            <Styled.InfoNegative executor>
                                -{data.negative.executor}
                            </Styled.InfoNegative>
                        </Styled.Text>
                    </Styled.Item>
                    <Styled.Item>
                        <Styled.PositiveContainer>
                            <Styled.Positive
                                comunicador
                                percentage={comuniPositiveData[0]}
                            />
                        </Styled.PositiveContainer>
                        <Styled.NegativeContainer>
                            <Styled.Negative
                                comunicador
                                percentage={comuniNegativeData[0]}
                            />
                        </Styled.NegativeContainer>
                        <Styled.Info />
                        <Styled.Text>
                            <Styled.InfoPositive comunicador>
                                +{data.positive.comunicador}
                            </Styled.InfoPositive>
                            <Styled.InfoNegative comunicador>
                                -{data.negative.comunicador}
                            </Styled.InfoNegative>
                        </Styled.Text>
                    </Styled.Item>
                    <Styled.Item>
                        <Styled.PositiveContainer>
                            <Styled.Positive
                                planejador
                                percentage={planePositiveData[0]}
                            />
                        </Styled.PositiveContainer>
                        <Styled.NegativeContainer>
                            <Styled.Negative
                                planejador
                                percentage={planeNegativeData[0]}
                            />
                        </Styled.NegativeContainer>
                        <Styled.Info />
                        <Styled.Text>
                            <Styled.InfoPositive planejador>
                                +{data.positive.planejador}
                            </Styled.InfoPositive>
                            <Styled.InfoNegative planejador>
                                -{data.negative.planejador}
                            </Styled.InfoNegative>
                        </Styled.Text>
                    </Styled.Item>
                    <Styled.Item>
                        <Styled.PositiveContainer>
                            <Styled.Positive
                                analista
                                percentage={analistPositiveData[0]}
                            />
                        </Styled.PositiveContainer>
                        <Styled.NegativeContainer>
                            <Styled.Negative
                                analista
                                percentage={analistNegativeData[0]}
                            />
                        </Styled.NegativeContainer>
                        <Styled.Info />
                        <Styled.Text>
                            <Styled.InfoPositive analista>
                                +{data.positive.analista}
                            </Styled.InfoPositive>
                            <Styled.InfoNegative analista>
                                -{data.negative.analista}
                            </Styled.InfoNegative>
                        </Styled.Text>
                    </Styled.Item>
                </Styled.ChartContent>
            </Styled.FirstContent>
        </Styled.PositiveNegative>
    );
}
