import React from "react";
import { theme } from "../../../../styles/theme";
import getStaticTextsProfiler from "../../../../utils/functions/getStaticTextsProfiler";

const GridChartSvg = ({ textTitle, children }) => {
  const staticTextsProfiler = getStaticTextsProfiler();
  const widthGrid = 200;
  const heightGrid = 200;
  return (
    <svg
      width="290"
      height="300"
      viewBox="0 -6 210 300"
      fill="#ffffff"
      version="1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={widthGrid}
        height={heightGrid}
        rx="9.5"
        stroke="#F4F0EB"
        strokeWidth="3"
      />
      <g>
        <line
          x1="0"
          y1="50"
          x2="200"
          y2="50"
          strokeWidth="2"
          stroke="#F4F0EB"
        />
        <line
          x1="0"
          y1="100"
          x2="200"
          y2="100"
          strokeWidth="2"
          stroke="#F4F0EB"
        />
        <line
          x1="0"
          y1="150"
          x2="200"
          y2="150"
          strokeWidth="2"
          stroke="#F4F0EB"
        />
      </g>
      <g>
        <line
          x1={widthGrid / 3}
          y1="200"
          x2={widthGrid / 3}
          y2="0"
          strokeWidth="2"
          stroke="#F4F0EB"
        />
        <line
          x1={(widthGrid / 3) * 2}
          y1="200"
          x2={(widthGrid / 3) * 2}
          y2="0"
          strokeWidth="2"
          stroke="#F4F0EB"
        />
      </g>

      {children}
      <text
        x={textTitle[0] === staticTextsProfiler.tipos.masculino.E ? "-15" : "-25"}
        y="230"
        style={{ fontFamily: "Roboto Condensed", lineHeight: "12px" }}
        fontSize="11"
        fill={textTitle[0] === staticTextsProfiler.tipos.masculino.E ? theme.colors.executor : " #8872B2 "}
      >
        {textTitle[0]}
      </text>
      <text
        x={textTitle[1] === staticTextsProfiler.tipos.masculino.C ? "40.666" : "44"}
        y="230"
        style={{ fontFamily: "Roboto Condensed", lineHeight: "12px" }}
        fontSize="11"
        fill={
          textTitle[1] === staticTextsProfiler.tipos.masculino.C
            ? theme.colors.communicator
            : " #8872B2"
        }
      >
        {textTitle[1]}
      </text>
      <text
        x={textTitle[3] === staticTextsProfiler.tipos.masculino.P ? "118" : "100"}
        y="230"
        style={{ fontFamily: "Roboto Condensed", lineHeight: "12px" }}
        fontSize="11"
        fill={textTitle[3] === staticTextsProfiler.tipos.masculino.P ? theme.colors.planner : " #8872B2"}
      >
        {textTitle[3]}
      </text>
      <text
        x={textTitle[2] === staticTextsProfiler.tipos.masculino.A ? "180" : "190"}
        y="230"
        style={{ fontFamily: "Roboto Condensed", lineHeight: "12px" }}
        fontSize="11"
        fill={textTitle[2] === staticTextsProfiler.tipos.masculino.A ? theme.colors.analyst : " #8872B2"}
      >
        {textTitle[2]}
      </text>
    </svg>
  );
};
export default GridChartSvg;
