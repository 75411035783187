import React, { useState, useEffect } from "react";
import * as Styled from "./styles";
import { getInitials } from "../../../../utils/functions/getInitials";
import { backgroundColorByProfiler } from "../../../../utils/functions/backgroundColorByProfiler";
import Chart from "../../index";

const MatchTalentsArea = ({
    primary = {
        name: "solides",
        profiler: "A",
        dx1: 0,
        dy1: 0,
        dx2: 0,
        dy2: 0,
        img:
            "https://avatars.slack-edge.com/2019-10-23/808278080631_36f0e314dd957949aa50_72.jpg",
    },
    secondary = {
        solides: "solides",
        profiler: "E",
        dx1: 0,
        dy1: 0,
        dx2: 0,
        dy2: 0,
        img:
            "https://avatars.slack-edge.com/2019-10-23/808278080631_36f0e314dd957949aa50_72.jpg",
    },
}) => {
    const [first, setFirst] = useState([]);
    const [second, setSecond] = useState([]);

    useEffect(() => {}, [primary, secondary]);

    const _renderTexts = arr => {
        // eslint-disable-next-line array-callback-return
        return arr.map((e, i) => {
            switch (e.number) {
                case 1:
                    return (
                        <Styled.List key={i}>
                            <Styled.TitleList>Comandante</Styled.TitleList>
                            <Styled.TitleText>
                                Pessoas com o perfil de comandante são
                                empreendedoras, independentes, exigentes consigo
                                mesmas e com os outros.São bons iniciadores de
                                novos negócios por serem assumidores de risco.
                                São ótimos resolvedores de problemas e em
                                funções que exijamum pulso forte no comando e
                                liderança de equipe altamente condescendente.
                                Profissões: Advogado, Cargos de Comando,
                                Líder,Supervisores, Gerentes de Produção,
                                Comandantes, Chefes de Construção.
                            </Styled.TitleText>
                        </Styled.List>
                    );
                case 2:
                    return (
                        <Styled.List key={i}>
                            <Styled.TitleList>Competidor</Styled.TitleList>
                            <Styled.TitleText>
                                Se alimentam de vitórias e cada dia é uma
                                disputa para ele na qual ele precisa se sair
                                vitorioso. Podem atuar comoVendedores,
                                Engenheiros ou Professores. Respeitam mais as
                                regras que os vendedores com alto índice de
                                comunicação, mas seespecializar em áreas mais
                                técnicas, se sentirá pouco à vontade para
                                fechamento de negócios. Têm pulso e garra, e
                                gostam de competir.Profissões: Advogado, Líder,
                                Trabalhos motivados por Competição, Repórter,
                                Área Financeira, Alto-Secretário, Contabilista,
                                Controller.
                            </Styled.TitleText>
                        </Styled.List>
                    );
                case 3:
                    return (
                        <Styled.List key={i}>
                            <Styled.TitleList>Administrador</Styled.TitleList>
                            <Styled.TitleText>
                                Têm habilidades em gerenciar sistemas e também
                                pessoas. São bons comunicadores, bons gerentes
                                de marketing.São orientados para Resultados. São
                                rápidos, intensos e assumem riscos. Têm
                                iniciativa e gostam de resolver problemas.
                                Gostam dedesafios e de mudanças. São bons
                                gerentes, empreendedores e administradores.
                                Profissões: Administrador, Repórter Líder,
                                Gerentes,Marketing.
                            </Styled.TitleText>
                        </Styled.List>
                    );
                case 4:
                    return (
                        <Styled.List key={i}>
                            <Styled.TitleList>Motivador</Styled.TitleList>
                            <Styled.TitleText>
                                São a Comunicação em Pessoa, dão vida ao que
                                falam e são bons palestrantes, motivadores e
                                vendedores. São capazesde vender o intangível.
                                Têm habilidade para ajudar as pessoas a se
                                desenvolverem em seu trabalho. São bons
                                conselheiros pessoais.Profissões: Palestrante,
                                Orador, Relações Públicas, Recepcionistas,
                                Trabalhos Voluntários.
                            </Styled.TitleText>
                        </Styled.List>
                    );
                case 5:
                    return (
                        <Styled.List key={i}>
                            <Styled.TitleList>Vendedor</Styled.TitleList>
                            <Styled.TitleText>
                                São pessoas Vendem idéias, benefícios e
                                prestígio. São políticos habilidosos. São
                                independentes, não gostam de seguirsistemas ou
                                regras e, por isso, podem não lidar bem com
                                detalhes e regras. São bons gerentes executores
                                e têm senso de urgência. Hápessoas que se
                                enquadram neste perfil que são Gerentes natos,
                                mas apesar de ter as habilidades de vendas, não
                                gostam da função exatade abordagem de vendas de
                                produtos. Mas se voltados à esta habilidade,
                                vendem qualquer coisa, produtos tangíveis ou
                                intangíveis.Profissões: Comercial, Vendas, Adm
                                Vendas, Depto Pessoal, Relações Públicas,
                                Publicidade, Palestrante, Consultor, Jornalista,
                                Gerente..6
                            </Styled.TitleText>
                        </Styled.List>
                    );

                case 6:
                    return (
                        <Styled.List key={i}>
                            <Styled.TitleList>Diplomata</Styled.TitleList>
                            <Styled.TitleText>
                                Têm habilidade de solucionar conflitos, são bons
                                ouvintes e se comunicam bem. Sabem passar seus
                                conhecimentos epodem ser Professores ou
                                Instrutores. Têm habilidades para vendas e
                                negociações. Profissões: Diplomata, Negociador,
                                Depto Pessoal,Publicidade, Relações Públicas,
                                Atendente, Publicitário, Trabalho Voluntário,
                                Psicólogo.
                            </Styled.TitleText>
                        </Styled.List>
                    );
                case 7:
                    return (
                        <Styled.List key={i}>
                            <Styled.TitleList>Aconselhador</Styled.TitleList>
                            <Styled.TitleText>
                                Bons ouvintes e voltados a interesses sociais.
                                Psicólogos, Conselheiros, Articuladores,
                                Harmonizadores ePacificadores geralmente têm
                                este perfil. São agradáveis e trabalham bem em
                                equipe. Ficam mais a vontade em meios que lhe
                                são maisfamiliares e precisam de um pouco de
                                estrutura. São amistosos e com grande habilidade
                                para serviços sociais. Gostam de estar com
                                aspessoas, orientá-las e ajudá-las. Podem
                                desempenhar trabalhos Professorais, de
                                Diplomacia e trabalhos Burocráticos.
                                Profissões:Assistente Social, Atendente,
                                Recepcionista, Balconista, Médico, Enfermeiro,
                                Psicólogo, Conselheiro.
                            </Styled.TitleText>
                        </Styled.List>
                    );
                case 8:
                    return (
                        <Styled.List key={i}>
                            <Styled.TitleList>Atendente</Styled.TitleList>
                            <Styled.TitleText>
                                Também conhecidos como Protetores, gostam de
                                trabalhar com relacionamentos positivos e uma
                                equipe onde osmembros se ajudam mutuamente.
                                Profissões: Trabalhos de Atendimento, Serviços
                                Burocráticos.
                            </Styled.TitleText>
                        </Styled.List>
                    );
                case 9:
                    return (
                        <Styled.List key={i}>
                            <Styled.TitleList>Professoral</Styled.TitleList>
                            <Styled.TitleText>
                                São bons Professores e Instrutores. Trabalham
                                bem com Suporte Técnico ou outras funções que
                                demandamconhecimentos técnicos e transmissão de
                                conhecimento. São gerentes compreensíveis.
                                Profissões: Suporte Técnico, Professor,
                                Gerente,Atendente, Recepcionista, Telefonista,
                                Secretariado, Pedagogo.
                            </Styled.TitleText>
                        </Styled.List>
                    );
                case 10:
                    return (
                        <Styled.List key={i}>
                            <Styled.TitleList>
                                Técnico/Analista
                            </Styled.TitleList>
                            <Styled.TitleText>
                                São auto didatas, conseguem realizar tudo o que
                                vem à mão para fazer. São especialistas,
                                recuperam trabalhosaparentemente perdidos. Não
                                gostam de mudanças e precisam de muita
                                estrutura. São bons Analistas de Sistemas
                                Computacionais,Desenvolvedores e criadores de
                                sistemas. Lidam bem com números, tabelas e
                                gráficos. Se seu primeiro fator predominante for
                                Planejador,perde bastante de sua iniciativa e
                                toma postura mais operacional e condescendente.
                                Profissões: Analistas (de sistemas, crédito,
                                etc), Áreade Computação e Informática,
                                Desenvolvedores de Softwares, Estatística,
                                Matemática, Engenharia.
                            </Styled.TitleText>
                        </Styled.List>
                    );
                case 11:
                    return (
                        <Styled.List key={i}>
                            <Styled.TitleList>Especialista</Styled.TitleList>
                            <Styled.TitleText>
                                Controlam o trabalho de acordo com as regras.
                                São bons secretários, mecânicos, pintores e
                                eletricistas e operadoresde computador. Realizam
                                bem todo o serviço de contabilidade e
                                escritório. Bons operadores de máquinas e na
                                montagem de eletrônicos.Caixas de banco e de
                                supermercado podem possuir esse perfil. Precisam
                                de regras e treinamento para executarem suas
                                tarefas e as fazmuito bem feito. Profissões:
                                Operador de Caixa, Área Financeira, Tesoureiro,
                                Arquiteto, Designer Gráfico, Artista, Musico,
                                Escritor.
                            </Styled.TitleText>
                        </Styled.List>
                    );
                case 12:
                    return (
                        <Styled.List key={i}>
                            <Styled.TitleList>Estrategista</Styled.TitleList>
                            <Styled.TitleText>
                                São inventores e organizadores, executam bons
                                trabalhos técnicos e analíticos. Não são bons
                                comunicadores. Sãobons com problemas fiscais,
                                contábeis e com trabalhos estatísticos. Possuem
                                habilidade em trabalhos sistematizados.
                                Profissões:Contabilista, Inventor, Arquiteto,
                                Designer Gráfico, Artista, Musico, Escritor.
                            </Styled.TitleText>
                        </Styled.List>
                    );
                case 13:
                    return (
                        <Styled.List key={i}>
                            <Styled.TitleList>Controlador</Styled.TitleList>
                            <Styled.TitleText>
                                São rápidos e eficientes; exigentes consigo
                                mesmo e com os outros. Têm alto padrão de
                                desempenho e sãodisciplinados. São reservados ao
                                se comunicarem. São bons Gerentes de Projetos e
                                de trabalhos que exijam a execução com rapidez
                                equalidade. Profissões: Gerentes, Supervisores,
                                Executores.
                            </Styled.TitleText>
                        </Styled.List>
                    );
                default:
                    break;
            }
        });
    };

    return (
        <Styled.MatchTalentsArea>
            <Styled.LeftContainer>
                <Styled.ImgContent>
                    {primary.img ? (
                        <Styled.MatchImage data={primary.img} />
                    ) : (
                        <Styled.MatchImage
                            backgroundColor={backgroundColorByProfiler(
                                primary.profiler
                            )}
                        >
                            <Styled.TextImagePerson>
                                {getInitials(primary.name)}
                            </Styled.TextImagePerson>
                        </Styled.MatchImage>
                    )}
                </Styled.ImgContent>
                <Chart
                    type="talentsArea"
                    talentsArea={{
                        ...primary,
                        showTable: false,
                        showList: false,
                        listNumbers: e => {
                            setFirst(e);
                        },
                    }}
                />
                {_renderTexts(first)}
            </Styled.LeftContainer>
            <Styled.RightContainer>
                <Styled.ImgContent>
                    {secondary.img ? (
                        <Styled.MatchImage data={secondary.img} />
                    ) : (
                        <Styled.MatchImage
                            backgroundColor={backgroundColorByProfiler(
                                secondary.profiler
                            )}
                        >
                            <Styled.TextImagePerson>
                                {getInitials(secondary.name)}
                            </Styled.TextImagePerson>
                        </Styled.MatchImage>
                    )}
                </Styled.ImgContent>
                <Chart
                    type="talentsArea"
                    talentsArea={{
                        ...secondary,
                        showTable: false,
                        showList: false,
                        listNumbers: e => {
                            setSecond(e);
                        },
                    }}
                />
                {_renderTexts(second)}
            </Styled.RightContainer>
        </Styled.MatchTalentsArea>
    );
};

export default MatchTalentsArea;
