import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 30px;
  margin-bottom: 20px;
  position: relative;
`;

export const Row = styled.div`
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
`;

export const ContainerPerson = styled.div`
  max-width: 190px;
  margin: 0px 31px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`;

export const ItemTopLeft = styled.div`
  flex: 1;
  ${({ color }) => color && `background: ${color};`};
  text-align: center;
  width: 460px;
  height: 244px;
  font-family: "Roboto Condensed";
  border-right: 1px solid #dad8df;
  border-bottom: 1px solid #dad8df;
  border-radius: 25px 0px 0px 0px;
  position: relative;
`;

export const Item = styled.div`
  flex: 1;
  ${({ color }) => color && `background: ${color};`};
  text-align: center;
  width: 560px;
  font-family: "Roboto Condensed";
  ${({ borderTop }) => borderTop && "border-top:1px solid #dad8df;"}
  ${({ borderRight }) => borderRight && "border-right:1px solid #dad8df;"}
    ${({ borderBottom }) => borderBottom && "border-bottom:1px solid #dad8df;"}
    ${({ borderLeft }) => borderLeft && "border-left:1px solid #dad8df;"}
    height: 244px;
  position: relative;
`;

export const ItemTopRigth = styled.div`
  flex: 1;
  ${({ color }) => color && `background: ${color};`};
  text-align: center;
  width: 560px;
  height: 244px;
  font-family: "Roboto Condensed";
  border-left: 1px solid #dad8df;
  border-bottom: 1px solid #dad8df;
  border-radius: 0px 25px 0px 0px;
  position: relative;
`;

export const ItemBottomLeft = styled.div`
  flex: 1;
  ${({ color }) => color && `background: ${color};`};
  text-align: center;
  width: 560px;
  height: 244px;
  font-family: "Roboto Condensed";
  border-right: 1px solid #dad8df;
  border-top: 1px solid #dad8df;
  border-radius: 0px 0px 0px 25px;
  position: relative;
`;

export const ItemBottomRigth = styled.div`
  flex: 1;
  ${({ color }) => color && `background: ${color};`};
  text-align: center;
  width: 560px;
  height: 244px;
  font-family: "Roboto Condensed";
  border-left: 1px solid #dad8df;
  border-top: 1px solid #dad8df;
  border-radius: 0px 0px 25px 0px;
  position: relative;
`;

export const TextBox = styled.span`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 11px;

  color: #601e82;
  text-shadow: 0px -1px 0px #ffffff;
  ${({ isUpper }) => isUpper && ` text-transform: uppercase;`};
  ${({ textBottom }) => textBottom && ` padding: 0px; cursor: pointer;`};
  visibility: ${({ labelVisibility }) => labelVisibility && "hidden"};
`;
export const WarrperCircleProfiler = styled.div`
  padding-right: 3px;
`;
export const ContainerTitle = styled.div`
  width: 100%;
  margin: 3px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding-left: 12px;
`;

export const ContainerTitleBottom = styled.div`
  width: 100%;
  margin: 14px 0 5px;
  justify-content: center;
  padding-right: 9px;
  display: flex;
  text-decoration: underline;
  align-items: center;
  position: absolute;
  bottom: 0;
`;

export const FlexSize = styled.div`
  ${({ flex }) => flex && `flex: ${flex};`};
`;

export const Person = styled.div`
  width: 32px;
  height: 32px;
  margin: 4px 2px;
  ${({ data }) => data && `background-image: url('${data}');`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 2px solid rgba(66, 82, 110, 0.65);
  box-sizing: border-box;
  border-radius: 125px;
`;

export const SvgContainer = styled.div`
  position: absolute;
  right: 9px;
`;
export const EixoVertical = styled.div`
  top: 0;
  position: absolute;
  left: -20px;
  border-left: 3px solid #efedf3;
  height: 100%;
  display: flex;
  align-items: center;
  svg {
    position: absolute;
    top: -10px;
    left: -14.6px;
  }
`;
export const EixoHorizonal = styled.div`
  bottom: 0;
  position: absolute;
  left: -20px;
  border-bottom: 3px solid #efedf3;
  width: 102%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    position: absolute;
    top: -11.45px;
    right: -9px;
    transform: rotate(90deg);
  }
`;
export const textEixo = styled.span`
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #601e82;
  text-shadow: 0px -1px 0px #ffffff;
  ${({ rotate }) =>
    rotate === "rotate" && "transform: rotate(-90deg);  right: -68px;"}
  background: #fff;
  padding: 1px 15px;
  position: absolute;
  text-transform: uppercase;
`;
export const ImgUserWithOut = styled.div`
  width: 32px;
  height: 32px;
  margin: 4px 2px;
  ${({ background }) =>
    background ? `background:${background};` : "background: #601e82"};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const InitalsName = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
`;
export const ContainerTooltip = styled.div`
  display: flex;
  align-items: center;
`;
export const InfoQuadrant = styled.div`
  font-family: "Roboto Condensed";
  display: flex;
  align-items: flex-start;
`;
export const TextInfo = styled.span`
  text-align: left;
  font-weight: ${({ bold }) => bold && `bold;`};
  flex: ${({ flex }) => flex && `${flex}`};
  font-size: 12px;
  font-family: "Roboto Condensed";
`;
export const TooltipInfo = styled.div`
  flex: 1;
  padding-right: 18px;
  text-align: left;
`;
export const TooltipName = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;
  color: #efedf3;
`;

export const TooltipPositions = styled.div`
  font-size: 10px;
  line-height: 11px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;
  color: #efedf3;
`;
export const TooltipScore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  font-size: 10px;
  line-height: 11px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: normal;
  color: #efedf3;
  svg {
    width: 32px;
    height: 32px;
    circle {
      stroke-width: 26;
    }
  }
`;
