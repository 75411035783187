
import { profilerTextStatic } from "../../pages/person/body/profiler/textsProfilers";
import { EnglishStaticTextsProfiler } from "../../pages/person/body/profiler/textsProfilersInEnglish";
import { SpanishStaticTextsProfiler } from "../../pages/person/body/profiler/textsProfilersInSpanish";
import getTokenLocale from "./getTokenLocale";

function getStaticTextsProfiler() {
  const locale = getTokenLocale();
  const staticTextsProfilerMap = {
    "en": EnglishStaticTextsProfiler,
    "es": SpanishStaticTextsProfiler,
  };

  return staticTextsProfilerMap[locale] || profilerTextStatic;
}

export default getStaticTextsProfiler;