import styled from "styled-components";

export const EmptyMessageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 32px 20px;
  color: #601e82;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  letter-spacing: -0.05em;
  height: 100vh;
`;

export const EmptyMessageTitle = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #601e82;
`;

export const EmptyMessageText = styled.p`
  font-size: 16px;
  text-align: center;
  color: #601e82;
`;

export const EmptyTimerText = styled.p`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #601e82;
`;

export const EmptyMessageContainer = styled.div`
  width: 70%;
`;

export const Center = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;
