import styled from "styled-components";

export const ProfilerBar = styled.div`
  height: 120px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  ${({ hideTexts }) => (hideTexts ? "margin-top: 0" : "margin-top: 40px;")};

  margin-bottom: 40px;
  flex-direction: column;
  padding-top: 50px;
`;

export const BallWhite = styled.div`
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 200px;
`;

export const Executor = styled.span`
  color: ${({ theme }) => theme.colors.executor};
  font-size: 0.875rem;
  font-weight: 800;
  display: flex;
  align-items: center;
  svg {
    rect {
      fill: ${({ theme }) => theme.colors.executor};
    }
    path {
      fill: ${({ theme }) => theme.colors.executor};
    }
    margin-left: 15px;
    margin-right: 3px;
    width: 15px;
    height: 15px;
    margin-bottom: 2px;
  }

  @media screen and (max-width: 540px) {
    font-size: 0.875rem;
  }

  @media screen and (max-width: 361px) {
    font-size: 0.75rem;
  }
`;

export const Planejador = styled.span`
  color: ${({ theme }) => theme.colors.planner};
  font-size: 0.875rem;
  font-weight: 800;
  display: flex;
  align-items: center;
  svg {
    rect {
      fill: ${({ theme }) => theme.colors.planner};
    }
    path {
      fill: ${({ theme }) => theme.colors.planner};
    }
    margin-left: 15px;
    margin-right: 3px;
    width: 15px;
    height: 15px;
    margin-bottom: 2px;
  }

  @media screen and (max-width: 540px) {
    font-size: 0.875rem;
  }

  @media screen and (max-width: 361px) {
    font-size: 0.75rem;
  }
`;

export const Comunicador = styled.span`
  color: ${({ theme }) => theme.colors.communicator};
  font-size: 0.875rem;
  font-weight: 800;
  display: flex;
  align-items: center;
  svg {
    rect {
      fill: ${({ theme }) => theme.colors.communicator};
    }
    path {
      fill: ${({ theme }) => theme.colors.communicator};
    }
    margin-left: 15px;
    margin-right: 3px;
    width: 15px;
    height: 15px;
    margin-bottom: 2px;
  }

  @media screen and (max-width: 540px) {
    font-size: 0.875rem;
  }

  @media screen and (max-width: 361px) {
    font-size: 0.75rem;
  }
`;

export const Analista = styled.span`
  color: ${({ theme }) => theme.colors.analyst};
  font-size: 0.875rem;
  font-weight: 800;
  display: flex;
  align-items: center;
  svg {
    rect {
      fill: ${({ theme }) => theme.colors.analyst};
    }
    path {
      fill: ${({ theme }) => theme.colors.analyst};
    }
    margin-left: 15px;
    margin-right: 3px;
    width: 15px;
    height: 15px;
    margin-bottom: 2px;
  }

  @media screen and (max-width: 540px) {
    font-size: 0.875rem;
  }

  @media screen and (max-width: 361px) {
    font-size: 0.75rem;
  }
`;

export const Chart = styled.div`
  display: grid;

  ${(props) =>
    gridTemplate(props, ["executor", "planejador", "comunicador", "analista"])};
  width: 100%;
`;

export const ItemExecutor = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${({ theme }) => theme.colors.executor};
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
`;

export const ItemPlanejador = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${({ theme }) => theme.colors.planner};
`;

export const ItemComunicador = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${({ theme }) => theme.colors.communicator};
`;

export const ItemAnalista = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${({ theme }) => theme.colors.analyst};
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
`;

export const ItemText = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ItemTextSmall = styled.span`
  color: #fff;
  font-size: 0.75rem;
  padding-right: 6px;
  text-shadow: -1px 0 rgba(66, 82, 110, 0.35), 0 1px rgba(66, 82, 110, 0.35),
    1px 0 rgba(66, 82, 110, 0.35), 0 -1px rgba(66, 82, 110, 0.35);
`;

export const ItemChart = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TextItemChart = styled.span`
  color: #fff;
  font-size: 1.375rem;
  padding-right: 10px;
`;

const gridTemplate = (props, item = []) => {
  const { executor, planejador, comunicador, analista } = props;

  const autal = item.filter((item) => {
    return parseFloat(props[item]) < 5 && item;
  });

  const currentValue = parseFloat(props[autal[0]]);
  const adjustValue = parseFloat(props[autal[0]]) / 4;
  const minPercentageValue = 3;
  const check = currentValue < 8;

  if (check) {
    if (autal[0] === "analista") {
      return `grid-template-columns: ${parseFloat(executor) - adjustValue}% ${
        parseFloat(comunicador) - adjustValue
      }%  ${parseFloat(planejador) - adjustValue}% ${
        parseFloat(analista) - adjustValue > 0
          ? parseFloat(analista) - adjustValue
          : minPercentageValue
      }%`;
    }
    if (autal[0] === "planejador") {
      return `grid-template-columns:
                ${parseFloat(executor) - adjustValue}% ${
        parseFloat(comunicador) - adjustValue
      }% ${
        parseFloat(planejador) - adjustValue < 1
          ? parseFloat(planejador) - adjustValue
          : minPercentageValue
      }%  ${parseFloat(analista) - adjustValue}% `;
    }
    if (autal[0] === "comunicador") {
      return `grid-template-columns:
                ${parseFloat(executor) - adjustValue}% ${
        parseFloat(comunicador) - adjustValue < 1
          ? parseFloat(comunicador) - adjustValue
          : minPercentageValue
      }% ${parseFloat(planejador) - adjustValue}%  ${
        parseFloat(analista) - adjustValue
      }% `;
    }
    if (autal[0] === "executor") {
      return `grid-template-columns:
            ${
              parseFloat(executor) - adjustValue < 1
                ? parseFloat(executor) - adjustValue
                : minPercentageValue
            }% ${parseFloat(comunicador) - adjustValue}%  ${
        parseFloat(planejador) - adjustValue
      }%  ${parseFloat(analista) - adjustValue}% `;
    }
  } else {
    return `grid-template-columns:${executor} ${comunicador} ${planejador}  ${analista} `;
  }
};

export const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 31px;
  padding-bottom: 20px;
`;

export const InfoGrid = styled.div`
  display: flex;
  width: 80%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  @media screen and (max-width: 540px) {
    width: 100%;
  }
`;

export const InfoGridItem = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
export const ProfilerEnergic = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  span {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: #601e82;
    font-weight: 600;
  }
`;

export const InfoGridItemPercentage = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  color: #601e82;

  @media screen and (max-width: 540px) {
    font-size: 0.875rem;
  }
`;

export const InfoGridItemType = styled.span`
  font-style: normal;
  font-size: 1.25rem;

  ::first-letter {
    text-transform: uppercase;
  }

  @media screen and (max-width: 540px) {
    font-size: 0.875rem;
  }

  ${({ theme, type }) =>
    type === "executor"
      ? `color: ${theme.colors.executor}`
      : type === "comunicador"
      ? `color: ${theme.colors.communicator}`
      : type === "planejador"
      ? `color: ${theme.colors.planner}`
      : type === "analista"
      ? `color: ${theme.colors.analyst}`
      : "color: black"}
`;

export const InfoGridItemLabel = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #601e82;
  @media screen and (max-width: 540px) {
    font-size: 11px;
  }
`;

export const InfoGridItemPlus = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: #601e82;

  font-weight: bold;
  font-size: 0.875rem;
`;

export const TitleToolip = styled.p`
  font-weight: bold;
  font-size: 0.75rem;
`;
export const Label = styled.p`
  font-size: 0.75rem;
  margin-bottom: 3px;
`;
