import React, { useState, useEffect } from "react";
import * as Styles from "./styles";
import CircleProfiler from "../../../circleProfile";
import CirclePercentage from "../../components/circlePercentage";
import { backgroundColorByProfiler } from "../../../../utils/functions/backgroundColorByProfiler";
import { Tooltip } from "antd";
import { ReactComponent as Arrows } from "../../../../assets/svg/arrow-up.svg";
import { ReactComponent as Nine } from "../../../../assets/svg/quadrant_9box_9.svg";
import { ReactComponent as Eight } from "../../../../assets/svg/quadrant_9box_8.svg";
import { ReactComponent as Seven } from "../../../../assets/svg/quadrant_9box_7.svg";
import { ReactComponent as Six } from "../../../../assets/svg/quadrant_9box_6.svg";
import { ReactComponent as Five } from "../../../../assets/svg/quadrant_9box_5.svg";
import { ReactComponent as Four } from "../../../../assets/svg/quadrant_9box_4.svg";
import { ReactComponent as Three } from "../../../../assets/svg/quadrant_9box_3.svg";
import { ReactComponent as Two } from "../../../../assets/svg/quadrant_9box_2.svg";
import { ReactComponent as One } from "../../../../assets/svg/quadrant_9box_1.svg";

const NineBox = ({ quadrantChartData, fallbackClickQuadrant }) => {
    const [develop_technique, setDevelop_technique] = useState({});
    const [improve_technique, setImprove_technique] = useState({});
    const [highlights, setHighlights] = useState({});
    const [verify_situation, setVerify_situation] = useState({});
    const [improve_culture_technique, setImprove_culture_technique] = useState(
        {}
    );
    const [improve_culture, setImprove_culture] = useState({});
    const [insufficient, setInsufficient] = useState({});
    const [work_values, setWork_values] = useState({});
    const [develop_culture, setDevelop_culture] = useState({});

    useEffect(() => {
        if (!quadrantChartData) return;
        if (quadrantChartData.develop_technique)
            setDevelop_technique(quadrantChartData.develop_technique);
        if (quadrantChartData.improve_technique)
            setImprove_technique(quadrantChartData.improve_technique);
        if (quadrantChartData.highlights)
            setHighlights(quadrantChartData.highlights);
        if (quadrantChartData.verify_situation)
            setVerify_situation(quadrantChartData.verify_situation);
        if (quadrantChartData.improve_culture_technique)
            setImprove_culture_technique(
                quadrantChartData.improve_culture_technique
            );
        if (quadrantChartData.improve_culture)
            setImprove_culture(quadrantChartData.improve_culture);
        if (quadrantChartData.insufficient)
            setInsufficient(quadrantChartData.insufficient);
        if (quadrantChartData.work_values)
            setWork_values(quadrantChartData.work_values);
        if (quadrantChartData.develop_culture)
            setDevelop_culture(quadrantChartData.develop_culture);
    }, [quadrantChartData]);

    const getInitalsName = value => {
        if (value === undefined) return;
        const fullName = value.split(" ");
        const firstName = fullName[0].substring(0, 1).toUpperCase();
        const lastIndex = fullName.length - 1 !== 0;
        const secondName =
            !!fullName[fullName.length - 1] && lastIndex
                ? fullName[fullName.length - 1].substring(0, 1).toUpperCase()
                : "";
        return `${firstName}${secondName}`;
    };
    const _hanleCLickQudrant = quadrant => {
        fallbackClickQuadrant(quadrant);
    };
    const TooltipSingle = ({ children, name, score, position, department }) => {
        return (
            <Tooltip
                overlayClassName="ant-tooltip-custumer"
                placement="right"
                title={
                    <Styles.ContainerTooltip>
                        <Styles.TooltipInfo>
                            <Styles.TooltipName>{name}</Styles.TooltipName>
                            <Styles.TooltipPositions>{`${department}/${position}`}</Styles.TooltipPositions>
                        </Styles.TooltipInfo>
                        <Styles.TooltipScore>
                            <CirclePercentage
                                data={{
                                    percentage: score ? score.toFixed(0) : 0,
                                    progressColor: "#EFEDF3",
                                    bgColor: "#8872B2",
                                    textColor: "#FFFFFF",
                                    lineWidth: 16,
                                    roundedStroke: true,
                                    showPercentage: true,
                                }}
                            />
                        </Styles.TooltipScore>
                    </Styles.ContainerTooltip>
                }
            >
                {children}
            </Tooltip>
        );
    };

    const TooltipNumberQuadrant = ({ children, numberQuadrant, text }) => {
        return (
            <Tooltip
                overlayClassName="ant-tooltip-custumer widthNinebox"
                style={{
                    maxWidth: "auto !important",
                }}
                placement="right"
                title={
                    <Styles.ContainerTooltip>
                        <Styles.InfoQuadrant>
                            <Styles.TextInfo bold flex={0.1}>
                                {numberQuadrant} -
                            </Styles.TextInfo>
                            <Styles.TextInfo flex={1}>{text}</Styles.TextInfo>
                        </Styles.InfoQuadrant>
                    </Styles.ContainerTooltip>
                }
            >
                {children}
            </Tooltip>
        );
    };
    return (
        <Styles.Container id="quadrantPrint">
            <Styles.Row>
                <Styles.ItemTopLeft color="#FFFDCC" id="develop_technique">
                    <Styles.ContainerTitle>
                        <TooltipNumberQuadrant
                            numberQuadrant={5}
                            text="Manter engajamento com a comportamento e incentivar capacitação técnica."
                        >
                            <Styles.TextBox isUpper>
                                DESENVOLVER TÉCNICA
                            </Styles.TextBox>
                        </TooltipNumberQuadrant>

                        <Styles.WarrperCircleProfiler>
                            <CircleProfiler
                                small
                                type={develop_technique.predominant_profiler}
                            />
                        </Styles.WarrperCircleProfiler>
                    </Styles.ContainerTitle>
                    <Styles.ContainerPerson
                        justifyContent={develop_technique.total <= 4}
                    >
                        {develop_technique.people &&
                            develop_technique.people.map((item, index) => {
                                if (item.picture) {
                                    return (
                                        <TooltipSingle
                                            key={`peron-${index}`}
                                            name={item.name}
                                            score={item.proximityPosition}
                                            position={item.position}
                                            department={item.department}
                                        >
                                            <Styles.Person
                                                data={item.picture}
                                            />
                                        </TooltipSingle>
                                    );
                                }
                                return (
                                    <TooltipSingle
                                        key={`peron-${index}`}
                                        name={item.name}
                                        score={item.proximityPosition}
                                        position={item.position}
                                        department={item.department}
                                    >
                                        <Styles.ImgUserWithOut
                                            background={
                                                item.profiler ===
                                                    "Não respondido" ||
                                                item.profiler === "Expirado" ||
                                                item.profiler === ""
                                                    ? "#5F4A87"
                                                    : backgroundColorByProfiler(
                                                          item.profiler
                                                      )
                                            }
                                        >
                                            <Styles.InitalsName>
                                                {getInitalsName(item.name)}
                                            </Styles.InitalsName>
                                        </Styles.ImgUserWithOut>
                                    </TooltipSingle>
                                );
                            })}
                    </Styles.ContainerPerson>
                    <Styles.ContainerTitleBottom>
                        <Styles.FlexSize flex={6} />

                        <Styles.TextBox
                            labelVisibility={
                                develop_technique.total <= 20 ||
                                Object.keys(develop_technique).length === 0
                            }
                            textBottom
                            onClick={() =>
                                _hanleCLickQudrant({
                                    title: "Desenvolver Técnica",
                                    quadrant: 5,
                                })
                            }
                        >{`ver todos os ${develop_technique.total}`}</Styles.TextBox>
                        <Styles.FlexSize flex={4} />

                        <Five />
                        <Styles.FlexSize flex={0.1} />
                    </Styles.ContainerTitleBottom>
                </Styles.ItemTopLeft>

                <Styles.Item
                    color="#EFFFCC"
                    id="improve_technique"
                    borderBottom
                >
                    <Styles.ContainerTitle>
                        <TooltipNumberQuadrant
                            numberQuadrant={8}
                            text="Reforçar capacitação técnica/treinamentos."
                        >
                            <Styles.TextBox isUpper>
                                APRIMORAR TÉCNICA
                            </Styles.TextBox>
                        </TooltipNumberQuadrant>

                        <Styles.WarrperCircleProfiler>
                            <CircleProfiler
                                small
                                type={improve_technique.predominant_profiler}
                            />
                        </Styles.WarrperCircleProfiler>
                    </Styles.ContainerTitle>
                    <Styles.ContainerPerson
                        justifyContent={improve_technique.total <= 4}
                    >
                        {improve_technique.people &&
                            improve_technique.people.map((item, index) => {
                                if (item.picture) {
                                    return (
                                        <TooltipSingle
                                            key={`peron-${index}`}
                                            name={item.name}
                                            score={item.proximityPosition}
                                            position={item.position}
                                            department={item.department}
                                        >
                                            <Styles.Person
                                                marginRight={
                                                    improve_technique.total <= 4
                                                }
                                                data={item.picture}
                                            />
                                        </TooltipSingle>
                                    );
                                }
                                return (
                                    <TooltipSingle
                                        key={`peron-${index}`}
                                        name={item.name}
                                        score={item.proximityPosition}
                                        position={item.position}
                                        department={item.department}
                                    >
                                        <Styles.ImgUserWithOut
                                            background={
                                                item.profiler ===
                                                    "Não respondido" ||
                                                item.profiler === "Expirado" ||
                                                item.profiler === ""
                                                    ? "#5F4A87"
                                                    : backgroundColorByProfiler(
                                                          item.profiler
                                                      )
                                            }
                                        >
                                            <Styles.InitalsName>
                                                {getInitalsName(item.name)}
                                            </Styles.InitalsName>
                                        </Styles.ImgUserWithOut>
                                    </TooltipSingle>
                                );
                            })}
                    </Styles.ContainerPerson>
                    <Styles.ContainerTitleBottom>
                        <Styles.FlexSize flex={6} />
                        <Styles.TextBox
                            labelVisibility={
                                improve_technique.total <= 20 ||
                                Object.keys(improve_technique).length === 0
                            }
                            textBottom
                            onClick={() =>
                                _hanleCLickQudrant({
                                    title: "Aprimorar Técnica",
                                    quadrant: 8,
                                })
                            }
                        >{`todos ${improve_technique.total}`}</Styles.TextBox>

                        <Styles.FlexSize flex={4} />

                        <Eight />
                        <Styles.FlexSize flex={0.1} />
                    </Styles.ContainerTitleBottom>
                </Styles.Item>

                <Styles.ItemTopRigth color="#DEFFB5" id="highlights">
                    <Styles.ContainerTitle>
                        <TooltipNumberQuadrant
                            numberQuadrant={9}
                            text="Imperdível. Está pronto
                            para novos desafios."
                        >
                            <Styles.TextBox isUpper>DESTAQUES</Styles.TextBox>
                        </TooltipNumberQuadrant>

                        <Styles.WarrperCircleProfiler>
                            <CircleProfiler
                                small
                                type={highlights.predominant_profiler}
                            />
                        </Styles.WarrperCircleProfiler>
                    </Styles.ContainerTitle>
                    <Styles.ContainerPerson
                        justifyContent={highlights.total <= 4}
                    >
                        {highlights.people &&
                            highlights.people.map((item, index) => {
                                if (item.picture) {
                                    return (
                                        <TooltipSingle
                                            key={`peron-${index}`}
                                            name={item.name}
                                            score={item.proximityPosition}
                                            position={item.position}
                                            department={item.department}
                                        >
                                            <Styles.Person
                                                marginRight={
                                                    highlights.total <= 4
                                                }
                                                data={item.picture}
                                            />
                                        </TooltipSingle>
                                    );
                                }
                                return (
                                    <TooltipSingle
                                        key={`peron-${index}`}
                                        name={item.name}
                                        score={item.proximityPosition}
                                        position={item.position}
                                        department={item.department}
                                    >
                                        <Styles.ImgUserWithOut
                                            marginRight={highlights.total <= 4}
                                            background={
                                                item.profiler ===
                                                    "Não respondido" ||
                                                item.profiler === "Expirado" ||
                                                item.profiler === ""
                                                    ? "#5F4A87"
                                                    : backgroundColorByProfiler(
                                                          item.profiler
                                                      )
                                            }
                                        >
                                            <Styles.InitalsName>
                                                {getInitalsName(item.name)}
                                            </Styles.InitalsName>
                                        </Styles.ImgUserWithOut>
                                    </TooltipSingle>
                                );
                            })}
                    </Styles.ContainerPerson>
                    <Styles.ContainerTitleBottom>
                        <Styles.FlexSize flex={6} />
                        <Styles.TextBox
                            labelVisibility={
                                highlights.total <= 20 ||
                                Object.keys(highlights).length === 0
                            }
                            textBottom
                            onClick={() =>
                                _hanleCLickQudrant({
                                    title: "Destaques",
                                    quadrant: 9,
                                })
                            }
                        >{`ver todos os ${highlights.total}`}</Styles.TextBox>

                        <Styles.FlexSize flex={4} />

                        <Nine />
                        <Styles.FlexSize flex={0.1} />
                    </Styles.ContainerTitleBottom>
                </Styles.ItemTopRigth>
            </Styles.Row>

            <Styles.Row>
                <Styles.Item color="#FFE3E3" id="verify_situation">
                    <Styles.ContainerTitle>
                        <TooltipNumberQuadrant
                            numberQuadrant={3}
                            text="Atenção. Analisar tempo no cargo, posição ocupada e necessidade de treinamento/capacitação."
                        >
                            <Styles.TextBox isUpper>
                                VERIFICAR SITUAÇÃO
                            </Styles.TextBox>
                        </TooltipNumberQuadrant>

                        <Styles.WarrperCircleProfiler>
                            <CircleProfiler
                                small
                                type={verify_situation.predominant_profiler}
                            />
                        </Styles.WarrperCircleProfiler>
                    </Styles.ContainerTitle>
                    <Styles.ContainerPerson
                        justifyContent={verify_situation.total <= 4}
                    >
                        {verify_situation.people &&
                            verify_situation.people.map((item, index) => {
                                if (item.picture) {
                                    return (
                                        <TooltipSingle
                                            key={`peron-${index}`}
                                            name={item.name}
                                            score={item.proximityPosition}
                                            position={item.position}
                                            department={item.department}
                                        >
                                            <Styles.Person
                                                data={item.picture}
                                                marginRight={
                                                    verify_situation.total <= 4
                                                }
                                            />
                                        </TooltipSingle>
                                    );
                                }
                                return (
                                    <TooltipSingle
                                        key={`peron-${index}`}
                                        name={item.name}
                                        score={item.proximityPosition}
                                        position={item.position}
                                        department={item.department}
                                    >
                                        <Styles.ImgUserWithOut
                                            background={
                                                item.profiler ===
                                                    "Não respondido" ||
                                                item.profiler === "Expirado" ||
                                                item.profiler === ""
                                                    ? "#5F4A87"
                                                    : backgroundColorByProfiler(
                                                          item.profiler
                                                      )
                                            }
                                        >
                                            <Styles.InitalsName>
                                                {getInitalsName(item.name)}
                                            </Styles.InitalsName>
                                        </Styles.ImgUserWithOut>
                                    </TooltipSingle>
                                );
                            })}
                    </Styles.ContainerPerson>
                    <Styles.ContainerTitleBottom>
                        <Styles.FlexSize flex={6} />
                        <Styles.TextBox
                            labelVisibility={
                                verify_situation.total <= 20 ||
                                Object.keys(verify_situation).length === 0
                            }
                            textBottom
                            onClick={() =>
                                _hanleCLickQudrant({
                                    title: "Verificar situação",
                                    quadrant: 3,
                                })
                            }
                        >{`ver todos os ${verify_situation.total}`}</Styles.TextBox>

                        <Styles.FlexSize flex={4} />

                        <Three />
                        <Styles.FlexSize flex={0.1} />
                    </Styles.ContainerTitleBottom>
                </Styles.Item>

                <Styles.Item
                    color="#FFEFD0"
                    id="improve_culture_technique"
                    borderLeft
                    borderRight
                >
                    <Styles.ContainerTitle>
                        <TooltipNumberQuadrant
                            numberQuadrant={6}
                            text="Entrega consistente.
                            Potencializar resultados e comportamento. "
                        >
                            <Styles.TextBox isUpper textBottom>
                                APRIMORAR COMPORTAMENTO E TÉCNICA
                            </Styles.TextBox>
                        </TooltipNumberQuadrant>

                        <Styles.WarrperCircleProfiler>
                            <CircleProfiler
                                small
                                type={
                                    improve_culture_technique.predominant_profiler
                                }
                            />
                        </Styles.WarrperCircleProfiler>
                    </Styles.ContainerTitle>
                    <Styles.ContainerPerson
                        justifyContent={improve_culture_technique.total <= 4}
                    >
                        {improve_culture_technique.people &&
                            improve_culture_technique.people.map(
                                (item, index) => {
                                    if (item.picture) {
                                        return (
                                            <TooltipSingle
                                                key={`peron-${index}`}
                                                name={item.name}
                                                score={item.proximityPosition}
                                                position={item.position}
                                                department={item.department}
                                            >
                                                <Styles.Person
                                                    data={item.picture}
                                                    marginRight={
                                                        improve_culture_technique.total <=
                                                        4
                                                    }
                                                />
                                            </TooltipSingle>
                                        );
                                    }
                                    return (
                                        <TooltipSingle
                                            key={`peron-${index}`}
                                            name={item.name}
                                            score={item.proximityPosition}
                                            position={item.position}
                                            department={item.department}
                                        >
                                            <Styles.ImgUserWithOut
                                                background={
                                                    item.profiler ===
                                                        "Não respondido" ||
                                                    item.profiler ===
                                                        "Expirado" ||
                                                    item.profiler === ""
                                                        ? "#5F4A87"
                                                        : backgroundColorByProfiler(
                                                              item.profiler
                                                          )
                                                }
                                            >
                                                <Styles.InitalsName>
                                                    {getInitalsName(item.name)}
                                                </Styles.InitalsName>
                                            </Styles.ImgUserWithOut>
                                        </TooltipSingle>
                                    );
                                }
                            )}
                    </Styles.ContainerPerson>
                    <Styles.ContainerTitleBottom>
                        <Styles.FlexSize flex={6} />
                        <Styles.TextBox
                            labelVisibility={
                                improve_culture_technique.total <= 20 ||
                                Object.keys(improve_culture_technique)
                                    .length === 0
                            }
                            textBottom
                            onClick={() =>
                                _hanleCLickQudrant({
                                    title: "Aprimorar Cultura e Técnica",
                                    quadrant: 6,
                                })
                            }
                        >{`ver todos os ${improve_culture_technique.total}`}</Styles.TextBox>
                        <Styles.FlexSize flex={4} />

                        <Six />
                        <Styles.FlexSize flex={0.1} />
                    </Styles.ContainerTitleBottom>
                </Styles.Item>

                <Styles.Item color="#EFFFCC" id="improve_culture" noBorderRight>
                    <Styles.ContainerTitle>
                        <TooltipNumberQuadrant
                            numberQuadrant={7}
                            text="Entrega além do esperado. Manter engajamento com resultados e potencializar comportamento."
                        >
                            <Styles.TextBox isUpper>
                                APRIMORAR COMPORTAMENTO
                            </Styles.TextBox>
                        </TooltipNumberQuadrant>

                        <Styles.WarrperCircleProfiler>
                            <CircleProfiler
                                small
                                type={improve_culture.predominant_profiler}
                            />
                        </Styles.WarrperCircleProfiler>
                    </Styles.ContainerTitle>
                    <Styles.ContainerPerson
                        justifyContent={improve_culture.total <= 4}
                    >
                        {improve_culture.people &&
                            improve_culture.people.map((item, index) => {
                                if (item.picture) {
                                    return (
                                        <TooltipSingle
                                            key={`peron-${index}`}
                                            name={item.name}
                                            score={item.proximityPosition}
                                            position={item.position}
                                            department={item.department}
                                        >
                                            <Styles.Person
                                                data={item.picture}
                                                marginRight={
                                                    improve_culture.total <= 4
                                                }
                                            />
                                        </TooltipSingle>
                                    );
                                }
                                return (
                                    <TooltipSingle
                                        key={`peron-${index}`}
                                        name={item.name}
                                        score={item.proximityPosition}
                                        position={item.position}
                                        department={item.department}
                                    >
                                        <Styles.ImgUserWithOut
                                            background={
                                                item.profiler ===
                                                    "Não respondido" ||
                                                item.profiler === "Expirado" ||
                                                item.profiler === ""
                                                    ? "#5F4A87"
                                                    : backgroundColorByProfiler(
                                                          item.profiler
                                                      )
                                            }
                                        >
                                            <Styles.InitalsName>
                                                {getInitalsName(item.name)}
                                            </Styles.InitalsName>
                                        </Styles.ImgUserWithOut>
                                    </TooltipSingle>
                                );
                            })}
                    </Styles.ContainerPerson>
                    <Styles.ContainerTitleBottom>
                        <Styles.FlexSize flex={6} />
                        <Styles.TextBox
                            labelVisibility={
                                improve_culture.total <= 20 ||
                                Object.keys(improve_culture).length === 0
                            }
                            onClick={() =>
                                _hanleCLickQudrant({
                                    title: "Aprimorar Cultura",
                                    quadrant: 7,
                                })
                            }
                            textBottom
                        >{`ver todos os ${improve_culture.total}`}</Styles.TextBox>
                        <Styles.FlexSize flex={4} />

                        <Seven />
                        <Styles.FlexSize flex={0.1} />
                    </Styles.ContainerTitleBottom>
                </Styles.Item>
            </Styles.Row>
            <Styles.Row>
                <Styles.ItemBottomLeft color="#FFD7DB" id="insufficient">
                    <Styles.ContainerTitle>
                        <TooltipNumberQuadrant
                            numberQuadrant={1}
                            text="Situação crítica. Analisar realocação ou substituição."
                        >
                            <Styles.TextBox isUpper>
                                INSUFICIENTES
                            </Styles.TextBox>
                        </TooltipNumberQuadrant>

                        <Styles.WarrperCircleProfiler>
                            <CircleProfiler
                                small
                                type={insufficient.predominant_profiler}
                            />
                        </Styles.WarrperCircleProfiler>
                    </Styles.ContainerTitle>
                    <Styles.ContainerPerson
                        justifyContent={insufficient.total <= 4}
                    >
                        {insufficient.people &&
                            insufficient.people.map((item, index) => {
                                if (item.picture) {
                                    return (
                                        <TooltipSingle
                                            key={`peron-${index}`}
                                            name={item.name}
                                            score={item.proximityPosition}
                                            position={item.position}
                                            department={item.department}
                                        >
                                            <Styles.Person
                                                data={item.picture}
                                                marginRight={
                                                    insufficient.total <= 4
                                                }
                                            />
                                        </TooltipSingle>
                                    );
                                }
                                return (
                                    <TooltipSingle
                                        key={`peron-${index}`}
                                        name={item.name}
                                        score={item.proximityPosition}
                                        position={item.position}
                                        department={item.department}
                                    >
                                        <Styles.ImgUserWithOut
                                            background={
                                                item.profiler ===
                                                    "Não respondido" ||
                                                item.profiler === "Expirado" ||
                                                item.profiler === ""
                                                    ? "#5F4A87"
                                                    : backgroundColorByProfiler(
                                                          item.profiler
                                                      )
                                            }
                                        >
                                            <Styles.InitalsName>
                                                {getInitalsName(item.name)}
                                            </Styles.InitalsName>
                                        </Styles.ImgUserWithOut>
                                    </TooltipSingle>
                                );
                            })}
                    </Styles.ContainerPerson>
                    <Styles.ContainerTitleBottom>
                        <Styles.FlexSize flex={6} />
                        <Styles.TextBox
                            labelVisibility={
                                insufficient.total <= 20 ||
                                Object.keys(insufficient).length === 0
                            }
                            onClick={() =>
                                _hanleCLickQudrant({
                                    title: "Insuficientes",
                                    quadrant: 1,
                                })
                            }
                            textBottom
                        >{`ver todos os ${insufficient.total}`}</Styles.TextBox>
                        <Styles.FlexSize flex={4} />

                        <One />
                        <Styles.FlexSize flex={0.1} />
                    </Styles.ContainerTitleBottom>
                </Styles.ItemBottomLeft>

                <Styles.Item color="#FFE3E3" id="work_values" borderTop>
                    <Styles.ContainerTitle>
                        <TooltipNumberQuadrant
                            numberQuadrant={2}
                            text=" Entrega dentro do esperado. Reforçar valores e comportamentos através de feedbacks."
                        >
                            <Styles.TextBox isUpper>
                                TRABALHAR VALORES
                            </Styles.TextBox>
                        </TooltipNumberQuadrant>

                        <Styles.WarrperCircleProfiler>
                            <CircleProfiler
                                small
                                type={work_values.predominant_profiler}
                            />
                        </Styles.WarrperCircleProfiler>
                    </Styles.ContainerTitle>
                    <Styles.ContainerPerson
                        justifyContent={work_values.total <= 4}
                    >
                        {work_values.people &&
                            work_values.people.map((item, index) => {
                                if (item.picture) {
                                    return (
                                        <TooltipSingle
                                            key={`peron-${index}`}
                                            name={item.name}
                                            score={item.proximityPosition}
                                            position={item.position}
                                            department={item.department}
                                        >
                                            <Styles.Person
                                                data={item.picture}
                                                marginRight={
                                                    work_values.total <= 4
                                                }
                                            />
                                        </TooltipSingle>
                                    );
                                }
                                return (
                                    <TooltipSingle
                                        key={`peron-${index}`}
                                        name={item.name}
                                        score={item.proximityPosition}
                                        position={item.position}
                                        department={item.department}
                                    >
                                        <Styles.ImgUserWithOut
                                            background={
                                                item.profiler ===
                                                    "Não respondido" ||
                                                item.profiler === "Expirado" ||
                                                item.profiler === ""
                                                    ? "#5F4A87"
                                                    : backgroundColorByProfiler(
                                                          item.profiler
                                                      )
                                            }
                                        >
                                            <Styles.InitalsName>
                                                {getInitalsName(item.name)}
                                            </Styles.InitalsName>
                                        </Styles.ImgUserWithOut>
                                    </TooltipSingle>
                                );
                            })}
                    </Styles.ContainerPerson>
                    <Styles.ContainerTitleBottom>
                        <Styles.FlexSize flex={6} />
                        <Styles.TextBox
                            labelVisibility={
                                work_values.total <= 20 ||
                                Object.keys(work_values).length === 0
                            }
                            onClick={() =>
                                _hanleCLickQudrant({
                                    title: "Trabalhar Valores",
                                    quadrant: 2,
                                })
                            }
                            textBottom
                        >{`ver todos os ${work_values.total}`}</Styles.TextBox>
                        <Styles.FlexSize flex={4} />

                        <Two />
                        <Styles.FlexSize flex={0.1} />
                    </Styles.ContainerTitleBottom>
                </Styles.Item>

                <Styles.ItemBottomRigth color="#FFEFE9" id="develop_culture">
                    <Styles.ContainerTitle>
                        <TooltipNumberQuadrant
                            numberQuadrant={4}
                            text=" Entrega acima do esperado. Observar e reforçar comportamentos esperados."
                        >
                            <Styles.TextBox isUpper>
                                DESENVOLVER COMPORTAMENTO
                            </Styles.TextBox>
                        </TooltipNumberQuadrant>

                        <Styles.WarrperCircleProfiler>
                            <CircleProfiler
                                small
                                type={develop_culture.predominant_profiler}
                            />
                        </Styles.WarrperCircleProfiler>
                    </Styles.ContainerTitle>
                    <Styles.ContainerPerson
                        justifyContent={develop_culture.total <= 4}
                    >
                        {develop_culture.people &&
                            develop_culture.people.map((item, index) => {
                                if (item.picture) {
                                    return (
                                        <TooltipSingle
                                            key={`peron-${index}`}
                                            name={item.name}
                                            score={item.proximityPosition}
                                            position={item.position}
                                            department={item.department}
                                        >
                                            <Styles.Person
                                                data={item.picture}
                                                marginRight={
                                                    develop_culture.total <= 4
                                                }
                                            />
                                        </TooltipSingle>
                                    );
                                }
                                return (
                                    <TooltipSingle
                                        key={`peron-${index}`}
                                        name={item.name}
                                        score={item.proximityPosition}
                                        position={item.position}
                                        department={item.department}
                                    >
                                        <Styles.ImgUserWithOut
                                            background={
                                                item.profiler ===
                                                    "Não respondido" ||
                                                item.profiler === "Expirado" ||
                                                item.profiler === ""
                                                    ? "#5F4A87"
                                                    : backgroundColorByProfiler(
                                                          item.profiler
                                                      )
                                            }
                                        >
                                            <Styles.InitalsName>
                                                {getInitalsName(item.name)}
                                            </Styles.InitalsName>
                                        </Styles.ImgUserWithOut>
                                    </TooltipSingle>
                                );
                            })}
                    </Styles.ContainerPerson>
                    <Styles.ContainerTitleBottom>
                        <Styles.FlexSize flex={6} />
                        <Styles.TextBox
                            labelVisibility={
                                develop_culture.total <= 20 ||
                                Object.keys(develop_culture).length === 0
                            }
                            onClick={() =>
                                _hanleCLickQudrant({
                                    title: "Desenvolver Cultura",
                                    quadrant: 4,
                                })
                            }
                            textBottom
                        >{`ver todos os ${develop_culture.total}`}</Styles.TextBox>
                        <Styles.FlexSize flex={4} />

                        <Four />
                        <Styles.FlexSize flex={0.1} />
                    </Styles.ContainerTitleBottom>
                </Styles.ItemBottomRigth>
            </Styles.Row>

            <Styles.EixoVertical id="eixoVerticalPrint">
                <Arrows width="28" height="28" fill="#EFEDF3" stroker="2" />
                <Styles.textEixo rotate="rotate">comportamento</Styles.textEixo>
            </Styles.EixoVertical>
            <Styles.EixoHorizonal>
                <Arrows width="28" height="28" fill="#EFEDF3" stroker="2" />
                <Styles.textEixo>DESEMPENHO</Styles.textEixo>
            </Styles.EixoHorizonal>
        </Styles.Container>
    );
};

export default NineBox;
