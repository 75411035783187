import styled from "styled-components";

export const ContainerSituationalIndicator = styled.div`
    height: 145px;
    align-self: center;
    margin-top: 10px;
`;

/** new */
export const Containermain = styled.div`
    display: flex;
    align-items: flex-end;
    padding-top: 30px;
    padding-left: 17px;

    @media (max-width: 702px) {
        padding-left: 0px;
    }
`;
export const Text = styled.div`
    margin: 9px 0 0 40px;
    border-left: 1px solid #efedf3;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 125%;
    text-align: justify;
    color: #8872b2;
    padding: 10px 18px;
    display: flex;
    align-items: center;
`;
export const ContainerSingle = styled.div`
    flex: 1.3;
    padding-right: 10px;
`;

export const textIndicador = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #8872b2;
    position: relative;
    top: 3px;

    @media (max-width: 702px) {
        font-size: 13px;
    }
`;
export const GraphicIndicadorContainer = styled.div`
    flex: 4;
    height: 8px;
    background-color: #efedf3;
    border-radius: 24px;
    position: relative;
`;
export const GrahicpColor = styled.div`
    flex: 4;
    height: 100%;
    ${({ width }) =>
        width ? ` width:${(100 * width) / 8}%; background-color:#5f4a87;` : ""}
    border-radius: 24px;
`;
export const ValueBars = styled.div`
    background: #efedf3;
    width: 100%;
    border-radius: 24px;
    height: 6px;
    flex: 1;
    position: relative;
`;
export const ItemStatusText = styled.div`
    color: #5f4a87;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 24px;
    position: absolute;
    top: -19px;
    span {
        font-family: "Roboto Condensed";
    }
`;

export const StatusBar = styled.div`
    background: #5f4a87;
    height: 100%;
    border-radius: 24px;
    ${({ widthValues }) =>
        widthValues ? `width:${(100 * widthValues) / 8}%` : "width:100%"}
`;

export const CircleIndicator = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: column;
    flex: 1;
    /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15); */
    width: 130px;
    height: 130px;
    border-radius: 80px;
    margin-top: 18px;
    align-items: flex-end;
    justify-content: flex-end;
    align-items: center;
    padding: 5px;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 720px) {
        margin: 0 auto;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    svg {
        width: 100%;
        height: 100%;
        transform: rotateX(180deg);
    }
`;

export const Meter = styled.svg``;

export const IconIndicators = styled.div`
    position: absolute;
    top: 40px;
    left: 59.5px;

    svg {
        width: 12px;
        height: 12px;
        color: rgb(47, 10, 119);
    }
`;

export const Circle = styled.img`
    position: absolute;
    left: 46%;
    top: 44%;
`;

export const Wrap = styled.div`
    margin-top: -6px;
    text-align: center;
`;

export const Title = styled.span`
    font-size: 14px;
    color: #5f4a87;
`;

export const TypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #5f4a87;
    font-weight: bold;
    font-size: 13px;
    line-height: 1;
    font-family: "Roboto Condensed";
`;

export const WrapContainer = styled.div`
    position: absolute;
    height: 50%;
    top: 49%;
    background: #fff;
    z-index: 10;
    width: 100%;
    border-bottom-left-radius: 200px;
    border-bottom-right-radius: 200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const Arrow1 = styled.img`
    position: absolute;
    left: 49%;
    top: -10%;
    height: 74.1px;
    ${({ animation }) =>
        animation
            ? ` -webkit-animation: animation1 1.7s ease-in-out both;
    animation: animation1 1.7s ease-in-out both;`
            : `  -webkit-transform: rotate(-80deg);
            transform: rotate(-80deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;`}

    opacity: 0.25;

    @keyframes animation1 {
        0% {
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        91% {
            -webkit-transform: rotate(-62deg);
            transform: rotate(-62deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        92% {
            -webkit-transform: rotate(-64deg);
            transform: rotate(-64deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        93% {
            -webkit-transform: rotate(-66deg);
            transform: rotate(-66deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        94% {
            -webkit-transform: rotate(-68deg);
            transform: rotate(-68deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        95% {
            -webkit-transform: rotate(-70deg);
            transform: rotate(-70deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        96% {
            -webkit-transform: rotate(-72deg);
            transform: rotate(-72deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        97% {
            -webkit-transform: rotate(-74deg);
            transform: rotate(-74deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        98% {
            -webkit-transform: rotate(-76deg);
            transform: rotate(-76deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        99% {
            -webkit-transform: rotate(-78deg);
            transform: rotate(-78deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        100% {
            -webkit-transform: rotate(-80deg);
            transform: rotate(-80deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
    }
`;

export const Arrow2 = styled.img`
    position: absolute;
    left: 49%;
    top: -10%;
    height: 74.1px;
    ${({ animation }) =>
        animation
            ? ` -webkit-animation: animation2 1.7s ease-in-out both;
    animation: animation2 1.7s ease-in-out both;`
            : `-webkit-transform: rotate(-57.5deg);
            transform: rotate(-57.5deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;`}

    opacity: 0.25;

    @keyframes animation2 {
        0% {
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        91% {
            -webkit-transform: rotate(-42deg);
            transform: rotate(-42deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        92% {
            -webkit-transform: rotate(-44deg);
            transform: rotate(-44deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        93% {
            -webkit-transform: rotate(-46deg);
            transform: rotate(-46deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        94% {
            -webkit-transform: rotate(-48deg);
            transform: rotate(-48deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        95% {
            -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        96% {
            -webkit-transform: rotate(-52deg);
            transform: rotate(-52deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        97% {
            -webkit-transform: rotate(-54deg);
            transform: rotate(-54deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        98% {
            -webkit-transform: rotate(-56deg);
            transform: rotate(-56deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        99% {
            -webkit-transform: rotate(-56deg);
            transform: rotate(-56deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        100% {
            -webkit-transform: rotate(-57.5deg);
            transform: rotate(-57.5deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
    }
`;

export const Arrow3 = styled.img`
    position: absolute;
    left: 49%;
    top: -10%;
    height: 74.1px;
    ${({ animation }) =>
        animation
            ? ` -webkit-animation: animation3 1.7s ease-in-out both;
    animation: animation3 1.7s ease-in-out both;`
            : ` -webkit-transform: rotate(-33deg);
            transform: rotate(-33deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;`}

    opacity: 0.25;

    @keyframes animation3 {
        0% {
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        91% {
            -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        92% {
            -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        93% {
            -webkit-transform: rotate(-26deg);
            transform: rotate(-26deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        94% {
            -webkit-transform: rotate(-28deg);
            transform: rotate(-28deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        95% {
            -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        96% {
            -webkit-transform: rotate(-32deg);
            transform: rotate(-32deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        97% {
            -webkit-transform: rotate(-34deg);
            transform: rotate(-34deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        98% {
            -webkit-transform: rotate(-35deg);
            transform: rotate(-35deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        99% {
            -webkit-transform: rotate(-35deg);
            transform: rotate(-35deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        100% {
            -webkit-transform: rotate(-33deg);
            transform: rotate(-33deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
    }
`;

export const Arrow4 = styled.img`
    position: absolute;
    left: 49%;
    top: -10%;
    height: 74.1px;
    ${({ animation }) =>
        animation
            ? ` -webkit-animation: animation4 1.7s ease-in-out both;
    animation: animation4 1.7s ease-in-out both;`
            : `-webkit-transform: rotate(-7deg);
            transform: rotate(-7deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;`}

    opacity: 0.25;

    @keyframes animation4 {
        0% {
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        91% {
            -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        92% {
            -webkit-transform: rotate(6deg);
            transform: rotate(6deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        93% {
            -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        94% {
            -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        95% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        96% {
            -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        97% {
            -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        98% {
            -webkit-transform: rotate(-6deg);
            transform: rotate(-6deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        99% {
            -webkit-transform: rotate(-7deg);
            transform: rotate(-7deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        100% {
            -webkit-transform: rotate(-7deg);
            transform: rotate(-7deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
    }
`;

export const Arrow5 = styled.img`
    position: absolute;
    left: 49%;
    top: -10%;
    height: 74.1px;
    ${({ animation }) =>
        animation
            ? ` -webkit-animation: animation5 1.7s ease-in-out both;
    animation: animation5 1.7s ease-in-out both;`
            : `-webkit-transform: rotate(17deg);
            transform: rotate(17deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;`}

    opacity: 0.25;

    @keyframes animation5 {
        0% {
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        91% {
            -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        92% {
            -webkit-transform: rotate(28deg);
            transform: rotate(28deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        93% {
            -webkit-transform: rotate(26deg);
            transform: rotate(26deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        94% {
            -webkit-transform: rotate(26deg);
            transform: rotate(26deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        95% {
            -webkit-transform: rotate(26deg);
            transform: rotate(26deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        96% {
            -webkit-transform: rotate(24deg);
            transform: rotate(24deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        97% {
            -webkit-transform: rotate(22deg);
            transform: rotate(22deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        98% {
            -webkit-transform: rotate(21deg);
            transform: rotate(21deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        99% {
            -webkit-transform: rotate(19deg);
            transform: rotate(19deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        100% {
            -webkit-transform: rotate(17deg);
            transform: rotate(17deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
    }
`;

export const Arrow6 = styled.img`
    position: absolute;
    left: 49%;
    top: -10%;
    height: 74.1px;
    ${({ animation }) =>
        animation
            ? ` -webkit-animation: animation6 1.7s ease-in-out both;
    animation: animation6 1.7s ease-in-out both;`
            : ` -webkit-transform: rotate(42deg);
            transform: rotate(42deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;`}

    opacity: 0.25;

    @keyframes animation6 {
        0% {
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        91% {
            -webkit-transform: rotate(54deg);
            transform: rotate(54deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        92% {
            -webkit-transform: rotate(54deg);
            transform: rotate(54deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        93% {
            -webkit-transform: rotate(52deg);
            transform: rotate(52deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        94% {
            -webkit-transform: rotate(52deg);
            transform: rotate(52deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        95% {
            -webkit-transform: rotate(52deg);
            transform: rotate(52deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        96% {
            -webkit-transform: rotate(50deg);
            transform: rotate(50deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        97% {
            -webkit-transform: rotate(48deg);
            transform: rotate(48deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        98% {
            -webkit-transform: rotate(46deg);
            transform: rotate(46deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        99% {
            -webkit-transform: rotate(44deg);
            transform: rotate(44deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        100% {
            -webkit-transform: rotate(42deg);
            transform: rotate(42deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
    }
`;

export const Arrow7 = styled.img`
    position: absolute;
    left: 49%;
    top: -10%;
    height: 74.1px;
    ${({ animation }) =>
        animation
            ? ` -webkit-animation: animation7 1.7s ease-in-out both;
    animation: animation7 1.7s ease-in-out both;`
            : `-webkit-transform: rotate(67deg);
            transform: rotate(67deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;`}

    opacity: 0.25;

    @keyframes animation7 {
        0% {
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        91% {
            -webkit-transform: rotate(54deg);
            transform: rotate(80deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        92% {
            -webkit-transform: rotate(54deg);
            transform: rotate(78deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        93% {
            -webkit-transform: rotate(76deg);
            transform: rotate(76deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        94% {
            -webkit-transform: rotate(76deg);
            transform: rotate(76deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        95% {
            -webkit-transform: rotate(76deg);
            transform: rotate(76deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        96% {
            -webkit-transform: rotate(74deg);
            transform: rotate(74deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        97% {
            -webkit-transform: rotate(72deg);
            transform: rotate(72deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        98% {
            -webkit-transform: rotate(70deg);
            transform: rotate(70deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        99% {
            -webkit-transform: rotate(69deg);
            transform: rotate(69deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        100% {
            -webkit-transform: rotate(67deg);
            transform: rotate(67deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
    }
`;

export const Arrow8 = styled.img`
    position: absolute;
    left: 49%;
    top: -10%;
    height: 74.1px;
    ${({ animation }) =>
        animation
            ? ` -webkit-animation: animation8 1.7s ease-in-out both;
    animation: animation8 1.7s ease-in-out both;`
            : `  -webkit-transform: rotate(82deg);
            transform: rotate(82deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;`}

    opacity: 0.25;

    @keyframes animation8 {
        0% {
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        91% {
            -webkit-transform: rotate(54deg);
            transform: rotate(90deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        92% {
            -webkit-transform: rotate(54deg);
            transform: rotate(89deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        93% {
            -webkit-transform: rotate(52deg);
            transform: rotate(88deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        94% {
            -webkit-transform: rotate(52deg);
            transform: rotate(88deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        95% {
            -webkit-transform: rotate(52deg);
            transform: rotate(88deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        96% {
            -webkit-transform: rotate(50deg);
            transform: rotate(88deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        97% {
            -webkit-transform: rotate(86deg);
            transform: rotate(86deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        98% {
            -webkit-transform: rotate(84deg);
            transform: rotate(84deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        99% {
            -webkit-transform: rotate(84deg);
            transform: rotate(84deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
        100% {
            -webkit-transform: rotate(82deg);
            transform: rotate(82deg);
            -webkit-transform-origin: bottom;
            transform-origin: bottom;
        }
    }
`;
