import React, { useEffect, useState, useCallback } from "react";
import getProfilerName from "../../../../utils/functions/getProfilerName";
import ChartSolides from "../../../../components/charts";
import moment from "moment";
import * as Styled from "./style";
import CircleProfile from "../../../../components/circleProfile";
import imgDefault from "../../../../assets/svg/logoDefaultEnterprise.svg";
import getStaticTextsProfiler from "../../../../utils/functions/getStaticTextsProfiler";
import getTextLevel from "../../../../utils/functions/getTextLevel";

export default ({
  textValidation,
  chartData,
  dataProfiler,
  gender,
  image,
  name,
  dateProfilerCurrent,
  typeReports,
  profileTexts,
  infoPerson,
}) => {
  const [chartProfiler, setChartProfiler] = useState({});
  const staticTextsProfiler = getStaticTextsProfiler();

  // const [getTypePerfil, setGetTypePerfil] = useState({});
  useEffect(() => {
    setChartProfiler(chartData);
  }, [chartData, chartProfiler]);

  const chartProfilerData = useCallback((chartDataParams) => {
    setChartProfiler(chartDataParams);
  }, []);

  useEffect(() => {
    chartProfilerData(chartData);
  }, [chartProfilerData, chartData]);

  const _handleCalcTime = (time) => {
    const seconds = time;
    const secondsToFormat = moment.duration(seconds, "seconds").asMinutes();
    const timeMinutes = secondsToFormat.toFixed(0);
    return timeMinutes < 1 
    ? `${time} ${staticTextsProfiler.complementares.segundos}`
    : `${timeMinutes} ${timeMinutes > 1 ? staticTextsProfiler.complementares.minutos : staticTextsProfiler.complementares.minuto}`;
  };

  /** render graphics */
  const RenderGraphics = ({ children, dontShow = [] }) => {
    const permission = dontShow.includes(typeReports);

    if (!permission) {
      return children;
    } else {
      return null;
    }
  };

  /** render one collumn */
  const renderTextProfilers = (
    dontShow = [],
    texts = {},
    id = "",
    marginTop = 0,
    marginBottom = 0
  ) => {
    const { titulo, texto } = texts ? texts : "";

    if (dontShow.includes(typeReports) || typeReports === "") return false;
    if (!titulo && !texto) return false;
    if (titulo === "Sub-características") {
      return (
        <Styled.ContainerTextReport
          id={id}
          marginTop={marginTop}
          marginBottom={marginBottom}
        >
          <Styled.TitleTextReports>{titulo}</Styled.TitleTextReports>
          <Styled.ContentTextReports>
            {texto.split(":")[0]}:<strong>{texto.split(":")[1]}</strong>
          </Styled.ContentTextReports>
        </Styled.ContainerTextReport>
      );
    }
    return (
      <>
        <Styled.ContainerTextReport
          id={id}
          marginTop={marginTop}
          marginBottom={marginBottom}
        >
          <Styled.TitleTextReports>
            {titulo === "Inicial" ? "" : titulo}
          </Styled.TitleTextReports>
          <Styled.ContentTextReports>
            {`${titulo === "Inicial" ? name.split(" ")[0] : ""} `}
            {texto}
          </Styled.ContentTextReports>
        </Styled.ContainerTextReport>
      </>
    );
  };
  /** render two collumn */
  const renderTwoTextProfilers = (
    dontShow = [],
    textsOne = {},
    textTwo = {},
    twoColmun = true,
    id = "",
    marginTop = 0,
    showTitle = true,
  ) => {
    let title1 = "";
    let title2 = "";
    if (showTitle && textsOne) {
      title1 = textsOne.titulo;
      title2 = textTwo.titulo;
    }
    const texto1 = textsOne ? textsOne.texto : "";
    const text2 = textTwo ? textTwo.texto : "";

    if (dontShow.includes(typeReports) || !typeReports) return false;
    return (
      <>
        <Styled.ContainerTextReport
          twoColmun={twoColmun}
          id={id}
          marginTop={marginTop}
        >
          <Styled.ColumnText paddingRight={30}>
            <Styled.TitleTextReports>{title1}</Styled.TitleTextReports>
            <Styled.ContentTextReports>{texto1}</Styled.ContentTextReports>
          </Styled.ColumnText>
          <Styled.ColumnText paddingLeft={30}>
            <Styled.TitleTextReports>{title2}</Styled.TitleTextReports>
            <Styled.ContentTextReports>{text2}</Styled.ContentTextReports>
          </Styled.ColumnText>
        </Styled.ContainerTextReport>
      </>
    );
  };

  const _showTextReport = (dontShow = [], objText = {}) => {
    if (dontShow.includes(typeReports) || !typeReports) return false;
    return objText;
  };
  const Result = () => {
    return (
      <>
        <Styled.BoxInfoPerson>
          <Styled.BoxInfoPersonEnterprise>
            {infoPerson.logo !== "" ? (
              <Styled.LogoImage src={infoPerson.logo} alt="" />
            ) : (
              <>
                <Styled.LogoImage src={imgDefault} alt="" nullable />
                <Styled.LogoImageText>
                  {infoPerson.nameEnterprise}
                </Styled.LogoImageText>
              </>
            )}
          </Styled.BoxInfoPersonEnterprise>
          <Styled.BoxInfoPersonPersonal>
            <Styled.BoxInfoPersonPersonalLeft>
              <Styled.BoxInfoPersonPersonalTitle>
                {`${infoPerson.name} ${
                  infoPerson.yearsOld !== "" && ", " + infoPerson.yearsOld
                } `}
              </Styled.BoxInfoPersonPersonalTitle>
              <Styled.BoxInfoPersonPersonalDesc>
                {infoPerson.position !== "" && infoPerson.position}
              </Styled.BoxInfoPersonPersonalDesc>
              <Styled.BoxInfoPersonPersonalDesc>
                {infoPerson.email !== "" && infoPerson.email}
              </Styled.BoxInfoPersonPersonalDesc>
              <Styled.BoxInfoPersonPersonalDesc>
                {infoPerson.phone !== "" && infoPerson.phone}
              </Styled.BoxInfoPersonPersonalDesc>
            </Styled.BoxInfoPersonPersonalLeft>
            <Styled.BoxInfoPersonPersonalRight>
              <CircleProfile medium type={infoPerson.profiler} />
            </Styled.BoxInfoPersonPersonalRight>
          </Styled.BoxInfoPersonPersonal>
        </Styled.BoxInfoPerson>

        <Styled.SpaceBox>
          {!!textValidation && (
            <Styled.BoxTextValidation>
              <Styled.TextValidation>
                {textValidation.split("<br>")}
              </Styled.TextValidation>
            </Styled.BoxTextValidation>
          )}
        </Styled.SpaceBox>

        <Styled.Text id="profilesBar">
          {staticTextsProfiler.complementares.neste_momento.replace('%{nome}', name.split(" ")[0])}
        </Styled.Text>
        <Styled.Title bold>
          {getProfilerName(
            dataProfiler.chart_data.profiler,
            gender === "masculino"
              ? "male"
              : gender === "feminino"
              ? "female"
              : "not_binary"
          )}
        </Styled.Title>
        <Styled.TextDate>{staticTextsProfiler.complementares.em} {dateProfilerCurrent}</Styled.TextDate>
        <RenderGraphics dontShow={["feedback"]}>
          <Styled.SpaceBox>
            <Styled.ContentCulturaAtualContentCard>
              {chartProfiler.predominant_profiler && (
                <ChartSolides
                  type="profilesBar"
                  isTooltip
                  dominante={chartProfiler.profiler}
                  profilesBar={{
                    executor: `${chartProfiler.predominant_profiler.executor.toFixed(
                      2
                    )}%`,
                    comunicador: `${chartProfiler.predominant_profiler.comunicador.toFixed(
                      2
                    )}%`,
                    planejador: `${chartProfiler.predominant_profiler.planejador.toFixed(
                      2
                    )}%`,
                    analista: `${chartProfiler.predominant_profiler.analista.toFixed(
                      2
                    )}%`,
                    tooltipExecutor:
                      chartProfiler.predominant_profiler.executor,
                    tooltipComunicador:
                      chartProfiler.predominant_profiler.comunicador,
                    tooltipPlanejador:
                      chartProfiler.predominant_profiler.planejador,
                    tooltipAnalista:
                      chartProfiler.predominant_profiler.analista,

                    predominant_energy_level: {
                      executor:
                        chartProfiler.predominant_energy_level &&
                        chartProfiler.predominant_energy_level.executor,
                      planejador:
                        chartProfiler.predominant_energy_level &&
                        chartProfiler.predominant_energy_level.planejador,
                      comunicador:
                        chartProfiler.predominant_energy_level &&
                        chartProfiler.predominant_energy_level.comunicador,
                      analista:
                        chartProfiler.predominant_energy_level &&
                        chartProfiler.predominant_energy_level.analista,
                    },
                  }}
                />
              )}
            </Styled.ContentCulturaAtualContentCard>
          </Styled.SpaceBox>
        </RenderGraphics>
        {/** text inital */}
        {renderTextProfilers(["simple"], profileTexts.inicial, "initial", 40)}
        {/** text Sub-características */}
        {renderTextProfilers(
          ["simple"],
          profileTexts.sub_caracteristicas,
          "subCaracteristicas"
        )}
        {/** text habilidades_basica */}
        {renderTextProfilers(
          ["simple"],
          profileTexts.habilidades_basica,
          "habilidadeBasica"
        )}
        {/** text habilidades_comuns */}
        {renderTextProfilers(
          ["simple"],
          profileTexts.habilidades_comuns,
          "habilidadeComuns"
        )}
        <RenderGraphics dontShow={["feedback"]}>
          <Styled.SpaceBox id="positiveNegative">
            <Styled.Description>
              <div dangerouslySetInnerHTML={{ __html: staticTextsProfiler.complementares.distribuicao_fatores }} />
            </Styled.Description>
            {dataProfiler.chart_data && (
              <ChartSolides
                type="positiveNegative"
                positiveNegative={{
                  data: {
                    positive: {
                      executor: `${dataProfiler.chart_data.detailed_profilers.executor[0].toFixed(
                        1
                      )}%`,
                      planejador: `${dataProfiler.chart_data.detailed_profilers.planejador[0].toFixed(
                        1
                      )}%`,
                      comunicador: `${dataProfiler.chart_data.detailed_profilers.comunicador[0].toFixed(
                        1
                      )}%`,
                      analista: `${dataProfiler.chart_data.detailed_profilers.analista[0].toFixed(
                        1
                      )}%`,
                    },
                    negative: {
                      executor: `${dataProfiler.chart_data.detailed_profilers.executor[1].toFixed(
                        1
                      )}%`,
                      planejador: `${dataProfiler.chart_data.detailed_profilers.planejador[1].toFixed(
                        1
                      )}%`,
                      comunicador: `${dataProfiler.chart_data.detailed_profilers.comunicador[1].toFixed(
                        1
                      )}%`,
                      analista: `${dataProfiler.chart_data.detailed_profilers.analista[1].toFixed(
                        1
                      )}%`,
                    },
                  },
                }}
              />
            )}
          </Styled.SpaceBox>
        </RenderGraphics>
        {/** text vantagens, desvantages */}
        {renderTwoTextProfilers(
          ["regular", "simple"],
          profileTexts.vantagens,
          profileTexts.desvantagens,
          true,
          "vantagensDesvantagens",
          40
        )}
        <RenderGraphics dontShow={["feedback"]}>
          <Styled.SpaceBox id="situationalIndicator" margin="30px 0px 0px 0px">
            <Styled.TitleSecondary center>
              {staticTextsProfiler.complementares.indicadores_situacionais}
            </Styled.TitleSecondary>

            {chartProfiler.situational_indicators && (
              <>
                <ChartSolides
                  isTooltip={["regular", "simple"].includes(typeReports)}
                  type="situationalIndicator"
                  situationalIndicator={{
                    title: staticTextsProfiler.situational_Indicator.energia.titulo,
                    type: chartProfiler.situational_indicators.nivelenergia,

                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.situational_Indicator.energia.texto
                    ),
                  }}
                />
                <ChartSolides
                  isTooltip={["regular", "simple"].includes(typeReports)}
                  type="situationalIndicator"
                  situationalIndicator={{
                    title: staticTextsProfiler.situational_Indicator.nivelestress.titulo,
                    type: chartProfiler.situational_indicators.nivelestress,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.situational_Indicator.nivelestress.texto
                    ),
                  }}
                />
                <ChartSolides
                  isTooltip={["regular", "simple"].includes(typeReports)}
                  type="situationalIndicator"
                  situationalIndicator={{
                    title: staticTextsProfiler.situational_Indicator.ia_nivel.titulo,
                    type: chartProfiler.situational_indicators.ia_nivel,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.situational_Indicator.ia_nivel.texto
                    ),
                  }}
                />

                <ChartSolides
                  isTooltip={["regular", "simple"].includes(typeReports)}
                  type="situationalIndicator"
                  situationalIndicator={{
                    title: staticTextsProfiler.situational_Indicator.moral_nivel.titulo,
                    type: chartProfiler.situational_indicators.moral_nivel,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.situational_Indicator.moral_nivel.texto
                    ),
                  }}
                />

                <ChartSolides
                  isTooltip={["regular", "simple"].includes(typeReports)}
                  type="situationalIndicator"
                  situationalIndicator={{
                    title: staticTextsProfiler.situational_Indicator.nivelip.titulo,
                    type: chartProfiler.situational_indicators.nivelip,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.situational_Indicator.nivelip.texto
                    ),
                  }}
                />
                <ChartSolides
                  isTooltip={["regular", "simple"].includes(typeReports)}
                  type="situationalIndicator"
                  situationalIndicator={{
                    title: staticTextsProfiler.situational_Indicator.flex_nivel.titulo,
                    type: chartProfiler.situational_indicators.flex_nivel,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.situational_Indicator.flex_nivel.texto
                    ),
                  }}
                />

                <ChartSolides
                  isTooltip={["regular", "simple"].includes(typeReports)}
                  type="situationalIndicator"
                  situationalIndicator={{
                    title: staticTextsProfiler.situational_Indicator.amplitude_nivel.titulo,
                    type: chartProfiler.situational_indicators.amplitude_nivel,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.situational_Indicator.amplitude_nivel.texto
                    ),
                  }}
                />
                <ChartSolides
                  isTooltip={["regular", "simple"].includes(typeReports)}
                  type="situationalIndicator"
                  situationalIndicator={{
                    title: staticTextsProfiler.situational_Indicator.nivel_energperfil.titulo,
                    type: chartProfiler.situational_indicators
                      .nivel_energperfil,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.situational_Indicator.nivel_energperfil.texto
                    ),
                  }}
                />
                <ChartSolides
                  isTooltip={["regular", "simple"].includes(typeReports)}
                  type="situationalIndicator"
                  situationalIndicator={{
                    title: staticTextsProfiler.situational_Indicator.nivel_incitab.titulo,
                    type: chartProfiler.situational_indicators.nivel_incitab,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.situational_Indicator.nivel_incitab.texto
                    ),
                  }}
                />
              </>
            )}
          </Styled.SpaceBox>
        </RenderGraphics>

        <Styled.SpaceBox>
          <Styled.ContentText></Styled.ContentText>
        </Styled.SpaceBox>

        <RenderGraphics dontShow={["feedback"]}>
          <Styled.SpaceBox id="chartinteraction">
            {dataProfiler.chart_data && (
              <ChartSolides
                type="chartinteraction"
                mediumRequirement={{
                  voce: {
                    executor: `${dataProfiler.chart_data.environment_requeriment[0].data[0]}`,
                    comunicador: `${dataProfiler.chart_data.environment_requeriment[0].data[1]}`,
                    planejador: `${dataProfiler.chart_data.environment_requeriment[0].data[2]}`,
                    analista: `${dataProfiler.chart_data.environment_requeriment[0].data[3]}`,
                  },
                  meio: {
                    executor: `${dataProfiler.chart_data.environment_requeriment[1].data[0]}`,
                    comunicador: `${dataProfiler.chart_data.environment_requeriment[1].data[1]}`,
                    planejador: `${dataProfiler.chart_data.environment_requeriment[1].data[2]}`,
                    analista: `${dataProfiler.chart_data.environment_requeriment[1].data[3]}`,
                  },
                  flexibilidade: {
                    executor: `${dataProfiler.chart_data.environment_requeriment[2].data[0]}`,
                    comunicador: `${dataProfiler.chart_data.environment_requeriment[2].data[1]}`,
                    planejador: `${dataProfiler.chart_data.environment_requeriment[2].data[2]}`,
                    analista: `${dataProfiler.chart_data.environment_requeriment[2].data[3]}`,
                  },
                }}
                searchResults={{
                  voce: {
                    dominante: `${dataProfiler.chart_data.reach_results[0].data[0]}`,
                    informal: `${dataProfiler.chart_data.reach_results[0].data[1]}`,
                    condescendente: `${dataProfiler.chart_data.reach_results[0].data[2]}`,
                    formal: `${dataProfiler.chart_data.reach_results[0].data[3]}`,
                  },
                  meio: {
                    dominante: `${dataProfiler.chart_data.reach_results[1].data[0]}`,
                    informal: `${dataProfiler.chart_data.reach_results[1].data[1]}`,
                    condescendente: `${dataProfiler.chart_data.reach_results[1].data[2]}`,
                    formal: `${dataProfiler.chart_data.reach_results[1].data[3]}`,
                  },
                  flexibilidade: {
                    dominante: `${dataProfiler.chart_data.reach_results[2].data[0]}`,
                    informal: `${dataProfiler.chart_data.reach_results[2].data[1]}`,
                    condescendente: `${dataProfiler.chart_data.reach_results[2].data[2]}`,
                    formal: `${dataProfiler.chart_data.reach_results[2].data[3]}`,
                  },
                }}
              />
            )}
          </Styled.SpaceBox>
        </RenderGraphics>

        <RenderGraphics dontShow={["feedback"]}>
          {/** text interacao_ambiente, estilio_lideranca */}
          {renderTwoTextProfilers(
            ["regular", "simple"],
            staticTextsProfiler.interacao_ambiente,
            staticTextsProfiler.estilio_lideranca,
            true,
            "",
            0,
            false
          )}
        </RenderGraphics>

        {/** text estilo_gestao_requerido */}
        {renderTextProfilers(
          ["regular", "simple"],
          profileTexts.estilo_gestao_requerido,
          "estiloGestaoRequerido"
        )}

        {/** text estilo_lideranca */}
        {renderTextProfilers(
          ["regular", "simple"],
          profileTexts.estilo_lideranca,
          "estiloLideranca"
        )}

        {/** text estilo_comunicacao */}
        {renderTextProfilers(
          ["regular", "simple"],
          profileTexts.estilo_comunicacao,
          "estiloComunicacao"
        )}
        {/** text ambiente_trabalho */}
        {renderTextProfilers(
          ["regular", "simple"],
          profileTexts.ambiente_trabalho,
          "ambienteTrabalho"
        )}
        {/** text desempenho_tarefas */}
        {renderTextProfilers(
          ["regular", "simple"],
          profileTexts.desempenho_tarefas,
          "desempenhoTarefas"
        )}

        {/** text estilo_vendas */}
        {renderTextProfilers(
          ["regular", "simple"],
          profileTexts.estilo_vendas,
          "estiloVendas"
        )}

        {/** text fatores_motivadores */}
        {renderTextProfilers(
          ["regular", "simple"],
          profileTexts.fatores_motivadores,
          "fatoresMotivacionais"
        )}

        {/** text valoriza_outros_por */}
        {renderTextProfilers(
          ["regular", "simple"],
          profileTexts.valoriza_outros_por,
          "valorizaOutrosPor"
        )}

        {/** text necessidades_basicas */}
        {renderTextProfilers(
          ["regular", "simple"],
          profileTexts.necessidades_basicas,
          "necessidadeBasicas"
        )}

        {/** text fatores_afastamento */}
        {renderTextProfilers(
          ["regular", "simple"],
          profileTexts.fatores_afastamento,
          "fatoresAfastamento"
        )}

        {/** text forma_busca_resultados */}
        {renderTextProfilers(
          ["regular", "simple"],
          profileTexts.forma_busca_resultados,
          "formaBuscaResultados"
        )}

        {/** text organizacao_planejamento */}
        {renderTextProfilers(
          ["regular", "simple"],
          profileTexts.organizacao_planejamento,
          "organizacaoPlanejamento"
        )}
        {/** text reacao_sob_pressao */}
        {renderTextProfilers(
          ["regular", "simple"],
          profileTexts.reacao_sob_pressao,
          "reacaoSobPressao"
        )}
        {/** text relacao_com_mudancas */}
        {renderTextProfilers(
          ["regular", "simple"],
          profileTexts.relacao_com_mudancas,
          "relacaoComMudancas"
        )}

        {/** text relacionamentos */}
        {renderTextProfilers(
          ["regular", "simple"],
          profileTexts.relacionamentos,
          "relacionamentos"
        )}

        {/** text relacionando_com_outros */}
        {renderTextProfilers(
          ["simple"],
          profileTexts.relacionando_com_outros,
          "relacionaComOutros"
        )}

        {/** text tomando_decisoes */}
        {renderTextProfilers(
          ["simple"],
          profileTexts.tomando_decisoes,
          "tomandoDecisoes",
          0,
          60
        )}
        <RenderGraphics dontShow={["feedback"]}>
          <Styled.SpaceBox id="competences" margin="0">
            <Styled.TitleSecondary>{staticTextsProfiler.complementares.competencias}</Styled.TitleSecondary>
          </Styled.SpaceBox>
          <Styled.SpaceBox margin="0px">
            {dataProfiler && dataProfiler.chart_data && (
              <ChartSolides
                isTooltip={["regular", "simple"].includes(typeReports)}
                type="competences"
                competences={[
                  {
                    type: "tolerancia",
                    value: dataProfiler.chart_data.skills.tolerancia,
                    status: dataProfiler.chart_data.skills_labels.tolerancia,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.tolerancia
                    ),
                  },
                  {
                    type: "planejamento",
                    value: dataProfiler.chart_data.skills.planejamento,
                    status: dataProfiler.chart_data.skills_labels.planejamento,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.planejamento
                    ),
                  },
                  {
                    type: "empatia",
                    value: dataProfiler.chart_data.skills.empatia,
                    status: dataProfiler.chart_data.skills_labels.empatia,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.empatia
                    ),
                  },
                  {
                    type: "capac_ouvir",
                    value: dataProfiler.chart_data.skills.capac_ouvir,
                    status: dataProfiler.chart_data.skills_labels.capac_ouvir,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.capac_ouvir
                    ),
                  },
                  {
                    type: "concentracao",
                    value: dataProfiler.chart_data.skills.concentracao,
                    status: dataProfiler.chart_data.skills_labels.concentracao,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.concentracao
                    ),
                  },
                  {
                    type: "condescendencia",
                    value: dataProfiler.chart_data.skills.condescendencia,
                    status:
                      dataProfiler.chart_data.skills_labels.condescendencia,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.condescendencia
                    ),
                  },
                  {
                    type: "perfil_tecnico",
                    value: dataProfiler.chart_data.skills.perfil_tecnico,
                    status:
                      dataProfiler.chart_data.skills_labels.perfil_tecnico,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.perfil_tecnico
                    ),
                  },
                  {
                    type: "organizacao",
                    value: dataProfiler.chart_data.skills.organizacao,
                    status: dataProfiler.chart_data.skills_labels.organizacao,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.organizacao
                    ),
                  },
                  {
                    type: "detalhismo",
                    value: dataProfiler.chart_data.skills.detalhismo,
                    status: dataProfiler.chart_data.skills_labels.detalhismo,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.detalhismo
                    ),
                  },
                  {
                    type: "rigorosidade",
                    value: dataProfiler.chart_data.skills.rigorosidade,
                    status: dataProfiler.chart_data.skills_labels.rigorosidade,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.rigorosidade
                    ),
                  },
                  {
                    type: "orientadorsultado",
                    value: dataProfiler.chart_data.skills.orientadorsultado,
                    status:
                      dataProfiler.chart_data.skills_labels.orientadorsultado,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.orientadorsultado
                    ),
                  },
                  {
                    type: "multitarefas",
                    value: dataProfiler.chart_data.skills.multitarefas,
                    status: dataProfiler.chart_data.skills_labels.multitarefas,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.multitarefas
                    ),
                  },
                  {
                    type: "automotivacao",
                    value: dataProfiler.chart_data.skills.automotivacao,
                    status: dataProfiler.chart_data.skills_labels.automotivacao,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.automotivacao
                    ),
                  },
                  {
                    type: "proatividade",
                    value: dataProfiler.chart_data.skills.proatividade,
                    status: dataProfiler.chart_data.skills_labels.proatividade,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.proatividade
                    ),
                  },
                  {
                    type: "dinamismo",
                    value: dataProfiler.chart_data.skills.dinamismo,
                    status: dataProfiler.chart_data.skills_labels.dinamismo,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.dinamismo
                    ),
                  },
                  {
                    type: "dominancia",
                    value: dataProfiler.chart_data.skills.dominancia,
                    status: dataProfiler.chart_data.skills_labels.dominancia,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.dominancia
                    ),
                  },
                  {
                    type: "extroversao",
                    value: dataProfiler.chart_data.skills.extroversao,
                    status: dataProfiler.chart_data.skills_labels.extroversao,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.extroversao
                    ),
                  },
                  {
                    type: "relac_interpes",
                    value: dataProfiler.chart_data.skills.relac_interpes,
                    status:
                      dataProfiler.chart_data.skills_labels.relac_interpes,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.relac_interpes
                    ),
                  },
                  {
                    type: "sociabilidade",
                    value: dataProfiler.chart_data.skills.sociabilidade,
                    status: dataProfiler.chart_data.skills_labels.sociabilidade,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.sociabilidade
                    ),
                  },
                  {
                    type: "orientadorelacionamento",
                    value:
                      dataProfiler.chart_data.skills.orientadorelacionamento,
                    status:
                      dataProfiler.chart_data.skills_labels
                        .orientadorelacionamento,
                    textIndicador: _showTextReport(
                      ["regular", "simple"],
                      staticTextsProfiler.competences.orientadorelacionamento
                    ),
                  },
                ]}
              />
            )}
          </Styled.SpaceBox>
        </RenderGraphics>

        <RenderGraphics dontShow={["feedback"]}>
          <Styled.SpaceBox id="talentsArea" margin="60px 0 0 0 ">
            <Styled.TitleSecondary>{staticTextsProfiler.complementares.area_talentos}</Styled.TitleSecondary>
          </Styled.SpaceBox>
          <Styled.SpaceBox margin="0 0 40px 0">
            {dataProfiler.chart_data && dataProfiler.chart_data.talent_area && (
              <ChartSolides
                isTooltip={["regular", "simple"].includes(typeReports)}
                type="talentsArea"
                talentsArea={{
                  dx1: dataProfiler.chart_data.talent_area.dx,
                  dy1: dataProfiler.chart_data.talent_area.dy,
                  dx2: dataProfiler.chart_data.talent_area.dx2,
                  dy2: dataProfiler.chart_data.talent_area.dy2,
                  image,
                  name,
                  textIndicador: staticTextsProfiler.talentsArea,
                  showDescription: !["regular", "simple"].includes(typeReports)
                }}
              />
            )}
          </Styled.SpaceBox>
        </RenderGraphics>
        <Styled.SpaceBox>
          <Styled.ContentText></Styled.ContentText>
          {!!chartProfiler.situational_indicators && (
            <Styled.InfoTime>
              <Styled.Time>
                {staticTextsProfiler.complementares.tempo_resposta}: {_handleCalcTime(chartProfiler.time || 0)}
              </Styled.Time>
              <Styled.Nivel>
                {getTextLevel(
                  chartProfiler.situational_indicators.niveltempo
                )}
              </Styled.Nivel>
            </Styled.InfoTime>
          )}
        </Styled.SpaceBox>
      </>
    );
  };
  return <>{!!chartProfiler ? <Result /> : null}</>;
};
