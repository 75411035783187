import React from "react";
import Circle from "./dist";

const circleSize = {
  small: 32,
  medium: 64,
  large: 96,
};

export default function CirclePercentage({
  data = {
    percentage: 25,
    progressColor: "#601e82",
    bgColor: "whitesmoke",
    textColor: "#8872b2",
    lineWidth: 50,
    roundedStroke: true,
    showPercentage: true,
  },
}) {
  return (
    <Circle
      animate={false} // Boolean: Animated/Static progress
      responsive={false} // Boolean: Make SVG adapt to parent size
      size={circleSize[data.size] || 90} // Number: Defines the size of the circle.
      lineWidth={data.lineWidth} // Number: Defines the thickness of the circle's stroke.
      progress={data.percentage} // Number: Update to change the progress and percentage.
      progressColor={data.progressColor} // String: Color of "progress" portion of circle.
      bgColor={data.bgColor} // String: Color of "empty" portion of circle.
      textColor={data.textColor} // String: Color of percentage text color.
      textStyle={{
        font: "bold 7.5rem Roboto Condensed", // CSSProperties: Custom styling for percentage.
      }}
      percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
      roundedStroke={data.roundedStroke} // Boolean: Rounded/Flat line ends
      showPercentage={data.showPercentage} // Boolean: Show/hide percentage.
      showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
    />
  );
}
