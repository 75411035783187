import React, { useState, useEffect } from "react";
import * as styled from "./style";

const ChartSvg = ({
  options = {
    executor: -35,
    comunicador: 0,
    analista: 0,
    planejador: 0,
  },
  type,
  valueIntial,
  color,
  searchResults,
}) => {
  const widthGrid = 200;
  const opacity = "0.5";

  const exec = valueIntial - options.executor * 2;
  const comu = valueIntial - options.comunicador * 2;
  const ana = valueIntial - options.analista * 2;
  const plan = valueIntial - options.planejador * 2;

  const domAux = valueIntial - options.dominante * 2;
  const infoAux = valueIntial - options.informal * 2;
  const condAux = valueIntial - options.condescendente * 2;
  const forAux = valueIntial - options.formal * 2;

  const [executor, setExecutor] = useState(exec);
  const [comunicador, setComunicador] = useState(comu);
  const [analista, setAnalista] = useState(ana);
  const [planejador, setPlanejador] = useState(plan);

  const [dominante, setDominante] = useState(domAux);
  const [informal, setinformal] = useState(infoAux);
  const [condescendente, setCondescendente] = useState(condAux);
  const [formal, setFormal] = useState(forAux);

  const [strokeWidth, setStrokeWidth] = useState(1.5);

  useEffect(() => {
    if (!searchResults) {
      options.executor <= 50 && options.executor >= -50
        ? setExecutor(exec)
        : setExecutor(valueIntial);
      options.comunicador <= 50 && options.comunicador >= -50
        ? setComunicador(comu)
        : setComunicador(valueIntial);
      options.analista <= 50 && options.analista >= -50
        ? setAnalista(ana)
        : setAnalista(valueIntial);
      options.planejador <= 50 && options.planejador >= -50
        ? setPlanejador(plan)
        : setPlanejador(valueIntial);
    } else {
      options.dominante <= 50 && options.dominante >= -50
        ? setDominante(domAux)
        : setDominante(valueIntial);
      options.informal <= 50 && options.informal >= -50
        ? setinformal(infoAux)
        : setinformal(valueIntial);
      options.condescendente <= 50 && options.condescendente >= -50
        ? setCondescendente(condAux)
        : setCondescendente(valueIntial);
      options.formal <= 50 && options.formal >= -50
        ? setFormal(forAux)
        : setFormal(valueIntial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showTooltip = (evt, text, color, position) => {
    if (window.innerWidth >= 700) {
      const tooltip = document.getElementById("tooltip");
      tooltip.innerHTML = text;
      tooltip.style.border = "1px solid " + color;
      tooltip.style.color = color;
      tooltip.style.left = evt.pageX - 500 + "px";
      tooltip.style.top = position + 55 + "px";
      tooltip.style.visibility = "visible";
      tooltip.style.zIndex = 99999;
      tooltip.addEventListener("mouseout", hideTooltip);
      setStrokeWidth(2.1);
    }
  };

  const hideTooltip = () => {
    const tooltip = document.getElementById("tooltip");
    tooltip.style.visibility = "hidden";
    tooltip.removeEventListener("mouseout", showTooltip);
    setStrokeWidth(1.5);
  };

  const meio = (
    <>
      <styled.Group>
        <rect
          x="-5.5"
          y={(searchResults ? dominante : executor) - 5.5}
          width="12"
          height="12"
          rx="2"
          stroke={color}
          fill={color}
          strokeWidth={strokeWidth}
          onMouseEnter={(evt) =>
            showTooltip(
              evt,
              `Como o ambiente a exige ser: ${Number(
                searchResults ? options.dominante : options.executor
              ).toFixed(2)}`,
              color,
              searchResults ? dominante : executor
            )
          }
          onMouseLeave={hideTooltip}
        />
        <rect
          x={widthGrid / 3 - 5.5}
          y={(searchResults ? informal : comunicador) - 5.5}
          width="12"
          height="12"
          rx="2"
          stroke={color}
          fill={color}
          strokeWidth={strokeWidth}
          onMouseMove={(evt) =>
            showTooltip(
              evt,
              `Como o ambiente a exige ser: ${Number(
                searchResults ? options.informal : options.comunicador
              ).toFixed(2)}`,
              color,
              searchResults ? informal : comunicador
            )
          }
          onMouseLeave={hideTooltip}
        />

        <rect
          x={(widthGrid / 3) * 2 - 5.5}
          y={(searchResults ? condescendente : planejador) - 5.5}
          width="12"
          height="12"
          rx="2"
          stroke={color}
          fill={color}
          strokeWidth={strokeWidth}
          onMouseMove={(evt) =>
            showTooltip(
              evt,
              `Como o ambiente a exige ser: ${Number(
                searchResults ? options.condescendente : options.planejador
              ).toFixed(2)}`,
              color,
              searchResults ? condescendente : planejador
            )
          }
          onMouseLeave={hideTooltip}
        />
        <rect
          x={200 - 5.5}
          y={(searchResults ? formal : analista) - 5.5}
          width="12"
          height="12"
          rx="2"
          stroke={color}
          fill={color}
          strokeWidth={strokeWidth}
          onMouseMove={(evt) =>
            showTooltip(
              evt,
              `Como o ambiente a exige ser: ${Number(
                searchResults ? options.formal : options.analista
              ).toFixed(2)}`,
              color,
              searchResults ? formal : analista
            )
          }
          onMouseLeave={hideTooltip}
        />
      </styled.Group>
      <g>
        <polyline
          points={`66.666, ${searchResults ? informal : comunicador} 5, ${
            searchResults ? dominante : executor
          }`}
          fill="none"
          stroke={color}
          strokeWidth={strokeWidth}
          opacity={opacity}
        />

        <polyline
          points={`133.333, ${
            searchResults ? condescendente : planejador
          } 61 ,${searchResults ? informal : comunicador}`}
          fill="none"
          stroke={color}
          strokeWidth={strokeWidth}
          opacity={opacity}
        />
        <polyline
          points={`130, ${searchResults ? condescendente : planejador}  195, ${
            (searchResults ? formal : analista) + 5.5
          }`}
          fill="none"
          stroke={color}
          strokeWidth={strokeWidth}
          opacity={opacity}
        />
      </g>
    </>
  );

  const flexibilidade = (
    <>
      <g>
        <svg
          version="1"
          xmlns="http://www.w3.org/2000/svg"
          y={(searchResults ? dominante : executor) - 6}
          x="-5.5"
          onMouseMove={(evt) =>
            showTooltip(
              evt,
              `O ajuste à exigência do meio: ${Number(
                searchResults ? options.dominante : options.executor
              ).toFixed(2)}`,
              (color = "#333333"),
              searchResults ? dominante : executor
            )
          }
          onMouseLeave={hideTooltip}
        >
          <path
            d={`M5.60557 0.788853L0.723607 10.5528C0.391156 11.2177 0.874652 12
                                    1.61803 12H11.382C12.1253 12 12.6088 11.2177 12.2764 10.5528L7.39443
                                    0.788854C7.0259 0.0518057 5.9741 0.0518042 5.60557 0.788853Z`}
            fill="#333333"
          />
        </svg>

        <svg
          y={(searchResults ? informal : comunicador) - 6}
          x={[widthGrid / 3] - 5.5}
          onMouseMove={(evt) =>
            showTooltip(
              evt,
              `O ajuste à exigência do meio: ${Number(
                searchResults ? options.informal : options.comunicador
              ).toFixed(2)}`,
              (color = "#333333"),
              searchResults ? informal : comunicador
            )
          }
          onMouseLeave={hideTooltip}
        >
          <path
            d={`M5.60557 0.788853L0.723607 10.5528C0.391156 11.2177 0.874652 12
                                    1.61803 12H11.382C12.1253 12 12.6088 11.2177 12.2764 10.5528L7.39443
                                    0.788854C7.0259 0.0518057 5.9741 0.0518042 5.60557 0.788853Z`}
            fill="#333333"
          />
        </svg>

        <svg
          y={(searchResults ? condescendente : planejador) - 6}
          x={[(widthGrid / 3) * 2] - 5.5}
          onMouseMove={(evt) =>
            showTooltip(
              evt,
              `O ajuste à exigência do meio: ${Number(
                searchResults ? options.condescendente : options.planejador
              ).toFixed(2)}`,
              (color = "#333333"),
              searchResults ? condescendente : planejador
            )
          }
          onMouseLeave={hideTooltip}
        >
          <path
            d={`M5.60557 0.788853L0.723607 10.5528C0.391156 11.2177 0.874652 12
                                    1.61803 12H11.382C12.1253 12 12.6088 11.2177 12.2764 10.5528L7.39443
                                    0.788854C7.0259 0.0518057 5.9741 0.0518042 5.60557 0.788853Z`}
            fill="#333333"
          />
        </svg>

        <svg
          y={(searchResults ? formal : analista) - 6}
          x={200 - 5.5}
          onMouseMove={(evt) =>
            showTooltip(
              evt,
              `O ajuste à exigência do meio: ${Number(
                searchResults ? options.formal : options.analista
              ).toFixed(2)}`,
              (color = "#333333"),
              searchResults ? formal : analista
            )
          }
          onMouseLeave={hideTooltip}
        >
          <path
            d={`M5.60557 0.788853L0.723607 10.5528C0.391156 11.2177 0.874652 12
                                    1.61803 12H11.382C12.1253 12 12.6088 11.2177 12.2764 10.5528L7.39443
                                    0.788854C7.0259 0.0518057 5.9741 0.0518042 5.60557 0.788853Z`}
            fill="#333333"
          />
        </svg>
      </g>

      <g>
        <polyline
          points={`66.333, ${searchResults ? informal : comunicador} 0, ${
            searchResults ? dominante : executor
          }`}
          fill="none"
          stroke="#333333"
          strokeWidth={strokeWidth}
          opacity={opacity}
        />
        <polyline
          points={`133.33, ${
            searchResults ? condescendente : planejador
          } 66.666 ,${searchResults ? informal : comunicador}`}
          fill="none"
          stroke="#333333"
          strokeWidth={strokeWidth}
          opacity={opacity}
        />
        <polyline
          points={`133.333, ${
            searchResults ? condescendente : planejador
          }  200,${searchResults ? formal : analista}`}
          fill="none"
          stroke="#333333"
          strokeWidth={strokeWidth}
          opacity={opacity}
        />
      </g>
    </>
  );

  const voce = (
    <g>
      <circle
        cx="0"
        cy={searchResults ? dominante : executor}
        r="6"
        stroke="#601e82ed"
        strokeWidth={strokeWidth}
        fill="#601e82ed"
        onMouseMove={(evt) =>
          showTooltip(
            evt,
            `Como a pessoa é: ${Number(
              searchResults ? options.dominante : options.executor
            ).toFixed(2)}`,
            (color = "#601e82ed"),
            searchResults ? dominante : executor
          )
        }
        onMouseLeave={hideTooltip}
      />

      <circle
        cx="66.666"
        cy={searchResults ? informal : comunicador}
        r="6"
        stroke="#601e82"
        strokeWidth={strokeWidth}
        fill="#601e82"
        onMouseMove={(evt) =>
          showTooltip(
            evt,
            `Como a pessoa é: ${Number(
              searchResults ? options.informal : options.comunicador
            ).toFixed(2)}`,
            (color = "#601e82ed"),
            searchResults ? informal : comunicador
          )
        }
        onMouseLeave={hideTooltip}
      />

      <circle
        cx="133.666"
        cy={searchResults ? condescendente : planejador}
        r="6"
        stroke="#601e82"
        strokeWidth={strokeWidth}
        fill="#601e82"
        onMouseMove={(evt) =>
          showTooltip(
            evt,
            `Como a pessoa é: ${Number(
              searchResults ? options.condescendente : options.planejador
            ).toFixed(2)}`,
            (color = "#601e82ed"),
            searchResults ? condescendente : planejador
          )
        }
        onMouseLeave={hideTooltip}
      />

      <circle
        cx="200"
        cy={searchResults ? formal : analista}
        r="6"
        stroke="#601e82"
        strokeWidth={strokeWidth}
        fill="#601e82"
        onMouseMove={(evt) =>
          showTooltip(
            evt,
            `Como a pessoa é: ${Number(
              searchResults ? options.formal : options.analista
            ).toFixed(2)}`,
            (color = "#601e82ed"),
            searchResults ? formal : analista
          )
        }
        onMouseLeave={hideTooltip}
      />

      <polyline
        points={`66.666, ${searchResults ? informal : comunicador} 5, ${
          searchResults ? dominante : executor
        }`}
        fill="none"
        stroke="#601e82"
        strokeWidth={strokeWidth}
        opacity={opacity}
      />

      <polyline
        points={`133.333, ${
          searchResults ? condescendente : planejador
        } 66.666 ,${searchResults ? informal : comunicador}`}
        fill="none"
        stroke="#601e82"
        strokeWidth={strokeWidth}
        opacity={opacity}
      />

      <polyline
        points={`133.333, ${searchResults ? condescendente : planejador}  200,${
          searchResults ? formal : analista
        }`}
        fill="none"
        stroke="#601e82"
        strokeWidth={strokeWidth}
        opacity={opacity}
      />
    </g>
  );

  const TypeIntersection = ({ type }) => {
    const renderCompnent = {
      meio: () => meio,
      flexibilidade: () => flexibilidade,
      voce: () => voce,
    };
    return renderCompnent[type || "voce"]();
  };

  return (
    <g>
      <TypeIntersection type={type} />
    </g>
  );
};

export default ChartSvg;
