import styled from "styled-components";

export const CircleProfilerContent = styled.div`
  ${({ theme, small, medium, large }) =>
    small
      ? `width: 32px; height: 32px`
      : medium
      ? `width: 60px; height: 60px`
      : large
      ? `width: 80px; height: 80px`
      : `width: 60px; height: 60px`}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 80px;
  background-origin: border-box;
  background-clip: content-box, border-box;
  ${({ atsTop }) => atsTop && "margin-right: 3px; margin-top: 10px;"}
  ${({ theme, type }) =>
    type === "blocked" ||
    type === "not_answered" ||
    (type === "expired" && `width: 60px; height: 30px;`)}
    ${({ width, height, type }) =>
    width &&
    `width: ${width}px; height: ${
      height ? `${height}px;` : type === "Não respondido" && "auto"
    };`}
    ${({ right }) => right && `margin-right: ${right};`}
    ${({ left }) => left && `margin-left: ${left};`}
    ${({ small, medium }) =>
    small ? `padding: 3px;` : medium ? `padding: 4px;` : `padding: 5px;`}
    ${({ theme, type }) =>
    type === "P" &&
    `background-image: linear-gradient(white, white),
            radial-gradient(circle at left, ${theme.colors.planner} 0%, ${theme.colors.planner} 100%);`}

    ${({ theme, type }) =>
    type === "A" &&
    `background-image: linear-gradient(white, white),
            radial-gradient(circle at left, ${theme.colors.analyst} 0%, ${theme.colors.analyst} 100%);`}

    ${({ theme, type }) =>
    type === "C" &&
    `background-image: linear-gradient(white, white),
                radial-gradient(circle at left, ${theme.colors.communicator} 0%, ${theme.colors.communicator} 100%);`}

    ${({ theme, type }) =>
    type === "E" &&
    `background-image: linear-gradient(white, white),
                radial-gradient(circle at left, ${theme.colors.executor} 0%, ${theme.colors.executor} 100%);`}

    ${({ theme, type }) =>
    type === "AP" &&
    `background-image: linear-gradient(white, white),
                    radial-gradient(circle at left, ${theme.colors.analyst} 0%, ${theme.colors.planner} 100%);`}

    ${({ theme, type }) =>
    type === "APC" &&
    `background-image: linear-gradient(white, white),
                radial-gradient(
                    circle at left,
                    ${theme.colors.analyst} 0%,
                    ${theme.colors.planner} 50%,
                    ${theme.colors.communicator} 100%
                );`}


    ${({ theme, type }) =>
    type === "APE" &&
    `background-image: linear-gradient(white, white),
                radial-gradient(
                    circle at left,
                    ${theme.colors.analyst} 0%,
                    ${theme.colors.planner} 50%,
                    ${theme.colors.executor} 100%
                );`}

    ${({ theme, type }) =>
    type === "PA" &&
    `background-image: linear-gradient(white, white),
                radial-gradient(circle at left, ${theme.colors.planner} 0%, ${theme.colors.analyst} 100%)`}


    ${({ theme, type }) =>
    type === "PAC" &&
    `background-image: linear-gradient(white, white),
                radial-gradient(
                    circle at left,
                    ${theme.colors.planner} 0%,
                    ${theme.colors.analyst} 50%,
                    ${theme.colors.communicator} 100%
                );`}

    ${({ theme, type }) =>
    type === "PAE" &&
    `background-image: linear-gradient(white, white),
    radial-gradient(
        circle at left,
        ${theme.colors.planner} 0%,
        ${theme.colors.analyst} 50%,
        ${theme.colors.executor} 100%
    );`}

    ${({ theme, type }) =>
    type === "AC" &&
    `background-image: linear-gradient(white, white),
    radial-gradient(circle at left, ${theme.colors.analyst} 0%, ${theme.colors.communicator} 100%)`}

    ${({ theme, type }) =>
    type === "ACP" &&
    `background-image: linear-gradient(white, white),
                    radial-gradient(
                        circle at left,
                        ${theme.colors.analyst} 0%,
                        ${theme.colors.communicator} 50%,
                        ${theme.colors.planner} 100%
                    );`}

    ${({ theme, type }) =>
    type === "ACE" &&
    `background-image: linear-gradient(white, white),
                    radial-gradient(
                        circle at left,
                        ${theme.colors.analyst} 0%,
                    ${theme.colors.communicator} 50%,
                    ${theme.colors.executor} 100%
                    );`}

    ${({ theme, type }) =>
    type === "CA" &&
    `background-image: linear-gradient(white, white),
        radial-gradient(circle at left, ${theme.colors.communicator} 0%, ${theme.colors.analyst} 100%)`}

    ${({ theme, type }) =>
    type === "CAP" &&
    `background-image: linear-gradient(white, white),
                        radial-gradient(
                            circle at left,
                        ${theme.colors.communicator} 0%,
                        ${theme.colors.analyst} 50%,
                        ${theme.colors.planner} 100%
                        );`}

    ${({ theme, type }) =>
    type === "CAE" &&
    `background-image: linear-gradient(white, white),
                        radial-gradient(
                            circle at left,
                        ${theme.colors.communicator} 0%,
                        ${theme.colors.analyst} 50%,
                        ${theme.colors.executor} 100%
                        );`}

    ${({ theme, type }) =>
    type === "EA" &&
    `background-image: linear-gradient(white, white),
            radial-gradient(circle at left, ${theme.colors.executor} 0%, ${theme.colors.analyst} 100%);`}

    ${({ theme, type }) =>
    type === "EAP" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                            ${theme.colors.executor} 0%,
                            ${theme.colors.analyst} 50%,
                            ${theme.colors.planner} 100%
                            );`}

    ${({ theme, type }) =>
    type === "EAC" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                            ${theme.colors.executor} 0%,
                            ${theme.colors.analyst} 50%,
                            ${theme.colors.communicator} 100%
                            );`}
${({ theme, type }) =>
    type === "AE" &&
    `background-image: linear-gradient(white, white),
            radial-gradient(circle at left, ${theme.colors.analyst} 0%, ${theme.colors.executor} 100%);`}

${({ theme, type }) =>
    type === "AEC" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                                ${theme.colors.analyst} 0%,
                                ${theme.colors.executor} 50%,
                                ${theme.colors.communicator} 100%
                            );`}

${({ theme, type }) =>
    type === "AEP" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                                ${theme.colors.analyst} 0%,
                                ${theme.colors.executor} 50%,
                                ${theme.colors.planner} 100%
                            );`}

${({ theme, type }) =>
    type === "PEC" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                                ${theme.colors.planner} 0%,
                ${theme.colors.executor} 50%,
                ${theme.colors.communicator} 100%
                            );`}

${({ theme, type }) =>
    type === "PEA" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                                ${theme.colors.planner} 0%,
                ${theme.colors.executor} 50%,
                ${theme.colors.analyst} 100%
                            );`}

${({ theme, type }) =>
    type === "PE" &&
    `background-image: linear-gradient(white, white),
            radial-gradient(circle at left, ${theme.colors.planner} 0%, ${theme.colors.executor} 100%);`}


${({ theme, type }) =>
    type === "CE" &&
    `background-image: linear-gradient(white, white),
            radial-gradient(circle at left, ${theme.colors.communicator} 0%, ${theme.colors.executor} 100%);`}


${({ theme, type }) =>
    type === "CEP" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                                ${theme.colors.communicator} 0%,
                ${theme.colors.executor} 50%,
                ${theme.colors.planner} 100%
                            );`}



${({ theme, type }) =>
    type === "CEA" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                                ${theme.colors.communicator} 0%,
                ${theme.colors.executor} 50%,
                ${theme.colors.analyst} 100%
                            );`}


${({ theme, type }) =>
    type === "EC" &&
    `background-image: linear-gradient(white, white),
            radial-gradient(circle at left, ${theme.colors.executor} 0%, ${theme.colors.communicator} 100%);`}



${({ theme, type }) =>
    type === "CEA" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                                ${theme.colors.communicator} 0%,
                ${theme.colors.executor} 50%,
                ${theme.colors.analyst} 100%
                            );`}

${({ theme, type }) =>
    type === "ECP" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                                ${theme.colors.executor} 0%,
                ${theme.colors.communicator} 50%,
                ${theme.colors.planner} 100%
                            );`}

${({ theme, type }) =>
    type === "PC" &&
    `background-image: linear-gradient(white, white),
            radial-gradient(circle at left, ${theme.colors.planner} 0%, ${theme.colors.communicator} 100%);`}

${({ theme, type }) =>
    type === "PCA" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                                ${theme.colors.planner} 0%,
                ${theme.colors.communicator} 50%,
                ${theme.colors.analyst} 100%
                            );`}


${({ theme, type }) =>
    type === "PCE" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                                ${theme.colors.planner} 0%,
                ${theme.colors.communicator} 50%,
                ${theme.colors.executor} 100%
                            );`}

${({ theme, type }) =>
    type === "PC" &&
    `background-image: linear-gradient(white, white),
            radial-gradient(circle at left, ${theme.colors.planner} 0%, ${theme.colors.communicator} 100%);`}
${({ theme, type }) =>
    type === "CPA" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                                ${theme.colors.communicator} 0%,
                ${theme.colors.planner} 50%,
                ${theme.colors.analyst} 100%
                            );`}

${({ theme, type }) =>
    type === "CPE" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                                ${theme.colors.communicator} 0%,
                ${theme.colors.planner} 50%,
                ${theme.colors.executor} 100%
                            );`}

${({ theme, type }) =>
    type === "EP" &&
    `background-image: linear-gradient(white, white),
            radial-gradient(circle at left, ${theme.colors.executor} 0%, ${theme.colors.planner} 100%);`}

${({ theme, type }) =>
    type === "CP" &&
    `background-image: linear-gradient(white, white),
            radial-gradient(circle at left, ${theme.colors.communicator} 0%, ${theme.colors.planner} 100%);`}



${({ theme, type }) =>
    type === "EPA" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                                ${theme.colors.executor} 0%,
                ${theme.colors.planner} 50%,
                ${theme.colors.analyst} 100%
                            );`}


${({ theme, type }) =>
    type === "EPC" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                                ${theme.colors.executor} 0%,
                ${theme.colors.planner} 50%,
                ${theme.colors.communicator} 100%
                            );`}

${({ theme, type }) =>
    type === "ECA" &&
    `background-image: linear-gradient(white, white),
                            radial-gradient(
                                circle at left,
                                ${theme.colors.executor} 0%,
                ${theme.colors.communicator} 50%,
                ${theme.colors.analyst} 100%
                            );`}

    ${({ flagOnClick }) =>
    flagOnClick &&
    `
        height: 30px;
        :hover {
            cursor: pointer;
            background: #f9f9f9 radial-gradient(circle, transparent 1%, #f9f9f9 1%)
                center/15000%;
        }
        :active {
            transform: scale(1.1);
            top: 6px;
        }
    `}
`;

export const InfoText = styled.span`
  ${({ color }) => (color ? `color:${color}` : "color:#601e82;")}

  ${({ theme, type }) =>
    type === "blocked"
      ? `font-size: 10px`
      : type === "not_answered" && `font-size: 0.75rem;`};

  ${({ length, small, medium, large }) => {
    return length === 1 && small
      ? "font-size: 1.125rem;"
      : length === 1 && medium
      ? "font-size: 2.25rem;"
      : length === 1 && large
      ? "font-size: 33px"
      : length === 2 && medium
      ? "font-size: 2rem;"
      : length === 2 && large
      ? "font-size: 30px"
      : length === 3 && large
      ? "font-size: 28px"
      : length === 3 && medium
      ? "font-size: 1.625rem;"
      : length === 2
      ? `font-size: 1rem;`
      : `font-size: 0.75rem;`;
  }}

  font-weight: bold;
`;

export const CircleProfilerContentAux = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-origin: border-box;
  background-clip: content-box, border-box;

  ${({ small, medium, large }) =>
    small
      ? `width: 32px; border: 2px solid transparent; height: 32px;`
      : medium
      ? `width: 64px; border: 4px solid transparent;height: 64px;`
      : large
      ? `width: 128px; border: 8px solid transparent;height: 128px;`
      : `width: 32px;  border: 2px solid transparent; height: 32px;`}

  ${({ atsTop }) => atsTop && "margin-right: 3px; margin-top: 10px;"}
    ${({ theme, type }) =>
    type === "blocked" ||
    type === "not_answered" ||
    (type === "expired" && `width: 60px; height: 30px;`)}
    ${({ width, height, type }) =>
    width &&
    `width: ${width}px; height: ${
      height ? `${height}px;` : type === "Não respondido" && "auto"
    };`}
    ${({ right }) => right && `margin-right: ${right};`}
    ${({ left }) => left && `margin-left: ${left};`}
    ${({ small, medium }) =>
    small ? `padding: 0.875rem;` : medium ? `padding: 4px;` : `padding: 5px;`}

    /** PROFILER TYPES COLOR */
    ${({ theme, type }) =>
    type === "A" &&
    `background-image:linear-gradient(white, white), linear-gradient(0deg, ${theme.colors.analyst} 50%, ${theme.colors.analyst} 50%);`}
    ${({ theme, type }) =>
    type === "P" &&
    ` background-image:linear-gradient(white, white), linear-gradient(0deg, ${theme.colors.planner} 50%, ${theme.colors.planner} 50%);`}

    ${({ theme, type }) =>
    type === "C" &&
    ` background-image:linear-gradient(white, white), linear-gradient(0deg, ${theme.colors.communicator} 50%, ${theme.colors.communicator} 50%);`}

    ${({ theme, type }) =>
    type === "E" &&
    ` background-image:linear-gradient(${theme.colors.executor} , ${theme.colors.executor} ), linear-gradient(0deg, ${theme.colors.executor} 50%, ${theme.colors.executor} 50%);`}

    ${({ theme, type }) =>
    type === "AP" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.analyst} 0%, ${theme.colors.planner} 100%);`}

    ${({ theme, type }) =>
    type === "AC" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.analyst} 0%, ${theme.colors.communicator} 100%);`}
    ${({ theme, type }) =>
    type === "AE" &&
    `background-image: linear-gradient(white, white),  linear-gradient(90deg, ${theme.colors.analyst} 0%, ${theme.colors.executor} 100%);`}

    ${({ theme, type }) =>
    type === "PA" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.planner} 0%, ${theme.colors.analyst} 100%);`}

    ${({ theme, type }) =>
    type === "PC" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.planner} 0%, ${theme.colors.communicator} 100%);`}

    ${({ theme, type }) =>
    type === "PE" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.planner} 0%, ${theme.colors.executor} 100%);`}

    ${({ theme, type }) =>
    type === "CA" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.communicator} 0%, ${theme.colors.analyst} 100%);`}

    ${({ theme, type }) =>
    type === "CP" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.communicator} 0%, ${theme.colors.planner} 100%);`}

    ${({ theme, type }) =>
    type === "CE" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.communicator} 0%, ${theme.colors.executor} 100%);`}

    ${({ theme, type }) =>
    type === "EA" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.executor} 0%, ${theme.colors.analyst} 100%);`}

    ${({ theme, type }) =>
    type === "EC" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.executor} 0%, ${theme.colors.communicator} 100%);`}


    ${({ theme, type }) =>
    type === "EP" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.executor} 0%, ${theme.colors.planner} 100%);`}

    ${({ theme, type }) =>
    type === "APC" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.analyst} 0%, ${theme.colors.planner} 50%, ${theme.colors.communicator} 100%);`}

    ${({ theme, type }) =>
    type === "APE" &&
    `background-image: linear-gradient(white,white), linear-gradient(90deg, ${theme.colors.analyst} 0%, ${theme.colors.planner} 50%, ${theme.colors.executor} 100%);`}

    ${({ theme, type }) =>
    type === "ACP" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.analyst} 0%, ${theme.colors.communicator} 50%, ${theme.colors.planner} 100%);`}


    ${({ theme, type }) =>
    type === "ACE" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.analyst} 0%, ${theme.colors.communicator} 50%, ${theme.colors.executor} 100%);`}

    ${({ theme, type }) =>
    type === "AEP" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.analyst} 0%, ${theme.colors.executor} 50%, ${theme.colors.planner} 100%);`}

    ${({ theme, type }) =>
    type === "PAC" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.planner} 0%, ${theme.colors.analyst} 50%, ${theme.colors.communicator} 100%);`}

    ${({ theme, type }) =>
    type === "PAE" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.planner} 0%, ${theme.colors.analyst} 50%, ${theme.colors.executor} 100%);`}

    ${({ theme, type }) =>
    type === "CAP" &&
    `background-image: linear-gradient(white, white),  linear-gradient(90deg, ${theme.colors.communicator} 0%, ${theme.colors.analyst} 50%, ${theme.colors.planner} 100%);`}

    ${({ theme, type }) =>
    type === "CAE" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.communicator} 0%, ${theme.colors.analyst} 50%, ${theme.colors.executor} 100%);`}

    ${({ theme, type }) =>
    type === "EAP" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.executor} 0%, ${theme.colors.analyst} 50%, ${theme.colors.planner} 100%);`}

    ${({ theme, type }) =>
    type === "EAC" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.executor} 0%, ${theme.colors.analyst} 50%, ${theme.colors.planner} 100%);`}

    ${({ theme, type }) =>
    type === "AEC" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.analyst} 0%, ${theme.colors.executor} 50%, ${theme.colors.communicator} 100%);`}

    ${({ theme, type }) =>
    type === "PEC" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.planner} 0%, ${theme.colors.executor} 50%, ${theme.colors.communicator} 100%);`}

    ${({ theme, type }) =>
    type === "PEA" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.planner} 0%, ${theme.colors.executor} 50%, ${theme.colors.analyst} 100%);`}

    ${({ theme, type }) =>
    type === "CEP" &&
    `background-image: linear-gradient(white, white),linear-gradient(90deg, ${theme.colors.communicator} 0%, ${theme.colors.executor} 50%, ${theme.colors.planner} 100%);`}

    ${({ theme, type }) =>
    type === "CEA" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.communicator} 0%, ${theme.colors.executor} 50%, ${theme.colors.analyst} 100%);`}

    ${({ theme, type }) =>
    type === "ECP" &&
    `background-image: linear-gradient(white, white),linear-gradient(90deg, ${theme.colors.executor} 0%, ${theme.colors.communicator} 50%, ${theme.colors.planner} 100%);`}

    ${({ theme, type }) =>
    type === "PCA" &&
    `background-image: linear-gradient(white, white),linear-gradient(90deg, ${theme.colors.planner} 0%, ${theme.colors.communicator} 50%, ${theme.colors.analyst} 100%);`}

    ${({ theme, type }) =>
    type === "PCE" &&
    `background-image: linear-gradient(white, white),linear-gradient(90deg, ${theme.colors.planner} 0%, ${theme.colors.communicator} 50%, ${theme.colors.executor} 100%);`}

    ${({ theme, type }) =>
    type === "PC" &&
    `background-image: linear-gradient(white, white),linear-gradient(90deg, ${theme.colors.planner} 0%, ${theme.colors.communicator} 100%);`}

    ${({ theme, type }) =>
    type === "CPA" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.communicator} 0%, ${theme.colors.planner} 50%, ${theme.colors.analyst} 100%);`}

    ${({ theme, type }) =>
    type === "CPE" &&
    `background-image: linear-gradient(white, white),linear-gradient(90deg, ${theme.colors.communicator} 0%, ${theme.colors.planner} 50%, ${theme.colors.executor} 100%);`}

    ${({ theme, type }) =>
    type === "EPA" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.executor} 0%, ${theme.colors.planner} 50%, ${theme.colors.analyst} 100%);`}

    ${({ theme, type }) =>
    type === "EPC" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.executor} 0%, ${theme.colors.planner} 50%, ${theme.colors.communicator} 100%);`}

    ${({ theme, type }) =>
    type === "ECA" &&
    `background-image: linear-gradient(white, white), linear-gradient(90deg, ${theme.colors.executor} 0%, ${theme.colors.communicator} 50%, ${theme.colors.analyst} 100%);`}

    ${({ flagOnClick }) =>
    flagOnClick &&
    `
        height: 30px;
        :hover {
            cursor: pointer;
            background: #f9f9f9 radial-gradient(circle, transparent 1%, #f9f9f9 1%)
                center/15000%;
        }
        :active {
            transform: scale(1.1);
            top: 6px;
        }
    `}
`;

export const InfoTextAux = styled.span`
  ${({ color }) => (color ? `color:${color}` : "color:#601e82;")}

  ${({ theme, type }) =>
    type === "blocked"
      ? `font-size: 0.625rem;  `
      : type === "not_answered" && `font-size: 0.75rem; `};
  ${({ flagOnClick }) => flagOnClick && "cursor:pointer;"}
  ${({ length, small, medium, large }) => {
    return length === 1 && small
      ? "font-size: 1.125rem;"
      : length === 1 && medium
      ? "font-size: 2.25rem;"
      : length === 1 && large
      ? "font-size: 33px"
      : length === 2 && medium
      ? "font-size: 2rem;"
      : length === 2 && large
      ? "font-size: 30px"
      : length === 3 && large
      ? "font-size: 28px"
      : length === 3 && medium
      ? "font-size: 1.625rem;"
      : length === 2
      ? `font-size: 1rem;`
      : `font-size: 0.75rem;`;
  }}

    ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}



    font-weight: bold;
`;

export const CircleType = styled.div`
  ${({ small }) => small && "width: 28px; height: 28px;"}
  ${({ medium }) => medium && "width: 56px; height: 56px;"}
    ${({ large }) => large && "width: 112px; height: 112px;"}

    ${({ small, type }) => small && type.length === 1 && "font-size: 1.125rem;"}
    ${({ small, type }) => small && type.length === 2 && "font-size: 1rem;"}
    ${({ small, type }) => small && type.length === 3 && "font-size: 0.75rem;"}

    ${({ medium, type }) =>
    medium && type.length === 1 && "font-size: 2.25rem;"}
    ${({ medium, type }) => medium && type.length === 2 && "font-size: 2rem;"}
    ${({ medium, type }) =>
    medium && type.length === 3 && "font-size: 1.625rem;"}

    ${({ large, type }) => large && type.length === 1 && "font-size: 72px;"}
    ${({ large, type }) => large && type.length === 2 && "font-size: 64px;"}
    ${({ large, type }) => large && type.length === 3 && "font-size: 52px;"}

    background: #fff;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextCircle = styled.span`
  font-style: normal;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #601e82;
  line-height: 24px;
`;
